/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import React, { useContext } from 'react';
import { DeviceType } from 'utils/constants';
import ModalMobile from './Mobile';
import './styles.scss';
import ModalWeb from './Web';

interface Params {
	show: boolean;
	showClose?: boolean;
	title: string;
	subTitle?: string;
	defaultTitle?: string;
	enableAwayClose?: boolean;
	onClose: () => void;
}

const Modal: React.FC<Params> = ({ show, showClose, title, subTitle, defaultTitle, enableAwayClose, children, onClose }) => {
	const { deviceType } = useContext<DeviceContextType>(DeviceContextElement);

	const mainContent = () => {
		return deviceType === DeviceType.MOBILE ? (
			<ModalMobile {...{ show, title, subTitle, defaultTitle, children, onClose }} />
		) : (
			<ModalWeb {...{ show, title, subTitle, defaultTitle, showClose, children, onClose, enableAwayClose }} />
		);
	};

	return show ? mainContent() : <></>;
};

export default Modal;
