/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import TechnicalAssists from 'models/TechnicalAssists';
import moment from 'moment';
import React from 'react';
import { phoneMask } from 'utils/pipe';

interface Params {
	techAssist: TechnicalAssists;
}

const VisitMessage: React.FC<Params> = ({ techAssist }) => {
	const technician = techAssist?.technician;
	const message = JSON.parse(techAssist?.message);
	const visitDate = moment(techAssist.appointment_date)
		.add(3, 'hours')
		.format('DD/MM/YYYY');
	const techPhones = () => {
		const defaultPhone = technician?.phone || technician?.phone1;
		if (technician?.phone && technician?.phone1) {
			return <span>{`contatos ${phoneMask(technician?.phone)} / ${phoneMask(technician?.phone1)}`}</span>;
		} else if (defaultPhone) {
			return <span>{`contato ${phoneMask(defaultPhone)}`}</span>;
		}
	};

	const messageContent = () => {
		return message ? (
			<div>
				<div>
					<span>Utilize o link abaixo para acompanhar seu atendimento em tempo real:</span>
				</div>
				<a href={message.link} target="_blank" rel="noopener noreferrer">
					{message.link}
				</a>
			</div>
		) : (
			<span> Um link para acompanhamento em tempo real será disponibilizada logo abaixo assim que sua ordem de serviço for recebida e atribuída ao técnico.</span>
		);
	};

	return (
		<div className="visit-description">
			<span>
				<b>Visita técnica agendada</b>
			</span>
			<span>
				{`Seu atendimento técnico foi agendado para o dia ${visitDate} com ${technician?.name} `}
				<a>{techPhones()}</a>
			</span>
			{messageContent()}
		</div>
	);
};

export default VisitMessage;
