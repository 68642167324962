/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import Pagination from '@material-ui/lab/Pagination';

import './styles.scss';

interface Params {
	selected: number;
	total: number;
	isLoading: boolean;
	onPageChange: (pageSelected: number) => void;
}

const Paginator: React.FC<Params> = ({ selected, total, isLoading, onPageChange }) => {
	const onChange = (e: React.ChangeEvent, value: number) => {
		onPageChange(value);
	};

	const mainContent = () => {
		return (
			<section className="paginator-main-content">
				<Pagination count={total} page={selected} onChange={onChange} siblingCount={1} />
			</section>
		);
	};

	return total > 1 && !isLoading ? mainContent() : <></>;
};

export default Paginator;
