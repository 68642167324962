/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React, { createContext, useRef, useState } from 'react';
import { RouteList } from 'utils/constants';
import { updateBodyForOutsidePages } from 'utils/Screen';
import history from '../../routes/history';
import { RouterContextType } from './RouterContextType';

const rootPages = [RouteList.EQUIPMENTS, RouteList.ORDERS];
const outsidePages = [RouteList.LOGIN, RouteList.NEW_ACCOUNT, RouteList.REDEFINE_PASS, RouteList.RESET_PASS];

interface RootManeger {
	isRoot: boolean;
}

function getDefaultRootManagerData() {
	return { isRoot: true };
}

export const RouterContextElement = createContext<RouterContextType>({
	isRoot: {} as boolean,
	onRouteChange: () => {},
	resetHistory: () => {},
});

const RouterProvider = (props: any) => {
	const isRootRef = useRef<RootManeger>(getDefaultRootManagerData());
	const [isRoot, setIsRoot] = useState(true);

	const onRouteChange = (path: RouteList) => {
		if (outsidePages.includes(path)) {
			updateBodyForOutsidePages();
		}
		updateRootRef(rootPages.includes(path));
	};

	const resetHistory = () => {
		history.push(RouteList.HOME);
	};

	const updateRootRef = (value: boolean) => {
		isRootRef.current = { isRoot: value };
		setIsRoot(value);
	};

	const providerValues = () => {
		return { ...isRootRef.current, onRouteChange, resetHistory };
	};

	return <RouterContextElement.Provider value={providerValues()}>{props.children}</RouterContextElement.Provider>;
};

export default RouterProvider;
