/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import wind from './images/wind.png';

import './styles.scss';
interface Params {
	show: boolean;
	text: string;
}

const EmptyList: React.FC<Params> = ({ show, text }) => {
	const mainContent = () => {
		return (
			<div className="empty-main-content">
				<div className="empty-main">
					<img src={wind} />
					<span>{text} :)</span>
				</div>
			</div>
		);
	};

	return show ? mainContent() : <></>;
};

export default EmptyList;
