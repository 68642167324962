/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

const FileIcon: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="97.4" height="77.92" viewBox="0 0 97.4 77.92">
			<path
				id="Subtração_86"
				data-name="Subtração 86"
				d="M87.66,77.92H9.74A9.769,9.769,0,0,1,0,68.18L.049,9.74A9.727,9.727,0,0,1,9.74,0H38.96L48.7,9.74H87.66a9.769,9.769,0,0,1,9.74,9.74v48.7a9.769,9.769,0,0,1-9.74,9.74ZM46.237,21.158a7.771,7.771,0,0,0-5.592,2.373,8.186,8.186,0,0,0-2.328,5.761V52.636a10.584,10.584,0,0,0,2.93,7.259,9.563,9.563,0,0,0,6.842,2.969h1.222a9.651,9.651,0,0,0,6.824-2.988,10.478,10.478,0,0,0,2.148-3.22,10.016,10.016,0,0,0,.8-3.919V34.626a1.444,1.444,0,0,0-.417-1.04,1.365,1.365,0,0,0-.976-.412,1.429,1.429,0,0,0-1.4,1.452V52.719a7.646,7.646,0,0,1-1.978,5.128,6.992,6.992,0,0,1-4.99,2.206H48.1a6.9,6.9,0,0,1-4.927-2.29,7.608,7.608,0,0,1-2.148-5.138V29.283a5.142,5.142,0,0,1,1.555-3.677,5.245,5.245,0,0,1,3.685-1.517A4.958,4.958,0,0,1,49.842,25.6a5.272,5.272,0,0,1,1.474,3.686v21.8a2.9,2.9,0,0,1-2.607,3,2.825,2.825,0,0,1-2.535-3V38.646a1.4,1.4,0,1,0-2.8,0V51.08c0,3.306,2.3,5.8,5.34,5.8a5.223,5.223,0,0,0,3.839-1.61,5.992,5.992,0,0,0,1.574-4.189v-21.8a8.165,8.165,0,0,0-2.311-5.752A7.69,7.69,0,0,0,46.237,21.158Z"
			/>
		</svg>
	);
};

export default FileIcon;
