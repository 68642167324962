/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import FileIcon from 'components/Shared/FileIcon';
import FilesCenter from 'components/Shared/FilesCenter';
import MobileHeader from 'components/Shared/MobileHeader';
import Modal from 'components/Shared/Modal';
import PhoneCaller from 'components/Shared/PhoneCaller';
import WhatsAppCaller from 'components/Shared/WhatsAppCaller';
import { ServiceOrderDetailsContextElement } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContext';
import { ServiceOrderDetailsContextType } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContextType';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { cepMaskFormat, parseEquipName } from 'utils/pipe';
import './styles.scss';

const ServiceOrderInfoMobile: React.FC = () => {
	const { serviceOrderDetail } = useContext<ServiceOrderDetailsContextType>(ServiceOrderDetailsContextElement);
	const [showFilesModal, setShowFilesModal] = useState<boolean>(false);
	const equipment = serviceOrderDetail?.equipment;
	const visitInfo = serviceOrderDetail?.visitInfo;
	const serviceOrder = serviceOrderDetail?.serviceOrder;
	const technician = serviceOrderDetail?.technician;
	const files = serviceOrderDetail?.files || [];

	const getTechPhones = () => {
		const phoneList = [];

		if (technician?.phone) {
			phoneList.push(technician?.phone);
		}
		if (technician?.phone1) {
			phoneList.push(technician?.phone1);
		}

		return phoneList;
	};

	const locationContent = () => {
		if (serviceOrder) {
			const address = `${serviceOrder.address}, ${serviceOrder.neighborhood}.`;
			const region = `${serviceOrder.city.toLocaleLowerCase()}, ${serviceOrder.state_sigla} - ${cepMaskFormat(serviceOrder.zip_code)}`;

			return (
				<div className="service-order-info-location">
					<b>Endereço do atendimento: </b>
					<span>{address.toLocaleLowerCase()}</span>
					<span>{region}</span>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const protocolContent = () => {
		if (serviceOrder) {
			return (
				<div className="service-order-info-protocol">
					<b>Protocolo de atendimento: </b>
					<span>{serviceOrder.service_order}</span>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const visitContent = () => {
		if (visitInfo?.date) {
			return (
				<div className="service-order-info-visit">
					<b>Visita: </b>
					<span>{moment(visitInfo.date).format('LL')}</span>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const techContent = () => {
		if (technician) {
			return (
				<>
					<div className="service-order-info-tech-name">
						<b>Técnico: </b>
						<span>{technician.name}</span>
					</div>
				</>
			);
		} else {
			return <></>;
		}
	};

	const techContactContent = () => {
		if (technician) {
			return (
				<div className="service-order-contact">
					<div className="contact-area">
						<WhatsAppCaller phone={technician?.whatsAppPhone} />
						<PhoneCaller phones={getTechPhones()} />
					</div>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const closeFilesModal = () => {
		setShowFilesModal(false);
	};

	const listFilesModalContent = () => {
		return (
			<Modal title="Anexos do Atendimento" defaultTitle="Anexos do Atendimento" onClose={closeFilesModal} show={showFilesModal} showClose>
				<FilesCenter {...{ techAssistFiles: files, onClose: closeFilesModal }} />
			</Modal>
		);
	};

	const serviceOrderFilesContent = () => {
		if (files?.length) {
			return (
				<div
					className="service-order-equip-files"
					onClick={() => {
						setShowFilesModal(true);
					}}
				>
					<FileIcon />
					<span>Anexos do Atendimento</span>
				</div>
			);
		} else {
			return <></>;
		}
	};
	const mainTitle = () => {
		const subTitle = equipment ? `N/S: ${equipment.serial_number}` : '';

		const title = equipment ? parseEquipName(equipment.name) : '';

		return <MobileHeader title={title} subTitle={subTitle} defaultTitle={`Atendimento ${serviceOrder?.service_order}`} />;
	};

	const mainContent = () => {
		return (
			<section className="service-order-mobile-info-content">
				{mainTitle()}
				<div className="service-order-info-extra">
					{locationContent()}
					{serviceOrderFilesContent()}
					{protocolContent()}
					{techContent()}
					{visitContent()}
				</div>
				{techContactContent()}
				{listFilesModalContent()}
			</section>
		);
	};

	return mainContent();
};

export default ServiceOrderInfoMobile;
