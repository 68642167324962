/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import CustomButton from 'components/Shared/CustomButton';
import FormQuestionLine from 'components/Shared/FormFields/FormQuestionLine';
import FormValidator from 'components/Shared/FormFields/FormValidator';
import ServiceOrder from 'models/ServiceOrder';
import { ServiceOrderFeedbackAnswers, ServiceOrderFeedbackAnswersInput } from 'models/ServiceOrderFeedback';
import React, { useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { CLEAR_OS_FEEDBACK_ANSWERS, ServiceOrderFeedbackQuestions, YES_OPTION } from 'utils/constants';
import OptionsField from './OptionField';
import RatingField from './RatingField';

interface Params {
	serviceOrder: ServiceOrder;
	serviceOrderFeedback?: ServiceOrderFeedbackAnswers;
	onConfirm: (data: any) => void;
	onClose: () => void;
}

const ServiceOrderFeedbackForm: React.FC<Params> = ({ serviceOrder, serviceOrderFeedback, onClose, onConfirm }) => {
	const {
		register,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm();

	const [osAnswers, setOsAnswers] = useState<ServiceOrderFeedbackAnswersInput>(CLEAR_OS_FEEDBACK_ANSWERS);

	const [showReopenQuestion, setShowReopenQuestion] = useState<boolean>(false);
	const [showVisitDelayQuestion, setShowVisitDelayQuestion] = useState<boolean>(false);

	const [text, setText] = useState<string>('');
	const [msg, setMsg] = useState<number>(500);

	const onSubmit = (data: any) => {
		const newOsAnswers = { ...osAnswers, serviceOrderId: serviceOrder.id, serviceOrderObs: data.serviceOrderObs ? data.serviceOrderObs : ' ' };
		if (validateForm(newOsAnswers)) {
			onConfirm(newOsAnswers);
		}
	};

	const validateForm = (osAnswers: any) => {
		let isValid = true;
		for (const key in osAnswers) {
			if (Object.prototype.hasOwnProperty.call(osAnswers, key)) {
				const answer = osAnswers[key];
				if (!answer) {
					const error = { type: 'required' };
					setError(key, error);
					isValid = false;
				}
			}
		}
		return isValid;
	};

	const onFieldChange = (value: string, field: string, callback?: (value: string) => void) => {
		clearErrors(field);
		if (callback) {
			callback(value);
		} else {
			const newOsAnswers = { ...osAnswers };
			newOsAnswers[field] = value;
			setOsAnswers(newOsAnswers);
		}
	};

	const onReopenQuestionClick = (value: string) => {
		const newOsAnswers = { ...osAnswers };
		newOsAnswers.serviceOrderComplete = value;
		if (value !== YES_OPTION) {
			newOsAnswers.serviceOrderReopen = '';
			setShowReopenQuestion(value !== YES_OPTION);
		}
		setOsAnswers(newOsAnswers);
	};

	const onVisitDelayQuestionClick = (value: string) => {
		const newOsAnswers = { ...osAnswers };
		newOsAnswers.visitTimeArrive = value;
		if (value !== YES_OPTION) {
			newOsAnswers.visitTimeDelay = '';
			setShowVisitDelayQuestion(value !== YES_OPTION);
		}
		setOsAnswers(newOsAnswers);
	};

	const formItemClass = (fieldName: string) => {
		const formError = errors[fieldName] as FieldErrors;
		const errorClass = formError?.type ? ' has-error' : '';

		return `form-item-content ${fieldName}${errorClass}`;
	};

	const messageInput = (formName: string) => {
		return (
			<textarea
				name={formName}
				{...register(formName, { required: false, maxLength: 500 })}
				placeholder="Digite aqui..."
				defaultValue={serviceOrderFeedback?.serviceOrderObs}
				onChange={e => {
					countMsg(e);
				}}
				value={text}
			/>
		);
	};

	const reopenQuestionContent = (serviceOrderReopen: any) => {
		const formName = serviceOrderReopen.formName;
		return !showReopenQuestion ? (
			<></>
		) : (
			<div className={formItemClass(formName)}>
				<label>
					<span className="feedback-question-order">{serviceOrderReopen.order}</span>
					{serviceOrderReopen.question}
				</label>
				<FormValidator {...{ formName, errors, isDirty }} />
				<OptionsField
					{...{
						formName,
						register,
						onChange: (value: string, field: string) => {
							onFieldChange(value, field);
						},
					}}
				/>
			</div>
		);
	};

	const visitDelayQuestionContent = (visitTimeDelay: any) => {
		const formName = visitTimeDelay.formName;
		return !showVisitDelayQuestion ? (
			<></>
		) : (
			<div className={formItemClass(formName)}>
				<label>
					<span className="feedback-question-order">{visitTimeDelay.order}</span>
					{visitTimeDelay.question}
				</label>
				<FormValidator {...{ formName, errors, isDirty }} />
				<OptionsField
					{...{
						formName,
						register,
						onChange: (value: string, field: string) => {
							onFieldChange(value, field);
						},
					}}
				/>
			</div>
		);
	};

	const countMsg = (e: any) => {
		if (text.length < 500) {
			setText(e.target.value.substring(0, 500));
			if (e.nativeEvent.inputType == 'insertText' || e.nativeEvent.inputType == 'insertFromPaste') {
				setMsg(500 - (text.length + 1));
			} else {
				setMsg(500 - (text.length - 1));
			}
		} else {
			if (e.nativeEvent.inputType == 'insertText' || e.nativeEvent.inputType == 'insertFromPaste') {
				setMsg(0);
			} else {
				setText(e.target.value.substring(0, 500));
				setMsg(500 - (text.length + 1));
			}
		}
	};

	const formQuestionsContent = () => {
		const { serviceOrderRating, serviceOrderComplete, serviceOrderReopen, visitTimeArrive, visitTimeDelay, serviceOrderObs } = ServiceOrderFeedbackQuestions;

		return (
			<>
				<FormQuestionLine order={serviceOrderRating.order} />
				<div className={formItemClass(serviceOrderRating.formName)}>
					<label>{serviceOrderRating.question}</label>
					<FormValidator {...{ formName: serviceOrderRating.formName, errors, isDirty }} />
					<RatingField
						{...{
							register,
							formName: serviceOrderRating.formName,
							onChange: (value: string, field: string) => {
								onFieldChange(value, field);
							},
							value: serviceOrderFeedback?.serviceOrderRating.toString(),
						}}
					/>
				</div>
				<FormQuestionLine order={serviceOrderComplete.order} />
				<div className={formItemClass(serviceOrderComplete.formName)}>
					<label>{serviceOrderComplete.question}</label>
					<FormValidator {...{ formName: serviceOrderComplete.formName, errors, isDirty }} />
					<OptionsField
						{...{
							register,
							formName: serviceOrderComplete.formName,
							onChange: (value: string, field: string) => {
								onFieldChange(value, field, onReopenQuestionClick);
							},
							value: serviceOrderFeedback?.serviceOrderComplete,
						}}
					/>
				</div>
				{reopenQuestionContent(serviceOrderReopen)}
				<FormQuestionLine order={visitTimeArrive.order} />
				<div className={formItemClass(visitTimeArrive.formName)}>
					<label>{visitTimeArrive.question}</label>
					<FormValidator {...{ formName: visitTimeArrive.formName, errors, isDirty }} />
					<OptionsField
						{...{
							register,
							formName: visitTimeArrive.formName,
							onChange: (value: string, field: string) => {
								onFieldChange(value, field, onVisitDelayQuestionClick);
							},
							value: serviceOrderFeedback?.visitTimeArrive,
						}}
					/>
				</div>
				{visitDelayQuestionContent(visitTimeDelay)}
				<FormQuestionLine order={serviceOrderObs.order} />
				<div className={formItemClass(serviceOrderObs.formName)}>
					<label>{serviceOrderObs.question}</label>
					{messageInput(serviceOrderObs.formName)}
					<span className="characters-remaining">{msg} caracteres restantes</span>
				</div>
			</>
		);
	};

	return (
		<div className="service-order-feedback-form">
			<div className="feedback-form-header">
				<h5>Avalie o atendimento referente ao protocolo {serviceOrder.service_order} e nos ajude a aprimorar nossos serviços!</h5>
			</div>
			<form onSubmit={handleSubmit(onSubmit)} className="main-form-content">
				{formQuestionsContent()}
				<div className="feedback-form-actions">
					<CustomButton context="primary" type="submit" label="ENVIAR" />
					<CustomButton context="secondary" label="CANCELAR" onClick={() => onClose()} />
				</div>
			</form>
		</div>
	);
};

export default ServiceOrderFeedbackForm;
