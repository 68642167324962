/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

const EquipmentTransferConflict: React.FC = () => {
	return (
		<div className="equip-add-conflict-message">
			<span>
				O número de série informado não está atrelado ao CNPJ/CPF em questão. Deseja enviar uma solicitação de transferência de titularidade do equipamento ou editar os
				dados informados?
			</span>
		</div>
	);
};

export default EquipmentTransferConflict;
