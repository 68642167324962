/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import TechnicalAssists from 'models/TechnicalAssists';
import React from 'react';

interface Params {
	techAssist: TechnicalAssists;
}

const ClosedMessage: React.FC<Params> = ({ techAssist }) => {
	const closedMessageContent = () => {
		if (techAssist.message) {
			const closedData = JSON.parse(techAssist.message);
			return (
				<>
					<div>
						<span className="att-information">
							<b>Mensagem</b>: {closedData.message}
						</span>
						<p className="att-information">
							<b>Contato</b>: {closedData.contact} | <b>Cargo</b>: {closedData.occupation}
						</p>
					</div>
				</>
			);
		} else {
			return <></>;
		}
	};

	return (
		<div className="default-description">
			<span>
				<b>Atendimento técnico finalizado com sucesso!</b>
			</span>
			{closedMessageContent()}
		</div>
	);
};

export default ClosedMessage;
