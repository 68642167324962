/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import MobileNavigator from 'components/Shared/MobileNavigator';
import React from 'react';
import history from '../../../../routes/history';
import './styles.scss';

interface Params {
	action?: () => void;
}

const MobileStoresSettingsHeader: React.FC<Params> = ({ action }) => {
	const navigatorAction = () => {
		if (action) {
			action();
		} else {
			history.goBack();
		}
	};

	return (
		<div>
			<section className="stores-settings-mobile-header-content">
				<div>
					<MobileNavigator action={navigatorAction} />
				</div>
				<div className="stores-settings-center-title">
					<span>Configurar lojas</span>
				</div>
			</section>
		</div>
	);
};

export default MobileStoresSettingsHeader;
