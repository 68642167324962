import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
	const keyName = process.env.REACT_APP_STORE_KEY || 'appStore';
	const persistedReducers = persistReducer(
		{
			key: keyName,
			storage,
			whitelist: ['auth', 'data'],
		},
		reducers,
	);

	return persistedReducers;
};
