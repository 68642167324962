/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import EquipmentsHistoryContext from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContext';
import Equipment from 'models/Equipment';
import { RequisitionError } from 'models/RequisitionError';
import React, { createContext, useEffect, useState } from 'react';
import { getEquipmentById, getEquipmentBySerialNumber } from 'services';
import { EquipmentsDetailsContextType } from './EquipmentsDetailsContextType';

export const EquipmentsDetailsContextElement = createContext<EquipmentsDetailsContextType>({
	equip: {} as Equipment,
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	onEquipChange: () => {},
	onSerialChange: () => {},
});

const EquipmentsProvider = (props: any) => {
	const [equip, setEquip] = useState<Equipment>();
	const [equipId, setEquipId] = useState<number>();
	const [serialNumber, setSerialNumber] = useState<string>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<RequisitionError>();

	useEffect(() => {
		requestEquips();
	}, [equipId || serialNumber]);

	const requestEquips = () => {
		if (equipId || serialNumber) {
			setLoading(true);

			const request = equipId ? getEquipmentById(equipId) : getEquipmentBySerialNumber(serialNumber);

			request
				.then(res => {
					const { equip } = res.data;
					setEquip(equip);
					setLoading(false);
				})
				.catch(err => {
					const error = err.request.response?.error;
					const reportError = {
						message: error ? error.message : 'Internal Server Error',
						code: error ? error.statusCode : 500,
						error: error ? error.name : 'ServerError',
					};
					setHasError(reportError);
					setLoading(false);
				});
		}
	};

	const onEquipChange = (equipId: number) => {
		setEquipId(equipId);
	};

	const onSerialChange = (serialNumber: string) => {
		setSerialNumber(serialNumber);
	};

	const providerValues = () => {
		return {
			equip,
			equipId,
			isLoading,
			hasError,
			onEquipChange,
			onSerialChange,
		};
	};

	return (
		<EquipmentsDetailsContextElement.Provider value={providerValues()}>
			<EquipmentsHistoryContext>{props.children}</EquipmentsHistoryContext>
		</EquipmentsDetailsContextElement.Provider>
	);
};

export default EquipmentsProvider;
