/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import TechnicalAssists from 'models/TechnicalAssists';
import React from 'react';

interface Params {
	techAssist: TechnicalAssists;
}

const FollowUpMessage: React.FC<Params> = ({ techAssist }) => {
	const followUpMessageContent = () => {
		if (techAssist.message) {
			const followUp = JSON.parse(techAssist.message);
			return (
				<>
					<div>
						<span>
							<b>Nota de acompanhamento: </b>
							{followUp.message}
						</span>
						<p className="att-information">
							<b>Contato</b>: {followUp.contact} | <b>Cargo</b>: {followUp.occupation}
						</p>
					</div>
				</>
			);
		} else {
			return <></>;
		}
	};

	return <div className="default-description">{followUpMessageContent()}</div>;
};

export default FollowUpMessage;
