/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { AccountInput } from 'models/Account';
import RequestResponse from 'models/RequestResponse';
import { RequisitionError } from 'models/RequisitionError';
import { TransferInput } from 'models/Transfer';

export interface TransfersContextType {
	hasError?: RequisitionError;
	isLoading: boolean;
	onVerifyEquip: (transferInput: TransferInput, callback: (request: RequestResponse) => void) => void;
	onRequestTransfer: (transferInput: TransferInput, callback: (request: RequestResponse) => void) => void;
	onRequestTransferByAccount: (newAccount: AccountInput) => void;
}

export const TransfersContextValue = {
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	onVerifyEquip: () => {},
	onRequestTransfer: () => {},
	onRequestTransferByAccount: () => {},
};
