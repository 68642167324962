/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { AccountInput } from 'models/Account';
import EquipReport from 'models/EquipReport';
import RequestResponse from 'models/RequestResponse';
import { RequisitionError } from 'models/RequisitionError';
import { TransferInput } from 'models/Transfer';
import React, { createContext, useState } from 'react';
import { registerTransfer, registerTransferByAccount, verifyEquipment } from 'services';
import { TransfersContextType, TransfersContextValue } from './TransfersContextType';

export const TransfersContextElement = createContext<TransfersContextType>(TransfersContextValue);

const TransfersProvider = (props: any) => {
	const [isLoading, setLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<RequisitionError>();

	const onRequestTransferByAccount = (newAccount: AccountInput) => {
		setLoading(true);
		registerTransferByAccount(newAccount)
			.then(res => {
				setLoading(false);
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				setHasError(reportError);
				setLoading(false);
			});
	};

	const onRequestTransfer = (transferInput: TransferInput, callback: (request: RequestResponse) => void) => {
		registerTransfer(transferInput)
			.then(res => {
				callback({ response: res.data });
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				setHasError(reportError);
				callback({ reportError });
			});
	};

	const onVerifyEquip = (transferInput: TransferInput, callback: (request: RequestResponse) => void) => {
		const equipReport = { ...transferInput } as EquipReport;
		verifyEquipment(equipReport)
			.then(res => {
				callback({ response: res.data.response });
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				setHasError(reportError);
				callback({ reportError });
			});
	};

	const providerValues = () => {
		return {
			hasError,
			isLoading,
			onRequestTransfer,
			onVerifyEquip,
			onRequestTransferByAccount,
		};
	};

	return <TransfersContextElement.Provider value={providerValues()}>{props.children}</TransfersContextElement.Provider>;
};

export default TransfersProvider;
