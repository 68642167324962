/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { ButtonOptions, PaginatorOptions, SearchOptions } from 'models/PageListOptions';
import React from 'react';
import PaginatorLimit from '../PaginatorLimit';
import SearchInput from '../SearchInput';
import './styles.scss';

interface Params {
	searchOptions: SearchOptions;
	buttonOptions?: ButtonOptions;
	paginatorOptions?: PaginatorOptions;
	secondaryClass?: string;
}

const PageListOptions: React.FC<Params> = ({ searchOptions, buttonOptions, paginatorOptions, secondaryClass }) => {
	const fullClass = `${buttonOptions ? 'full-comp' : 'non-button'}`;
	const className = `page-list-options-content${secondaryClass ? ` ${secondaryClass}` : ''} ${fullClass}`;

	const buttonContent = () => {
		return buttonOptions ? (
			<div className="icon-button-action" onClick={buttonOptions.onClick}>
				{buttonOptions.icon}
			</div>
		) : (
			<></>
		);
	};

	const paginatoContent = () => {
		return paginatorOptions ? <PaginatorLimit {...{ ...paginatorOptions }} /> : <></>;
	};

	return (
		<div {...{ className }}>
			<SearchInput {...{ ...searchOptions }} />
			{buttonContent()}
			{paginatoContent()}
		</div>
	);
};

export default PageListOptions;
