/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import { ServiceOrderStatusLabels, ServiceOrderStatusTypes } from 'utils/constants';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';

interface Params {
	statusList?: string[];
	onStatusSelected: (statusList: string[]) => void;
}

const StatusField: React.FC<Params> = ({ statusList, onStatusSelected }) => {
	const isChecked = (value: string) => {
		if (statusList?.length) {
			return statusList.includes(value);
		}
		return false;
	};

	const onClick = (statusSelected: string) => {
		if (statusList?.length) {
			const currentIndex = statusList.findIndex((status: string) => status === statusSelected);
			const newSelectedList = [...statusList];

			currentIndex === -1 ? newSelectedList.push(statusSelected) : newSelectedList.splice(currentIndex, 1);

			onStatusSelected(newSelectedList);
		} else {
			onStatusSelected([statusSelected]);
		}
	};

	return (
		<FormControl>
			<FormGroup>
				<FormControlLabel
					label={ServiceOrderStatusLabels.PROGRESS}
					control={
						<Checkbox
							checked={isChecked(ServiceOrderStatusTypes.PROGRESS)}
							onChange={() => onClick(ServiceOrderStatusTypes.PROGRESS)}
							name={ServiceOrderStatusTypes.PROGRESS}
						/>
					}
				/>
				<FormControlLabel
					label={ServiceOrderStatusLabels.CLOSED}
					control={
						<Checkbox
							checked={isChecked(ServiceOrderStatusTypes.CLOSED)}
							onChange={() => onClick(ServiceOrderStatusTypes.CLOSED)}
							name={ServiceOrderStatusTypes.CLOSED}
						/>
					}
				/>
			</FormGroup>
		</FormControl>
	);
};

export default StatusField;
