/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */
import React from 'react';

const VisitPreventedFieldControl: React.FC = () => {
	return (
		<div className="default-description">
			<span>
				<b>Impedimento registrado pelo técnico</b>
			</span>
		</div>
	);
};

export default VisitPreventedFieldControl;
