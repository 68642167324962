/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import FileIcon from 'components/Shared/FileIcon';
import FilesCenter from 'components/Shared/FilesCenter';
import Modal from 'components/Shared/Modal';
import { ServiceOrderDetailsContextElement } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContext';
import { ServiceOrderDetailsContextType } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContextType';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { cepMaskFormat, parseEquipName, phoneMask } from 'utils/pipe';
import './styles.scss';

const ServiceOrderInfoWeb: React.FC = () => {
	const { serviceOrderDetail } = useContext<ServiceOrderDetailsContextType>(ServiceOrderDetailsContextElement);
	const [showFilesModal, setShowFilesModal] = useState<boolean>(false);
	const equipment = serviceOrderDetail?.equipment;
	const visitInfo = serviceOrderDetail?.visitInfo;
	const serviceOrder = serviceOrderDetail?.serviceOrder;
	const technician = serviceOrderDetail?.technician;
	const files = serviceOrderDetail?.files || [];

	const locationContent = () => {
		if (serviceOrder) {
			const address = `${serviceOrder.address}, ${serviceOrder.neighborhood}.`;
			const region = `${serviceOrder.city.toLocaleLowerCase()}, ${serviceOrder.state_sigla} - ${cepMaskFormat(serviceOrder.zip_code)}`;

			return (
				<div className="service-order-info-location">
					<b>Endereço do atendimento: </b>
					<span>{address.toLocaleLowerCase()}</span>
					<span>{region}</span>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const visitContent = () => {
		if (visitInfo?.date) {
			return (
				<div className="service-order-info-visit">
					<b>Visita: </b>
					<span>
						{moment(visitInfo.date)
							.add(3, 'hours')
							.format('LL')}
					</span>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const techContent = () => {
		if (technician) {
			return (
				<>
					<div className="service-order-info-tech-name">
						<b>Técnico: </b>
						<span>{technician.name}</span>
					</div>
					<div className="service-order-info-tech-contact">
						<b>Contato: </b>
						<span>{phoneMask(technician.phone)}</span>
					</div>
				</>
			);
		} else {
			return <></>;
		}
	};

	const closeFilesModal = () => {
		setShowFilesModal(false);
	};

	const listFilesModalContent = () => {
		return (
			<Modal title="Anexos do Atendimento" defaultTitle="Anexos do Atendimento" onClose={closeFilesModal} show={showFilesModal} showClose>
				<FilesCenter {...{ techAssistFiles: files, onClose: closeFilesModal }} />
			</Modal>
		);
	};

	const serviceOrderFilesContent = () => {
		if (files?.length) {
			return (
				<div
					className="service-order-equip-files"
					onClick={() => {
						setShowFilesModal(true);
					}}
				>
					<FileIcon />
					<span>Anexos do Atendimento</span>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const mainContent = () => {
		return (
			<section className="service-order-web-info-content">
				<div className="service-order-equip">
					<h1>{parseEquipName(equipment?.name)}</h1>
					<span>
						<b>Número de série:</b> {equipment?.serial_number}
					</span>
					{serviceOrderFilesContent()}
				</div>
				<div className="service-order-info">
					{locationContent()}
					<div className="service-order-info-extra">
						<div className="service-order-info-protocol">
							<b>Protocolo: </b>
							<span>{serviceOrder?.service_order}</span>
						</div>
						{visitContent()}
						{techContent()}
					</div>
				</div>
				{listFilesModalContent()}
			</section>
		);
	};

	return serviceOrderDetail ? mainContent() : <></>;
};

export default ServiceOrderInfoWeb;
