/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import Files from 'models/OsFiles';
import moment from 'moment';
import { mask } from 'remask';
import history from 'routes/history';
import { lastEl, StepTypes } from 'utils/constants';

/**
 * CEP Mask Constants;
 */
const MIN_CEP = 8;
const MAX_CEP = 9;
const INI_CEP = 0;
const STP_CEP = 5;
const END_CEP = 8;

/**
 * @description Receive a CEP value and verify if is masked, or put a mask on it.
 * @param value CEP to verify format and mask
 * @returns CEP with mask
 */
export function cepMaskFormat(value: string) {
	if (value.length < MIN_CEP || value.length > MAX_CEP) {
		return value;
	}

	if (value.includes('-')) {
		return value;
	} else {
		const startValues = value.substring(INI_CEP, STP_CEP);
		const endValues = value.substring(STP_CEP, END_CEP);

		return `${startValues}-${endValues}`;
	}
}

const invalidNameList = [
	{ old: 'PORTAS/ARMARIO', new: 'PORTAS COM ARMARIO' },
	{ old: 'FERMENTACAO/RETARDO', new: 'FERMENTACAO COM RETARDO' },
	{ old: 'RETARDO/C/PISO/STANDARD/V2', new: 'RETARDO COM PISO STANDARD V2' },
	{ old: 'CFM-2C/FERMENTACAO', new: 'CFM-2C COM FERMENTACAO' },
	{ old: 'MOD.CANOVA300', new: 'MOD. CANOVA300' },
	{ old: 'MOD.AUTOMEC350', new: 'MOD. AUTOMEC350' },
	{ old: 'RESFRIADO/', new: 'RESFRIADO COM' },
	{ old: '  ', new: ' ' },
];

/**
 * @description Receive a equipment name and replace some values,
 * based on invalidNameList
 * @returns a new equipment name.
 */
export function parseEquipName(value?: string) {
	let newName = '';
	if (value) {
		for (let index = 0; index < invalidNameList.length; index++) {
			const name = invalidNameList[index];
			const foundValue = value.includes(name.old);
			if (foundValue) {
				newName = value.replace(name.old, name.new);
				break;
			}
		}
	}

	return newName || value;
}

/**
 * Phone Mask Constants;
 */
const MIN_PHONE_SIZE = 10;
const MAX_PHONE_SIZE = 11;

const INI_DDD = 0;
const END_DDD = 2;
const ESPECIAL_NUM = 2;
const INI_PHONE = 3;
const STP_PHONE = 7;

/**
 * @description Receive a Phone/Tel string and returns on masked format.
 * @param value Phone or Telephone number.
 * @returns Phone or Telephone on masked format.
 */
export function phoneMask(value?: string) {
	if (value) {
		const clearValue = value.replace(/\D/g, '');

		if (clearValue.length < MIN_PHONE_SIZE || clearValue.length > MAX_PHONE_SIZE) {
			return value;
		}

		if (clearValue.length === MAX_PHONE_SIZE) {
			const dddValues = clearValue.substring(INI_DDD, END_DDD);
			const especialNum = clearValue.substring(ESPECIAL_NUM, ESPECIAL_NUM + 1);
			const phoneStartValue = clearValue.substring(INI_PHONE, STP_PHONE);
			const phoneEndValue = clearValue.substring(STP_PHONE, clearValue.length + 1);

			return `(${dddValues}) ${especialNum} ${phoneStartValue}-${phoneEndValue}`;
		} else {
			const dddValues = clearValue.substring(INI_DDD, END_DDD);
			const phoneStartValue = clearValue.substring(INI_PHONE - 1, STP_PHONE - 1);
			const phoneEndValue = clearValue.substring(STP_PHONE - 1, clearValue.length + 1);

			return `(${dddValues}) ${phoneStartValue}-${phoneEndValue}`;
		}
	}
}

/**
 * @description Method that returns a real file type, based on his name and data.
 * @param fileName File name
 * @param fileType File Type that was inputed by other services.
 * @returns Returns a File Type.
 */
export function parseFileFormat(fileName: string, fileType: string) {
	if (fileName) {
		const fileNameTexts = fileName.split('.');
		const textsLength = fileNameTexts.length;

		return fileNameTexts[textsLength - 1].toUpperCase();
	} else {
		return fileType.toUpperCase();
	}
}

/**
 * @description Method that returns a real file type, based on his name and data.
 * @param fileName File name
 * @param fileType File Type that was inputed by other services.
 * @returns Returns a File Type.
 */
export function parseFileUploadedType(fileType: string) {
	return lastEl(fileType.split('/')).toUpperCase();
}

/**
 * @description Method that returns a name for a file.
 * @param type StepType that file is related.
 * @param file File to be named
 * @returns Returns a parsed name for a file.
 */
export function parseFileNameByStepType(file: Files, type?: StepTypes) {
	if (file.name) {
		return file.name;
	} else {
		switch (type) {
			case StepTypes.QUOTE:
				return `orcamento_${moment(file.created_date).format('DD-MM-YYYY')}.${file.file_type.toLowerCase()}`;
			case StepTypes.INVOICE:
				return `nfe_${moment(file.created_date).format('DD-MM-YYYY')}.${file.file_type.toLowerCase()}`;
			default:
				return `anexo.${file.file_type.toLowerCase()}`;
		}
	}
}

/**
 * @description Convert a file from Input to base64
 * @param file File selected by user on Input Field
 */
export function toBase64(file: File) {
	return new Promise<string | ArrayBuffer>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function() {
			if (reader.result) {
				resolve(reader.result);
			} else {
				reject();
			}
		};
		reader.onerror = function(error) {
			reject(error);
		};
	});
}

/**
 * @description Get values from URL query.
 * @param resetUrl Flag to reset URL values and address bar
 * @returns Returns a object with params from URL.
 */
export function getValuesFromPath(resetUrl = true) {
	const paramsQuery = history?.location.search;
	const params = {} as any;
	if (paramsQuery) {
		const query = paramsQuery.substring(1, paramsQuery.length);
		const fields = query.split('&');
		if (fields) {
			fields.forEach(field => {
				const brokeString = field.split('=');
				const fieldName = brokeString[0];
				const fieldValue = brokeString[1] ? decodeURIComponent(brokeString[1]) : undefined;
				params[fieldName] = fieldValue;
			});

			if (resetUrl) {
				window.history.pushState({}, document.title, history?.location?.pathname);
			}
		}
	}

	return params;
}

/**
 * @description Input a phone mask format on input field
 * @param value Value to be masked
 * @returns Returns the masked value.
 */
export function inputPhoneMask(value: string) {
	const tel = ['(99) 99999-9999'];
	const originalValue = mask(value, tel);
	const maskedValue = mask(originalValue, tel);
	return maskedValue;
}

/**
 * @description Input a CPF/CNPJ mask format on input field
 * @param value Value to be masked
 * @returns Returns the masked value.
 */
export function inputCNPJMask(v: any) {
	//Remove tudo o que não é dígito
	v = v.replace(/\D/g, '');

	if (v.length < 14) {
		//CPF

		//Coloca um ponto entre o terceiro e o quarto dígitos
		v = v.replace(/(\d{3})(\d)/, '$1.$2');

		//Coloca um ponto entre o terceiro e o quarto dígitos
		//de novo (para o segundo bloco de números)
		v = v.replace(/(\d{3})(\d)/, '$1.$2');

		//Coloca um hífen entre o terceiro e o quarto dígitos
		v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	} else {
		//CNPJ

		//Coloca ponto entre o segundo e o terceiro dígitos
		v = v.replace(/^(\d{2})(\d)/, '$1.$2');

		//Coloca ponto entre o quinto e o sexto dígitos
		v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

		//Coloca uma barra entre o oitavo e o nono dígitos
		v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');

		//Coloca um hífen depois do bloco de quatro dígitos
		v = v.replace(/(\d{4})(\d)/, '$1-$2');
	}

	return v;
}
