/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import moment from 'moment';
import React, { useContext } from 'react';
import { Notification } from 'models/Notification';
import MoreOptions from 'components/Shared/MoreOptions';
import { MoreOptionsItem } from 'models/MoreOptionsItem';
import { NotificationsContextElement } from 'contexts/NotificationsContext/NotificationsContext';
import { NotificationsContextType } from 'contexts/NotificationsContext/NotificationsContextType';

import './styles.scss';

interface Params {
	notify: Notification;
	onItemClick: (notify: Notification) => void;
}

const NotificationListItem: React.FC<Params> = ({ notify, onItemClick }) => {
	const { onChangeStatus, onRemove } = useContext<NotificationsContextType>(NotificationsContextElement);

	const actionName = !notify.readAt ? 'Não Lida' : 'Lida';

	const className = !notify.readAt ? 'mark-as' : 'unmark-as';

	const removeOption: MoreOptionsItem = { label: 'Excluir', onClick: onRemove, item: notify.id };

	const moreOptions = [removeOption];

	return (
		<div className={`notifications-list-item ${className}`} onClick={() => onItemClick(notify)}>
			<div className="notifications-info">
				<div className="title">
					<span>{notify.title}</span>
				</div>
				<div className="message">
					<span>{notify.body}</span>
				</div>
				<div className="protocol">
					<span>{notify.osNumber}</span>
				</div>
				<div className="created">
					<span>{moment(notify.createdAt).format('DD/MM/YYYY')}</span>
				</div>
				<div
					className="status"
					onClick={e => {
						e.stopPropagation();
						onChangeStatus(notify.id);
					}}
				>
					<span
						className={className}
						onClick={e => {
							e.stopPropagation();
							onChangeStatus(notify.id);
						}}
					>
						{actionName}
					</span>
				</div>
			</div>
			<div className="options">
				<MoreOptions id="notifications-list-item-options" options={moreOptions} />
			</div>
		</div>
	);
};

export default NotificationListItem;
