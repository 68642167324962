/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import HasError from 'components/Shared/HasError';
import { EquipmentsHistoryContextElement } from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContext';
import { EquipmentsHistoryContextType } from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContextType';
import ServiceOrder from 'models/ServiceOrder';
import React, { useContext, useEffect, useState } from 'react';
import EquipmentServiceOrdersList from './ServiceOrdersList';
import './styles.scss';

interface Params {
	equipId?: number;
	onItemClick: (serviceOrder: ServiceOrder) => void;
	onCancelServiceOrderClick: (serviceOrder: ServiceOrder) => void;
}

const EquipmentServiceOrdersWeb: React.FC<Params> = ({ equipId, onItemClick, onCancelServiceOrderClick }) => {
	const { onEquipChange } = useContext<EquipmentsHistoryContextType>(EquipmentsHistoryContextElement);
	const [notFoundError, setNotFoundError] = useState<boolean>(false);

	useEffect(() => {
		requestEquipServiceOrders();
	}, []);

	const requestEquipServiceOrders = () => {
		if (equipId) {
			onEquipChange(equipId);
		} else {
			setNotFoundError(true);
		}
	};

	return (
		<section className="equip-service-orders-web-content">
			<EquipmentServiceOrdersList {...{ onCancelServiceOrderClick, onItemClick }} />
			<HasError show={notFoundError} />
		</section>
	);
};

export default EquipmentServiceOrdersWeb;
