/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import './styles.scss';

const MessageSuccessTutorial: React.FC = () => {
	const iconContent = () => {
		return (
			<div className="modal-icon">
				<i className="fa fa-caret-right" aria-hidden="true"></i>
			</div>
		);
	};

	return (
		<div className="modal-tutorial-success">
			<span>Para assisti-lo novamente, acesse o menu de Ajuda e, em Tutorial toque no botão {iconContent()} Rever.</span>
		</div>
	);
};

export default MessageSuccessTutorial;
