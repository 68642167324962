/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { Checkbox, FormControl, FormControlLabel, FormGroup, Tooltip } from '@material-ui/core';
import { StoreSettingsContextElement } from 'contexts/StoreSettingsContext/StoreSettingsContext';
import { StoreSettingsContextType } from 'contexts/StoreSettingsContext/StoreSettingsContextType';
import { RequisitionError } from 'models/RequisitionError';
import StoreSettings from 'models/StoreSettings';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import './styles.scss';

interface Params {
	storeSettings: StoreSettings;
}

const StoresSettingsListItem: React.FC<Params> = ({ storeSettings }) => {
	const { storeSelectedList, onSelectStores } = useContext<StoreSettingsContextType>(StoreSettingsContextElement);

	const usersContent = () => {
		let users = '';
		storeSettings.users?.map((u: any) => (users += `${u.name.toLowerCase()}; `));
		return users;
	};

	const isChecked = () => {
		return storeSelectedList.includes(storeSettings.clientId);
	};

	const onComplete = (hasError?: RequisitionError) => {
		if (hasError) {
			toast.error('Erro ao selecionar loja');
		}
	};

	const checkboxContent = () => {
		return (
			<FormControl>
				<FormGroup>
					<FormControlLabel
						label={storeSettings.clientName}
						control={<Checkbox checked={isChecked()} onChange={() => onSelectStores(storeSettings, onComplete)} name={storeSettings.clientName} />}
					/>
				</FormGroup>
			</FormControl>
		);
	};

	return (
		<div className="stores-settings-list-item">
			<div className="stores-settings-info">
				<div className="name">{checkboxContent()}</div>
				<div className="full-name">
					<span>{storeSettings.clientFullName}</span>
				</div>
				<div className="equips">
					<span>{storeSettings.equipsCount}</span>
				</div>
				<div className="users">
					<Tooltip title={usersContent()} interactive arrow placement="bottom">
						<span>{usersContent()}</span>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};

export default StoresSettingsListItem;
