import ResponseModal from 'components/Shared/ResponseModal';
import React from 'react';
import { StepTypes } from 'utils/constants';
import './styles.scss';

interface Params {
	status: string;
	show: boolean;
	onCloseCancelServiceOrderModalClick: () => void;
}

const CancelServiceOrderModalContentResponseError: React.FC<Params> = ({ status, show, onCloseCancelServiceOrderModalClick }) => {
	const handleMessageContent = () => {
		let message = '';

		switch (status) {
			case StepTypes.CANCEL_REQUEST:
				message = 'Já existe uma solicitação de cancelamento em andamento, para acompanhar acesse a ordem de serviços';
				break;
			default:
				message =
					'Não é possível solicitar o cancelamento desta ordem de serviço, pois ela já está em andamento. ' +
					'Caso deseje falar com algum atendente entre em contato pelo número (35) 3449-1200 ou pelo chat.';
				break;
		}

		return message;
	};

	const messageContent = () => {
		return (
			<div className="service-order-cancel-error-menssage">
				<span>{handleMessageContent()}</span>
			</div>
		);
	};

	const responseModalContent = () => {
		const children = messageContent();
		const secondaryClass = 'cancel-service-order-response';

		return <ResponseModal {...{ children, title: 'SOLICITAR CANCELAMENTO', secondaryClass, onClose: onCloseCancelServiceOrderModalClick }} />;
	};

	return show ? responseModalContent() : <></>;
};
export default CancelServiceOrderModalContentResponseError;
