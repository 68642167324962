/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import SideBar from 'components/Shared/SideBar';
import PageTitle from 'components/Shared/PageTitle';
import ResetPassForm from 'components/Account/ResetPass';
import PraticaImage from 'components/Shared/PraticaImage';

import './styles.scss';

const ResetPass: React.FC = () => {
	return (
		<>
			<PageTitle title="Restaurar senha" single />
			<section className="reset-pass-main-content">
				<SideBar>
					<ResetPassForm />
				</SideBar>
				<PraticaImage />
			</section>
		</>
	);
};

export default ResetPass;
