/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EmptyList from 'components/Shared/EmptyList';
import HasError from 'components/Shared/HasError';
import LoadingProgress from 'components/Shared/LoadingProgress';
import { ServiceOrderContextElement } from 'contexts/ServiceOrderContext/ServiceOrderContext';
import { ServiceOrderContextType } from 'contexts/ServiceOrderContext/ServiceOrderContextType';
import $ from 'jquery';
import { FilterData } from 'models/FilterData';
import ServiceOrder from 'models/ServiceOrder';
import React, { useContext, useState } from 'react';
import { onChildrenScroll } from 'utils/Screen';
import ServiceOrderLabel from '../ServiceOrderLabel';
import ServiceOrderListItem from '../ServiceOrderListItem';
import './styles.scss';

const SCROLL_TOP = 0;

interface Params {
	paginatorContent: JSX.Element;
	onItemClick: (serviceOrder: ServiceOrder) => void;
	onCancelServiceOrderClick: (serviceOrder: ServiceOrder) => void;
}

const ServiceOrderList: React.FC<Params> = ({ paginatorContent, onItemClick, onCancelServiceOrderClick }) => {
	const { serviceOrders, isLoading, ordersFilter, hasError, onFilterChange } = useContext<ServiceOrderContextType>(ServiceOrderContextElement);

	const [statusOrder, setStatusOrder] = useState(ordersFilter?.statusOrder || 'asc');
	const [createdOrder, setCreatedOrder] = useState(ordersFilter?.createdOrder || 'desc');

	$('.mobile-scroll').scrollTop(SCROLL_TOP);
	$('.orders-list-items').scrollTop(SCROLL_TOP);

	const onOrderChange = (type: string, order: 'desc' | 'asc') => {
		const newFilter = ordersFilter ? { ...ordersFilter } : ({} as FilterData);

		if (type === 'created') {
			newFilter.createdOrder = order;
			newFilter.statusOrder = statusOrder;
		} else {
			newFilter.statusOrder = order;
			newFilter.createdOrder = createdOrder;
		}

		setStatusOrder(newFilter.statusOrder);
		setCreatedOrder(newFilter.createdOrder);
		onFilterChange(newFilter);
	};

	const arrowCreateContent = () => {
		return createdOrder === 'desc' ? (
			<ArrowUpwardIcon onClick={() => onOrderChange('created', 'asc')} />
		) : (
			<ArrowDownwardIcon onClick={() => onOrderChange('created', 'desc')} />
		);
	};

	const arrowStatusContent = () => {
		return statusOrder !== 'asc' ? <ArrowUpwardIcon onClick={() => onOrderChange('status', 'asc')} /> : <ArrowDownwardIcon onClick={() => onOrderChange('status', 'desc')} />;
	};

	const headerContent = () => {
		return (
			<>
				<div className="orders-label-layer">
					<ServiceOrderLabel />
				</div>
				<div className="orders-list-header">
					<div className="list-header equip">
						<span>Equipamento</span>
					</div>
					<div className="list-header protocol">
						<span>Protocolo</span>
					</div>
					<div className="list-header created">
						<span>Data da Solicitação</span>
						{arrowCreateContent()}
					</div>
					<div className="list-header status">
						<span>Status</span>
						{arrowStatusContent()}
					</div>
					<div className="list-header options"></div>
				</div>
			</>
		);
	};

	const listContent = () => {
		const listItems = serviceOrders.map((serviceOrder: ServiceOrder, index: number) => (
			<ServiceOrderListItem {...{ onCancelServiceOrderClick, onItemClick, serviceOrder }} key={index} />
		));

		return (
			<div
				className="orders-list-items"
				onScroll={e => {
					onChildrenScroll(e, 'orders-main-content');
				}}
			>
				<ServiceOrderLabel />
				{listItems}
				{paginatorContent}
			</div>
		);
	};

	const handleMainContent = () => {
		if (isLoading) {
			return <LoadingProgress show text="Buscando atendimentos..." />;
		} else if (hasError) {
			return <HasError show />;
		} else if (!hasError && !isLoading && !serviceOrders.length) {
			return <EmptyList show text="Sem atendimentos por aqui" />;
		} else {
			return listContent();
		}
	};

	return (
		<section className="orders-list-content">
			{headerContent()}
			{handleMainContent()}
		</section>
	);
};

export default ServiceOrderList;
