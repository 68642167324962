/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import OpenServiceOrder from 'components/OpenServiceOrder';
import LoadingProgress from 'components/Shared/LoadingProgress';
import Modal from 'components/Shared/Modal';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { EquipmentsDetailsContextElement } from 'contexts/EquipmentsDetailsContext/EquipmentsDetailsContext';
import { EquipmentsDetailsContextType } from 'contexts/EquipmentsDetailsContext/EquipmentsDetailsContextType';
import OpenServiceOrderContext from 'contexts/OpenServiceOrderContext/OpenServiceOrderContext';
import Equipment from 'models/Equipment';
import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DeviceType } from 'utils/constants';
import { parseEquipName } from 'utils/pipe';
import './styles.scss';

interface Params {
	equipment?: Equipment;
	onClose: () => void;
}

const EquipmentsListItemDetails: React.FC<Params> = ({ equipment, onClose }) => {
	const { deviceType } = useContext<DeviceContextType>(DeviceContextElement);
	const { equip, isLoading, onEquipChange } = useContext<EquipmentsDetailsContextType>(EquipmentsDetailsContextElement);

	useEffect(() => {
		if (equipment) {
			onEquipChange(equipment.id);
		} else {
			onClose();
			toast.error('Erro ao carregar informações do equipamento.');
		}
	}, []);

	const openServiceOrderModalContent = () => {
		if (equip) {
			const title = deviceType !== DeviceType.MOBILE ? 'Solicitação de Atendimento' : parseEquipName(equip.name) || equip.name;

			const subTitle = deviceType === DeviceType.MOBILE ? `N/S: ${equip.serial_number}` : '';

			return (
				<OpenServiceOrderContext>
					<Modal show={!isLoading} title={title} subTitle={subTitle} defaultTitle={title} onClose={onClose} showClose enableAwayClose>
						<OpenServiceOrder onClose={onClose} equipment={equip} />
					</Modal>
				</OpenServiceOrderContext>
			);
		} else {
			return (
				<div className="equip-details-open-service-loading">
					<Modal show={isLoading} title="Solicitação de Atendimento" defaultTitle="Solicitação de Atendimento" onClose={onClose}>
						<LoadingProgress show={isLoading} text="Carregando..." />
					</Modal>
				</div>
			);
		}
	};

	return openServiceOrderModalContent();
};

export default EquipmentsListItemDetails;
