/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { AccountInput } from 'models/Account';
import { LoginInput } from 'models/LoginInfo';
import { RequisitionError } from 'models/RequisitionError';
import User, { UserInput } from 'models/User';
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUser, resetPass, updatePass, updateUser, userFirstAccess } from 'services';
import { store } from 'store';
import { updateUserInfo } from 'store/modules/data/actions';
import { AccountContextType } from './AccountContextType';

export const AccountContextElement = createContext<AccountContextType>({
	user: {} as User,
	isLoading: {} as boolean,
	onCreateAccount: () => {},
	onFirstAccess: () => {},
	onResetPass: () => {},
	onUpdatePass: () => {},
	onUpdateUser: () => {},
});

const AccountProvider = (props: any) => {
	const [user, setUser] = useState<User>(store.getState().data.user);
	const [isLoading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		setUser(store.getState().data.user);
	}, [store.getState().data.user]);

	const dispatch = useDispatch();

	const onFirstAccess = async (account: AccountInput, callback: (hasError?: RequisitionError) => void) => {
		setLoading(true);
		userFirstAccess(account)
			.then(res => {
				setLoading(false);
				callback();
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				setLoading(false);
				callback(reportError);
			});
	};

	const onCreateAccount = async (newAccount: AccountInput, callback: (hasError?: RequisitionError) => void) => {
		setLoading(true);
		createUser(newAccount)
			.then(res => {
				setLoading(false);
				callback();
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				setLoading(false);
				callback(reportError);
			});
	};

	const onResetPass = async (email: string, callback: (hasError?: RequisitionError) => void) => {
		setLoading(true);
		resetPass({ email })
			.then(res => {
				setLoading(false);
				callback();
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				callback(reportError);
				setLoading(false);
			});
	};

	const onUpdatePass = async (newPassData: LoginInput, callback: (hasError?: RequisitionError) => void) => {
		setLoading(true);
		console.log(newPassData);
		if (newPassData) {
			updatePass(newPassData)
				.then(async res => {
					setLoading(false);
					callback();
				})
				.catch(err => {
					const error = err.request.response?.error;
					const reportError = {
						message: error ? error.message : 'Internal Server Error',
						code: error ? error.statusCode : 500,
						error: error ? error.name : 'ServerError',
					};
					callback(reportError);
					setLoading(false);
				});
		} else {
			const reportError = {
				message: 'Invalid Params',
				error: 'Invalid Params',
			};
			callback(reportError);
			setLoading(false);
		}
	};

	const onUpdateUser = (newUserData: UserInput, callback: (hasError?: RequisitionError) => void) => {
		updateUser(newUserData)
			.then(res => {
				const { userUpdated } = res.data;
				const userInfo = { ...store.getState().data.user };
				userInfo.name = userUpdated.name;
				userInfo.phone = userUpdated.phone;
				userInfo.email = userUpdated.email;
				userInfo.email_gestor = userUpdated.email_gestor;
				setUser(userInfo);
				dispatch(updateUserInfo(userInfo));
				callback();
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				callback(reportError);
			});
	};

	const providerValues = () => {
		return { user, isLoading, onCreateAccount, onResetPass, onUpdatePass, onUpdateUser, onFirstAccess };
	};

	return <AccountContextElement.Provider value={providerValues()}>{props.children}</AccountContextElement.Provider>;
};

export default AccountProvider;
