/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import _ from 'lodash';
import ServiceOrder from 'models/ServiceOrder';
import { FilterData } from 'models/FilterData';
import { getServiceOrdersByEquip } from 'services';
import { RequisitionError } from 'models/RequisitionError';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { EquipmentsHistoryContextType } from './EquipmentsHistoryContextType';

const INITIAL_PAGE = 1;
const DEFAULT_LIMIT = 20;

export const EquipmentsHistoryContextElement = createContext<EquipmentsHistoryContextType>({
	serviceOrders: [],
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	equipId: {} as number,
	totalPages: {} as number,
	currentPage: {} as number,
	refreshList: () => {},
	onPageChange: () => {},
	onEquipChange: () => {},
	onFilterChange: () => {},
});

interface ListParams {
	pageLimit: number;
	currentPage: number;
	totalPages: number;
	searchValue?: string;
	ordersFilter?: FilterData;
}

interface TechOnService {
	serviceOrderId: number;
	techName: string;
	createdAt?: string;
}

function defaultListParams() {
	return {
		pageLimit: DEFAULT_LIMIT,
		currentPage: INITIAL_PAGE,
		totalPages: INITIAL_PAGE,
	};
}

const EquipmentsHistoryProvider = (props: any) => {
	const [equipId, setEquipId] = useState<number>();
	const [serviceOrders, setServiceOrders] = useState<ServiceOrder[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<RequisitionError>();
	const listParams = useRef<ListParams>(defaultListParams());

	useEffect(() => {
		clearListParams();
		requestServiceOrders();
	}, [equipId]);

	const requestServiceOrders = () => {
		if (equipId) {
			setLoading(true);

			const { currentPage, pageLimit, ordersFilter } = { ...listParams.current };
			const offset = (currentPage - 1) * pageLimit;

			getServiceOrdersByEquip(equipId, DEFAULT_LIMIT, offset, ordersFilter)
				.then(res => {
					const { count, serviceOrders, techOnService } = res.data.response;
					updateListParams(Math.ceil(count / pageLimit), 'count');
					setServiceOrders(buildEquipServiceOrderList(serviceOrders, techOnService));
					setLoading(false);
				})
				.catch(err => {
					const error = err.request.response?.error;
					const reportError = {
						message: error ? error.message : 'Internal Server Error',
						code: error ? error.statusCode : 500,
						error: error ? error.name : 'ServerError',
					};
					setHasError(reportError);
					setLoading(false);
				});
		}
	};

	const buildEquipServiceOrderList = (serviceOrders: ServiceOrder[], techOnService: TechOnService[]) => {
		if (serviceOrders.length) {
			return serviceOrders.map((service: ServiceOrder) => {
				const serviceTechs = techOnService.filter(i => i.serviceOrderId === service.id);
				const lastVisitTech = _.maxBy(serviceTechs, 'createdAt');
				return { ...service, techName: lastVisitTech?.techName };
			});
		}

		return serviceOrders;
	};

	const refreshList = () => {
		clearListParams();
		requestServiceOrders();
	};

	const onEquipChange = (equipId: number) => {
		setEquipId(equipId);
	};

	const onPageChange = (page: number) => {
		updateListParams(page, 'page');
		requestServiceOrders();
	};

	const onFilterChange = (newFilter: FilterData) => {
		updateListParams(newFilter, 'filter');
		clearListParams();
		requestServiceOrders();
	};

	const updateListParams = (value: number | string | FilterData, field: 'filter' | 'limit' | 'page' | 'search' | 'count') => {
		const newListParams = { ...listParams.current };

		switch (field) {
			case 'filter':
				newListParams.ordersFilter = value as FilterData;
				break;
			case 'limit':
				newListParams.pageLimit = value as number;
				break;
			case 'page':
				newListParams.currentPage = value as number;
				break;
			case 'count':
				newListParams.totalPages = value as number;
				break;
			case 'search':
				newListParams.searchValue = value as string;
				break;
		}

		listParams.current = newListParams;
	};

	const clearListParams = (clearAll = false) => {
		if (clearAll) {
			listParams.current = defaultListParams();
		} else {
			const newListParams = { ...listParams.current };
			newListParams.currentPage = INITIAL_PAGE;
			newListParams.totalPages = INITIAL_PAGE;
			listParams.current = newListParams;
		}
	};

	const providerValues = () => {
		return {
			serviceOrders,
			equipId,
			...listParams.current,
			isLoading,
			hasError,
			refreshList,
			onPageChange,
			onEquipChange,
			onFilterChange,
		};
	};

	return <EquipmentsHistoryContextElement.Provider value={providerValues()}>{props.children}</EquipmentsHistoryContextElement.Provider>;
};

export default EquipmentsHistoryProvider;
