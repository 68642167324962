export function signInRequest(user) {
	return {
		type: '@auth/SIGN_IN_REQUEST',
		payload: { user },
	};
}

export function signOutRequest(userId) {
	return {
		type: '@auth/SIGN_OUT_REQUEST',
		payload: { userId },
	};
}
