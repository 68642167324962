/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import EmptyList from 'components/Shared/EmptyList';
import HasError from 'components/Shared/HasError';
import LoadingProgress from 'components/Shared/LoadingProgress';
import MoreOptions from 'components/Shared/MoreOptions';
import { NotificationsContextElement } from 'contexts/NotificationsContext/NotificationsContext';
import { NotificationsContextType } from 'contexts/NotificationsContext/NotificationsContextType';
import { MoreOptionsItem } from 'models/MoreOptionsItem';
import moment from 'moment';
import React, { useContext } from 'react';
import { Notification } from '../../../../../models/Notification';
import './styles.scss';

interface Params {
	paginatorContent: JSX.Element;
	onItemClick: (notification: Notification) => void;
}

const NotificationsList: React.FC<Params> = ({ paginatorContent, onItemClick }) => {
	const { notificationsList, isLoading, hasError, onChangeStatus, onRemove } = useContext<NotificationsContextType>(NotificationsContextElement);

	const parseDate = (value: Date): string => {
		return moment(value).format('HH:mm | DD/MM/YYYY');
	};

	const changeAction = (notifyId: number, readAt?: Date) => {
		const actionName = !readAt ? 'Marcar como lida' : 'Marcar como não lida';

		const className = !readAt ? 'mark-as' : 'unmark-as';

		return (
			<span
				className={className}
				onClick={() => {
					onChangeStatus(notifyId);
				}}
			>
				{actionName}
			</span>
		);
	};

	const notificationListItemContent = (notify: Notification, index: number) => {
		return (
			<div className="notify-list-item-mobile" key={index}>
				<div className="list-item-row">
					<div
						className="list-item title"
						onClick={() => {
							onChangeStatus(notify.id, true);
							onItemClick(notify);
						}}
					>
						<span className={`${!notify.readAt ? 'not-read' : ''}`}>{notify.body}</span>
					</div>
					<div className="notify-item-menu">{handleMoreOptions(notify.id)}</div>
				</div>
				<div className="list-item-row">
					<div className="list-item options">
						{changeAction(notify.id, notify.readAt)}
						<span className="notify-time">{parseDate(new Date(notify.createdAt))}</span>
					</div>
				</div>
			</div>
		);
	};

	const handleMoreOptions = (notifyId: number) => {
		const removeOption: MoreOptionsItem = { item: notifyId, label: 'Excluir', onClick: onRemove };
		const moreOptions = [removeOption];
		return <MoreOptions id="notifications-list-item-options" options={moreOptions} />;
	};

	const listContent = () => {
		const listItems = notificationsList.map(notificationListItemContent);

		return (
			<div className="orders-list-items">
				{listItems}
				{paginatorContent}
			</div>
		);
	};

	const handleMainContent = () => {
		if (isLoading) {
			return <LoadingProgress show text="Buscando notificações..." />;
		} else if (hasError) {
			return <HasError show />;
		} else if (!hasError && !isLoading && !notificationsList.length) {
			return <EmptyList show text="Sem notificações por aqui" />;
		} else {
			return listContent();
		}
	};

	return <div className="notify-center-content">{handleMainContent()}</div>;
};

export default NotificationsList;
