/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

import './styles.scss';

const PraticaImage: React.FC = () => {
	return <section className="pratica-image"></section>;
};

export default PraticaImage;
