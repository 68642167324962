/**
 * Developed by: Inatel Competence Center
 * Copyright 2019, Prática
 * Author: Lucas Marciano
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */
import GlobalStyle from 'assets/styles/styles';
import AuthProvider from 'contexts/AuthContext/AuthContext';
import DeviceContext from 'contexts/DeviceContext/DeviceContext';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from 'routes';
import './assets/styles/styles.scss';
import { useSentry } from './hooks/use-sentry';
import History from './routes/history';
import { persistor, store } from './store';

const appHeight = () => {
	const innerHeight = window.innerHeight;
	const doc = document.documentElement;
	doc.style.setProperty('--app-height', `${innerHeight}px`);
};

window.addEventListener('resize', appHeight);
appHeight();
useSentry();

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<DeviceContext>
				<AuthProvider>
					<Router history={History}>
						<Routes />
						<GlobalStyle />
						<ToastContainer
							position="top-center"
							autoClose={5000}
							hideProgressBar
							newestOnTop={false}
							closeOnClick={false}
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							closeButton={false}
							className="toast-main-content"
							bodyClassName="toast-body-content"
						/>
					</Router>
				</AuthProvider>
			</DeviceContext>
		</PersistGate>
	</Provider>,
	document.getElementById('root'),
);
