/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import brLocale from 'date-fns/locale/pt-BR';
import React from 'react';

interface Params {
	initialDate?: Date;
	finalDate?: Date;
	onDateSelect: (value: Date, type: string) => void;
	onError: (value: any) => void;
}

const PeriodFields: React.FC<Params> = ({ initialDate, finalDate, onDateSelect, onError }) => {
	const mainContent = () => {
		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
				<KeyboardDatePicker
					cancelLabel="CANCELAR"
					clearLabel="LIMPAR"
					okLabel="OK"
					emptyLabel="DD/MM/AAAA"
					format="dd/MM/yyyy"
					id="date-initial-picker"
					label="De:"
					invalidDateMessage="Data invalida"
					value={initialDate || null}
					onChange={(value: Date) => {
						onDateSelect(value, 'initialDate');
					}}
					onError={(e, value) => {
						onError(value);
					}}
					KeyboardButtonProps={{
						'aria-label': 'change date',
						className: 'period-filter-keyboard-button',
					}}
				/>
				<KeyboardDatePicker
					format="dd/MM/yyyy"
					id="date-final-picker"
					cancelLabel="CANCELAR"
					clearLabel="LIMPAR"
					okLabel="OK"
					emptyLabel="DD/MM/AAAA"
					label="A:"
					minDate={new Date(initialDate || '')}
					invalidDateMessage="Data invalida"
					value={finalDate || null}
					onChange={(value: Date) => {
						onDateSelect(value, 'finalDate');
					}}
					KeyboardButtonProps={{
						'aria-label': 'change date',
						className: 'period-filter-keyboard-button',
					}}
				/>
			</MuiPickersUtilsProvider>
		);
	};

	return mainContent();
};

export default PeriodFields;
