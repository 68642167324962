/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { store } from 'store';

interface Params {
	title: string;
	single?: boolean;
}

const PageTitle: React.FC<Params> = ({ title, single }) => {
	const client = store.getState().data?.client;

	const pageTitle = () => {
		if (!single) {
			if (client) {
				const clientName = client.nome_fantasia;
				return `${title} - ${clientName} - Prática Klimaquip`;
			} else {
				return `${title} - Prática Klimaquip`;
			}
		}

		return title.toUpperCase();
	};

	return (
		<Helmet>
			<title>{pageTitle()}</title>
		</Helmet>
	);
};

export default PageTitle;
