/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useContext } from 'react';
import MobileHeader from 'components/Shared/MobileHeader';
import { cepMaskFormat, parseEquipName } from 'utils/pipe';
import BriefcaseIcon from 'components/Shared/BriefcaseIcon';
import { EquipmentsDetailsContextElement } from 'contexts/EquipmentsDetailsContext/EquipmentsDetailsContext';
import { EquipmentsDetailsContextType } from 'contexts/EquipmentsDetailsContext/EquipmentsDetailsContextType';

import './styles.scss';

interface Params {
	openNewServiceClick: () => void;
}

const EquipmentInfoMobile: React.FC<Params> = ({ openNewServiceClick }) => {
	const { equip } = useContext<EquipmentsDetailsContextType>(EquipmentsDetailsContextElement);

	const equipLoctionItemContent = (label: string, value: string) => {
		return (
			<div className="location-item">
				<div className="loacaiton-item-label">
					<span>{label}:</span>
				</div>
				<div className="loacaiton-item-value">
					<span>{value}</span>
				</div>
			</div>
		);
	};

	const equipLocationContent = () => {
		const location = equip?.location;
		if (location) {
			return (
				<>
					{equipLoctionItemContent('Rua/n°', location.street.toLocaleLowerCase())}
					{equipLoctionItemContent('Bairro', location.neighborhood.toLocaleLowerCase())}
					{equipLoctionItemContent('Cidade/UF', `${location.city.toLocaleLowerCase()}, ${location.state_sigla}`)}
					{equipLoctionItemContent('CEP', cepMaskFormat(location.zip_code))}
				</>
			);
		} else {
			return <></>;
		}
	};

	const equipWarrantContent = () => {
		const warrant = equip?.date_end_warranty;
		if (warrant) {
			return (
				<div className="equip-info-warrant">
					<b>Fim da garantia: </b>
					<span>
						{moment(warrant)
							.locale('pt-br')
							.format('LL')}
					</span>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const openServiceContent = () => {
		return (
			<>
				<BriefcaseIcon />
				<span>Solicitar Atendimento</span>
			</>
		);
	};

	const mainContent = () => {
		const subTitle = equip ? `N/S: ${equip.serial_number}` : '';

		const title = equip ? parseEquipName(equip.name) : '';

		return (
			<section className="equip-mobile-info-content">
				<MobileHeader title={title} subTitle={subTitle} defaultTitle="Equipamento" />
				{equipWarrantContent()}
				<div className="equip-info-extra">
					<div className="equip-info-location">{equipLocationContent()}</div>
					<div className="equip-open-service" onClick={() => openNewServiceClick()}>
						{openServiceContent()}
					</div>
				</div>
			</section>
		);
	};

	return mainContent();
};

export default EquipmentInfoMobile;
