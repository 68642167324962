/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import LoadingProgress from 'components/Shared/LoadingProgress';
import ResponseModal from 'components/Shared/ResponseModal';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import React, { useContext } from 'react';
import { ViewType } from 'utils/constants';
import './styles.scss';

interface Params {
	show: boolean;
	title: string;
	text: string;
}

const LoadingModal: React.FC<Params> = ({ show, title, text }) => {
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);

	const onLoadingContent = () => {
		return <LoadingProgress show text={text} />;
	};

	const onCloseModals = () => {};

	const responseModalContent = () => {
		const children = onLoadingContent();
		const secondaryClass = 'loading-message';
		const hideTitle = viewType === ViewType.MOBILE;

		return <ResponseModal {...{ children, title, secondaryClass, onClose: onCloseModals, hideTitle }} hideAction hideClose />;
	};

	return show ? responseModalContent() : <></>;
};

export default LoadingModal;
