/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import React, { useContext } from 'react';
import { ViewType } from 'utils/constants';
import EquipmentInfoMobile from './Mobile';
import EquipmentInfoWeb from './Web';

interface Params {
	openNewServiceClick: () => void;
}

const EquipmentInfo: React.FC<Params> = ({ openNewServiceClick }) => {
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);

	return viewType === ViewType.WEB ? <EquipmentInfoWeb {...{ openNewServiceClick }} /> : <EquipmentInfoMobile {...{ openNewServiceClick }} />;
};

export default EquipmentInfo;
