/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import EquipmentInfo from 'components/EquipmentDetails/EquipmentInfo';
import EquipmentServiceOrders from 'components/EquipmentDetails/EquipmentServiceOrders';
import OpenServiceOrder from 'components/OpenServiceOrder';
import HasError from 'components/Shared/HasError';
import LoadingProgress from 'components/Shared/LoadingProgress';
import Modal from 'components/Shared/Modal';
import PageTitle from 'components/Shared/PageTitle';
import CancelServiceOrderModalContent from 'components/Shared/ServiceOrderCancel/CancelServiceOrderContent';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { EquipmentsDetailsContextElement } from 'contexts/EquipmentsDetailsContext/EquipmentsDetailsContext';
import { EquipmentsDetailsContextType } from 'contexts/EquipmentsDetailsContext/EquipmentsDetailsContextType';
import OpenServiceOrderContext from 'contexts/OpenServiceOrderContext/OpenServiceOrderContext';
import ServiceOrderDetailsContext from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContext';
import ServiceOrder from 'models/ServiceOrder';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ViewType } from 'utils/constants';
import { parseEquipName } from 'utils/pipe';
import './styles.scss';

type TParams = {
	history: string;
	location: any;
	match: any;
};

const EquipmentDetails: React.FC<RouteComponentProps<TParams>> = ({ history, location, match }) => {
	const { equip, isLoading, hasError, onEquipChange, onSerialChange } = useContext<EquipmentsDetailsContextType>(EquipmentsDetailsContextElement);

	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);
	const [showOpenServiceOrderModal, setShowOpenServiceOrderModal] = useState<boolean>(false);
	const [notFoundError, setNotFoundError] = useState<boolean>(false);
	const [hasUpdate, setHasUpdate] = useState<boolean>(false);
	const [equipId, setEquipId] = useState<number>();
	const [serialNumber, setSerialNumber] = useState<string>();
	const [pageTitle, setPageTitle] = useState<string>('');

	const [serviceOrderToCancel, setServiceOrderToCancel] = useState<ServiceOrder>();
	const [showCancelServiceOrderModal, setShowCancelServiceOrderModal] = useState<boolean>(false);

	useEffect(() => {
		getRouterParams();
	}, []);

	useEffect(() => {
		if (equipId) {
			onEquipChange(equipId);
		}
	}, [equipId]);

	useEffect(() => {
		if (serialNumber) {
			onSerialChange(serialNumber);
		}
	}, [serialNumber]);

	useEffect(() => {
		if (serviceOrderToCancel) {
			setShowCancelServiceOrderModal(true);
		} else {
			setShowCancelServiceOrderModal(false);
		}
	}, [serviceOrderToCancel]);

	const getRouterParams = () => {
		if (location?.state) {
			const { equipment } = location.state as any;
			if (equipment) {
				setEquipId(equipment.id);
				setPageTitle(`Equipamento - ${equipment.name}, N/S: ${equipment.serial_number}`);
			} else {
				setNotFoundError(true);
			}
		} else {
			const params = match?.params as any;
			if (params) {
				const serialNumber = params.serial;
				if (serialNumber) {
					setSerialNumber(serialNumber);
					setPageTitle(`Equipamento - ${serialNumber}`);
				} else {
					setNotFoundError(true);
				}
			} else {
				setNotFoundError(true);
			}
		}
	};

	const onItemClick = (serviceOrder: ServiceOrder) => {
		if (equip && !serviceOrderToCancel) {
			history.push(`/equipamentos/${encodeURIComponent(equip.serial_number)}/atendimentos/${serviceOrder.service_order}`, { serviceOrder });
		} else {
			setNotFoundError(true);
		}
	};

	const showError = () => {
		return (notFoundError || hasError) as boolean;
	};

	const serviceOrderList = () => {
		if (equip) {
			const equipId = equip?.id;
			return <EquipmentServiceOrders {...{ onItemClick, equipId, hasUpdate, onCancelServiceOrderClick }} />;
		}

		return <></>;
	};

	const openServiceOrderToggle = () => {
		setShowOpenServiceOrderModal(true);
	};

	const closeServiceOrderToggle = (hasUpdate: boolean) => {
		setShowOpenServiceOrderModal(false);
		setHasUpdate(hasUpdate);
	};

	const onCancelServiceOrderClick = (serviceOrder: ServiceOrder) => {
		setServiceOrderToCancel(serviceOrder);
	};

	const onCloseCancelServiceOrderModalClick = () => {
		setServiceOrderToCancel(undefined);
	};

	const cancelServiceOrderContent = () => {
		return serviceOrderToCancel ? (
			<ServiceOrderDetailsContext>
				<CancelServiceOrderModalContent serviceOrder={serviceOrderToCancel} show={showCancelServiceOrderModal} onClose={onCloseCancelServiceOrderModalClick} />
			</ServiceOrderDetailsContext>
		) : (
			<></>
		);
	};

	const openServiceOrderModalContent = () => {
		if (equip) {
			const title = viewType === ViewType.WEB ? 'Solicitação de Atendimento' : parseEquipName(equip.name) || equip.name;

			const subTitle = viewType === ViewType.MOBILE ? `N/S: ${equip.serial_number}` : '';

			return (
				<OpenServiceOrderContext>
					<Modal
						show={showOpenServiceOrderModal}
						title={title}
						subTitle={subTitle}
						defaultTitle={title}
						onClose={() => {
							closeServiceOrderToggle(false);
						}}
						enableAwayClose
					>
						<OpenServiceOrder onClose={closeServiceOrderToggle} equipment={equip} />
					</Modal>
				</OpenServiceOrderContext>
			);
		} else {
			return <></>;
		}
	};

	const mainContent = () => {
		return (
			<section className="equipment-details-content">
				<EquipmentInfo openNewServiceClick={openServiceOrderToggle} />
				{serviceOrderList()}
				<HasError show={showError()} />
				<LoadingProgress show={isLoading || !equip} text="Buscando equipamento..." />
				{openServiceOrderModalContent()}
				{cancelServiceOrderContent()}
			</section>
		);
	};

	return (
		<>
			<PageTitle title={pageTitle} />
			{mainContent()}
		</>
	);
};

export default EquipmentDetails;
