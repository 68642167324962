/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import MobileAppSettings from './MobileAppSettings';
import MobileEditAccount from './MobileEditAccount';
import AccountContext from 'contexts/AccountContext/AccountContext';

import './styles.scss';

const MobileSettings: React.FC = () => {
	return (
		<section className="mobile-settings-main-content">
			<MobileAppSettings />
			<AccountContext>
				<MobileEditAccount />
			</AccountContext>
		</section>
	);
};

export default MobileSettings;
