/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import DefectField from './DefectField';
import StatusField from './StatusFields';
import PeriodFields from './PeriodFields';
import { FilterData } from 'models/FilterData';
import React, { useContext, useState } from 'react';
import CustomButton from 'components/Shared/CustomButton';
import ReportedDefectContext from 'contexts/ReportedDefectContext/ReportedDefectContext';
import { ServiceOrderContextElement } from 'contexts/ServiceOrderContext/ServiceOrderContext';
import { ServiceOrderContextType } from 'contexts/ServiceOrderContext/ServiceOrderContextType';

import './styles.scss';

interface Params {
	onClose: () => void;
}

const ServiceOrderFilter: React.FC<Params> = ({ onClose }) => {
	const { ordersFilter, onFilterChange } = useContext<ServiceOrderContextType>(ServiceOrderContextElement);

	const [defectCode, setDefectCode] = useState(ordersFilter?.defectCode);
	const [initialDate, setInitialDate] = useState(ordersFilter?.initialDate);
	const [finalDate, setFinalDate] = useState(ordersFilter?.finalDate);
	const [statusList, setStatusList] = useState(ordersFilter?.statusList);
	const [hasError, setHasError] = useState<boolean>(false);

	const onDateSelect = (value: Date, type: string) => {
		type === 'initialDate' ? setInitialDate(value) : setFinalDate(value);
	};

	const onStatusSelected = (status: string[]) => {
		setStatusList(status);
	};

	const onDefectSelected = (code: string) => {
		setDefectCode(code);
	};

	const applyFilterChanges = () => {
		const newFilter = {
			defectCode,
			statusList,
			initialDate,
			finalDate,
		} as FilterData;
		onFilterChange(newFilter);
		onClose();
	};

	const clearFilters = () => {
		setStatusList(undefined);
		setDefectCode(undefined);
		setInitialDate(undefined);
		setFinalDate(undefined);
		setHasError(false);
	};

	const onError = (value: any) => {
		if (!value || Date.parse(value)) {
			setHasError(false);
			return;
		}
		setHasError(true);
	};

	const mainContent = () => {
		return (
			<div className="orders-filter-content">
				<div className="clear-filter" onClick={clearFilters}>
					<span>Limpar filtros</span>
				</div>
				<div className="period-filter">
					<div className="period-filter-header">
						<span>Período</span>
					</div>
					<div className="period-filter-fields">
						<PeriodFields {...{ initialDate, finalDate, onDateSelect, onError }} />
					</div>
				</div>
				<div className="status-filter">
					<div className="status-filter-header">
						<span>Status:</span>
					</div>
					<div className="status-filter-fields">
						<StatusField {...{ onStatusSelected, statusList }} />
					</div>
				</div>
				<div className="defect-filter">
					<span>Defeito relatado:</span>
					<ReportedDefectContext>
						<DefectField {...{ onDefectSelected, defectCode }} />
					</ReportedDefectContext>
				</div>
				<div className="orders-filter-actions">
					<CustomButton context="primary" label="FILTRAR" disabled={hasError} onClick={applyFilterChanges} />
					<CustomButton context="secondary" label="CANCELAR" onClick={onClose} />
				</div>
			</div>
		);
	};

	return mainContent();
};

export default ServiceOrderFilter;
