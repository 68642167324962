/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import MoreOptions from 'components/Shared/MoreOptions';
import { MoreOptionsItem } from 'models/MoreOptionsItem';
import ServiceOrder from 'models/ServiceOrder';
import moment from 'moment';
import React from 'react';
import { ServiceOrderStatusLabels } from 'utils/constants';
import './styles.scss';

interface Params {
	serviceOrder: ServiceOrder;
	onItemClick: (serviceOrder: ServiceOrder) => void;
	onCancelServiceOrderClick: (serviceOrder: ServiceOrder) => void;
}

const EquipmentServiceOrdersListItem: React.FC<Params> = ({ serviceOrder, onItemClick, onCancelServiceOrderClick }) => {
	const statusLabel = serviceOrder.closure_status ? ServiceOrderStatusLabels.CLOSED : ServiceOrderStatusLabels.PROGRESS;

	const techName = () => {
		return serviceOrder.techName ? serviceOrder.techName : '--';
	};

	const optionsContent = () => {
		const options: MoreOptionsItem = {
			label: 'Cancelar Solicitação',
			onClick: () => {
				onCancelServiceOrderClick(serviceOrder);
			},
			item: serviceOrder,
		};

		const moreOptions = [options];
		return (
			<div className="options">
				<MoreOptions id="service-order-list-item-options" options={moreOptions} />
			</div>
		);
	};

	return (
		<>
			<div className="list-item">
				<div className="order-info" onClick={() => onItemClick(serviceOrder)}>
					<div className="protocol">
						<span>{serviceOrder.service_order}</span>
					</div>
					<div className="created">
						<span>{moment(serviceOrder.created_at).format('DD/MM/YYYY')}</span>
					</div>
					<div className="tech">
						<span>{techName()}</span>
					</div>
					<div className="status">
						<span>{statusLabel}</span>
					</div>
					{optionsContent()}
				</div>
			</div>
		</>
	);
};

export default EquipmentServiceOrdersListItem;
