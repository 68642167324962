/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

import './styles.scss';

interface Params {
	isFinished: boolean;
	isFirstItem: boolean;
	isLastItem: boolean;
}

const ProgressLine: React.FC<Params> = ({ isFinished, isFirstItem, isLastItem }) => {
	const showStartLine = () => {
		return !isFirstItem ? <div className="progress-line start"></div> : <div className="progress-line start hide"></div>;
	};

	const lastItemContent = () => {
		return (
			<div className="timeline-progress-line-dots">
				{showStartLine()}
				<div className="progress-line-start-dot"></div>
				<div className="progress-line"></div>
				<div className="progress-line-dot"></div>
				<div className="progress-line-dot"></div>
				<div className="progress-line-dot"></div>
			</div>
		);
	};

	const progressLineContent = () => {
		return (
			<div className="timeline-progress-line">
				{showStartLine()}
				<div className="progress-line-start-dot"></div>
				<div className="progress-line"></div>
			</div>
		);
	};

	const progressLineFinishedContent = () => {
		return (
			<div className="timeline-progress-line-finished">
				{showStartLine()}
				<div className="progress-line-start-dot"></div>
			</div>
		);
	};

	const mainContent = () => {
		if (isFinished) {
			return progressLineFinishedContent();
		} else if (isLastItem && !isFinished) {
			return lastItemContent();
		} else {
			return progressLineContent();
		}
	};

	return mainContent();
};

export default ProgressLine;
