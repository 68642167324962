/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import Equipment from 'models/Equipment';
import React, { useContext } from 'react';
import { ViewType } from 'utils/constants';
import EquipmentsListItemMobile from './Mobile';
import './styles.scss';
import EquipmentsListItemWeb from './Web';

interface Params {
	equipment: Equipment;
	onItemClick: (equipment: Equipment) => void;
	onOpenClick: (equipment: Equipment) => void;
}

const EquipmentsListItem: React.FC<Params> = ({ equipment, onItemClick, onOpenClick }) => {
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);

	const mainContent = () => {
		return viewType === ViewType.WEB ? (
			<EquipmentsListItemWeb {...{ equipment, onItemClick, onOpenClick }} />
		) : (
			<EquipmentsListItemMobile {...{ equipment, onItemClick, onOpenClick }} />
		);
	};

	return mainContent();
};

export default EquipmentsListItem;
