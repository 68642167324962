/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ServiceOrderStatus from 'components/ServiceOrder/ServiceOrderStatus';
import MoreOptions from 'components/Shared/MoreOptions';
import ServiceOrder from 'models/ServiceOrder';
import moment from 'moment';
import React from 'react';
import { ServiceOrderStatusLabels, ServiceOrderStatusTypes } from 'utils/constants';

interface Params {
	serviceOrder: ServiceOrder;
	onItemClick: (serviceOrder: ServiceOrder) => void;
	onCancelServiceOrderClick: (serviceOrder: ServiceOrder) => void;
}

const ServiceOrderListItemWeb: React.FC<Params> = ({ serviceOrder, onItemClick, onCancelServiceOrderClick }) => {
	const status = serviceOrder.closure_status ? ServiceOrderStatusTypes.CLOSED : ServiceOrderStatusTypes.PROGRESS;
	const statusLabel = serviceOrder.closure_status ? ServiceOrderStatusLabels.CLOSED : ServiceOrderStatusLabels.PROGRESS;

	const serviceOrderStatus = () => {
		return <ServiceOrderStatus {...{ status }} />;
	};

	const optionsContent = () => {
		const options = { label: 'Cancelar Solicitação', onClick: onCancelServiceOrderClick, item: serviceOrder };

		const moreOptions = [options];
		return status === ServiceOrderStatusTypes.PROGRESS ? (
			<div className="options">
				<MoreOptions id="service-order-list-item-options" options={moreOptions} />
			</div>
		) : (
			<></>
		);
	};

	return (
		<>
			<div className="list-item web">
				<div onClick={() => onItemClick(serviceOrder)} className="order-info">
					<div className="equip">
						{serviceOrderStatus()}
						<span>{serviceOrder.equipmentName}</span>
					</div>
					<div className="protocol">
						<span>{serviceOrder.service_order}</span>
					</div>
					<div className="created">
						<span>{moment(serviceOrder.created_at).format('DD/MM/YYYY')}</span>
					</div>
					<div className="status">
						<span>{statusLabel}</span>
					</div>
					{optionsContent()}
				</div>
			</div>
		</>
	);
};

export default ServiceOrderListItemWeb;
