/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import RouterContext from 'contexts/RouterContext/RouterContext';
import SocketContext from 'contexts/SocketContext/SocketContext';
import VersionContext from 'contexts/VersionContext/VersionContext';
import AppUserReport from 'models/AppUserReport';
import { RequisitionError } from 'models/RequisitionError';
import UpdateInfo from 'models/UpdateInfo';
import UserAppConfigs from 'models/UserAppConfigs';
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import history from 'routes/history';
import { apiPrp, createReport, updateSettings, userInfo } from 'services';
import { store } from 'store';
import { signOutRequest } from 'store/modules/auth/actions';
import { saveClientSelected, updateUserInfo } from 'store/modules/data/actions';
import { applyUserTheme, getDeviceOrientation, getDeviceType, getViewType } from 'utils/Screen';
import { AppThemes, DeviceType, OrientationType, RouteList, ViewType } from 'utils/constants';
import { getValuesFromPath } from 'utils/pipe';
import { DeviceContextType } from './DeviceContextType';

const THEME_TRANSITION_TIME = 2000;

export const DeviceContextElement = createContext<DeviceContextType>({
	viewType: {} as ViewType,
	deviceType: {} as DeviceType,
	mainTheme: {} as AppThemes,
	onUpdateUser: (): void => {},
	onUpdateSettings: (): void => {},
	onCreateReport: (): void => {},
	clearUser: (): void => {},
	onUpdateVersion: (): void => {},
});

function getSelectedTheme() {
	const theme = store.getState().data.user?.appConfigs?.theme || '';
	return applyUserTheme(theme);
}

const DeviceProvider = (props: any) => {
	const [user, setUser] = useState(store.getState().data.user);
	const [viewType, setViewType] = useState<ViewType>(getViewType());
	const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType());
	const [orientationType, setOrientationType] = useState<OrientationType>(getDeviceOrientation());
	const [mainTheme, setMainTheme] = useState<string>(getSelectedTheme());

	const dispatch = useDispatch();

	useEffect(() => {
		updateUser();
		resizeListener();
		clientUserDataListener();
	}, []);

	useEffect(() => {
		setUser(store.getState().data.user);
	}, [store.getState().data.user]);

	const updateUser = (resetData = false, clientId?: number) => {
		const { signed } = store.getState().auth;
		if (signed) {
			const userId = store.getState().auth.id;
			const params = getValuesFromPath();
			const storeId = clientId || params?.storeId;

			userInfo(userId, storeId)
				.then(res => {
					const { userData } = res.data;
					dispatch(updateUserInfo(userData));
					if (resetData || storeId) {
						dispatch(saveClientSelected(userData.client));
					}
					setUser(userData);
					checkThemeChanges();
				})
				.catch(err => {
					clearUser();
					toast.error('Erro ao carregar informações do usuário');
				});
		}
	};

	const auth = store.getState().auth;

	const clearUser = async () => {
		history.push(RouteList.LOGIN);
		apiPrp.headers.Authorization = '';
		dispatch(signOutRequest(auth.id));
		// updateUserToken(null, );
		setUser(undefined);
		localStorage.clear();
		onUpdateUser();
	};

	const resizeListener = () => {
		const browserOrientation = window.screen?.orientation;
		const orientationListener = () => {
			setViewType(getViewType());
			const currentOrientation = getDeviceOrientation();
			if (currentOrientation !== orientationType) {
				window.location.reload();
			}
		};

		if (!browserOrientation) {
			window.addEventListener('resize', orientationListener, false);
		} else {
			browserOrientation.addEventListener('change', orientationListener);
		}
	};

	const checkThemeChanges = () => {
		setMainTheme(getSelectedTheme());
	};

	const clientUserDataListener = () => {
		const unsubscribe = store.subscribe(() => {
			const { signed } = store.getState().auth;
			if (signed) {
				if (!user) {
					updateUser();
				}
			} else {
				setUser(undefined);
			}

			checkThemeChanges();
			unsubscribe();
		});
	};

	const changingEffect = (newTheme: any) => {
		if (user) {
			const theme = user?.appConfigs?.theme || false;
			if (theme !== newTheme) {
				setMainTheme(`${mainTheme} ${AppThemes.TRANSITION}`);
			}
		}
	};

	const onUpdateSettings = (newSettings: UserAppConfigs, callback: (hasError?: RequisitionError) => void) => {
		changingEffect(newSettings.theme);
		setTimeout(() => {
			updateSettings(newSettings)
				.then(() => {
					const userInfo = { ...store.getState().data.user };
					userInfo.appConfigs = newSettings;
					dispatch(updateUserInfo(userInfo));
					checkThemeChanges();
					setUser(userInfo);
					callback();
				})
				.catch(err => {
					const error = err.request.response?.error;
					const reportError = {
						message: error ? error.message : 'Internal Server Error',
						code: error ? error.statusCode : 500,
						error: error ? error.name : 'ServerError',
					};
					callback(reportError);
				});
		}, THEME_TRANSITION_TIME);
	};

	const onCreateReport = (report: AppUserReport, callback: (hasError?: RequisitionError) => void) => {
		createReport(report)
			.then(() => {
				callback();
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				callback(reportError);
			});
	};

	const onUpdateUser = (clientId?: number) => {
		updateUser(true, clientId);
	};

	const onUpdateVersion = (updateInfo: UpdateInfo, callback: (hasError?: RequisitionError) => void) => {};

	const providerValues = () => {
		return {
			mainTheme,
			viewType,
			deviceType,
			onCreateReport,
			onUpdateSettings,
			onUpdateUser,
			onUpdateVersion,
			clearUser,
		};
	};

	return (
		<DeviceContextElement.Provider value={providerValues()}>
			<RouterContext>
				<SocketContext>
					<VersionContext>{props.children}</VersionContext>
				</SocketContext>
			</RouterContext>
		</DeviceContextElement.Provider>
	);
};

export default DeviceProvider;
