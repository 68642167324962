/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import LoadingProgress from 'components/Shared/LoadingProgress';
import ResponseModal from 'components/Shared/ResponseModal';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { OpenServiceOrderContextElement } from 'contexts/OpenServiceOrderContext/OpenServiceOrderContext';
import { OpenServiceOrderContextType } from 'contexts/OpenServiceOrderContext/OpenServiceOrderContextType';
import React, { useContext } from 'react';
import { DeviceType } from 'utils/constants';
import './styles.scss';

interface Params {
	show: boolean;
	serviceOrderCode?: string;
	onClose: () => void;
}

const OpenServiceOrderResponse: React.FC<Params> = ({ show, serviceOrderCode, onClose }) => {
	const { deviceType } = useContext<DeviceContextType>(DeviceContextElement);
	const { isLoading } = useContext<OpenServiceOrderContextType>(OpenServiceOrderContextElement);

	const onSendingServiceOrder = () => {
		return <LoadingProgress show={isLoading} text="Enviando..." />;
	};

	const mobileMessage = () => {
		return (
			<div className="service-order-success-response-modal">
				<span>
					Protocolo <b>{serviceOrderCode}</b>. Em até 24 horas um de nossos atendentes entrará em contato com você.
				</span>
			</div>
		);
	};

	const webMessage = () => {
		return (
			<div className="service-order-success-response-modal">
				<span>
					<b>Protocolo {serviceOrderCode}</b>
					Em até 24 horas um de nossos atendentes entrará em contato com você.
				</span>
			</div>
		);
	};

	const onSuccessContent = () => {
		return deviceType !== DeviceType.MOBILE ? webMessage() : mobileMessage();
	};

	const responseModalChildren = () => {
		if (isLoading) {
			return onSendingServiceOrder();
		} else {
			return onSuccessContent();
		}
	};

	const responseModalTitle = () => {
		if (isLoading) {
			return 'Solicitando atendimento';
		} else {
			return 'Solicitação de atendimento realizada!';
		}
	};

	const responseModalSecClass = () => {
		if (isLoading) {
			return 'loading-message';
		} else {
			return 'success-message';
		}
	};

	const onCloseModals = () => {
		if (!isLoading) {
			onClose();
		}
	};

	const responseModalContent = () => {
		const title = responseModalTitle();
		const children = responseModalChildren();
		const secondaryClass = responseModalSecClass();

		return <ResponseModal {...{ children, title, secondaryClass, onClose: onCloseModals, hideAction: isLoading, hideTitle: isLoading }} />;
	};

	return show ? responseModalContent() : <></>;
};

export default OpenServiceOrderResponse;
