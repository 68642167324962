/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import logoPratica from 'assets/image/logo-pratica.webp';
import React from 'react';
import history from 'routes/history';
import { RouteList } from 'utils/constants';
import VersionArea from '../Version';
import './styles.scss';

const SideBar: React.FC = ({ children }) => {
	return (
		<section className="side-bar-content">
			<div className="logo-pratica">
				<img
					src={logoPratica}
					onClick={() => {
						history.push(RouteList.LOGIN);
					}}
				/>
			</div>
			{children}
			<VersionArea />
		</section>
	);
};

export default SideBar;
