/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import ProgressIcon from './ProgressIcon';
import CheckIcon from '@material-ui/icons/Check';
import { ServiceOrderStatusTypes } from 'utils/constants';

import './styles.scss';

interface Params {
	status: ServiceOrderStatusTypes;
}

const ServiceOrderStatus: React.FC<Params> = ({ status }) => {
	const statusIcon = () => {
		return status === ServiceOrderStatusTypes.CLOSED ? <CheckIcon /> : <ProgressIcon />;
	};

	const mainContent = () => {
		return <div className={`status-circle ${status}`}>{statusIcon()}</div>;
	};

	return mainContent();
};

export default ServiceOrderStatus;
