/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { store } from 'store';
import StoreSettings from 'models/StoreSettings';
import { RequisitionError } from 'models/RequisitionError';
import { StoreSettingsContextType } from './StoreSettingsContextType';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { createUserClient, getUserStores, removeUserClient } from 'services';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateStoresFilters } from 'store/modules/data/actions';

const INITIAL_PAGE = 1;
const DEFAULT_LIMIT = 10;

export const StoreSettingsContextElement = createContext<StoreSettingsContextType>({
	storeList: [],
	storeSelectedList: [],
	showAll: {} as boolean,
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	pageLimit: {} as number,
	totalPages: {} as number,
	currentPage: {} as number,
	onViewChange: () => {},
	onPageChange: () => {},
	onLimitChange: () => {},
	onSearchStores: () => {},
	onSelectStores: () => {},
});

interface ListParams {
	pageLimit: number;
	currentPage: number;
	totalPages: number;
	searchValue?: string;
}

function defaultListParams(clearAll = true): ListParams {
	if (!clearAll) {
		const listStoresParams = store.getState().data.listStoresParams;
		return {
			pageLimit: listStoresParams?.pageLimit || DEFAULT_LIMIT,
			currentPage: listStoresParams?.currentPage || INITIAL_PAGE,
			totalPages: listStoresParams?.totalPages || INITIAL_PAGE,
		};
	} else {
		return {
			pageLimit: DEFAULT_LIMIT,
			currentPage: INITIAL_PAGE,
			totalPages: INITIAL_PAGE,
		};
	}
}

const StoreSettingsProvider = (props: any) => {
	const { onUpdateUser } = useContext<DeviceContextType>(DeviceContextElement);

	const [storeList, setStoresList] = useState<StoreSettings[]>([]);
	const [storeSelectedList, setStoresSelectedList] = useState<number[]>([]);
	const [hasError, setHasError] = useState<RequisitionError>();
	const [showAll, setShowAll] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isUpating, setUpdating] = useState<boolean>(false);
	const listParams = useRef<ListParams>(defaultListParams(false));

	const dispatch = useDispatch();

	useEffect(() => {
		clearListParams(true);
		requestStores();
	}, [showAll]);

	const requestStores = () => {
		setLoading(true);
		const user = store.getState().data.user;
		const clientId = user.appConfigs.clientId;
		const { currentPage, pageLimit, searchValue } = { ...listParams.current };
		const offset = (currentPage - 1) * pageLimit;
		const getAll = showAll ? 'all' : '';
		getUserStores(clientId, pageLimit, offset, searchValue, getAll, user.id)
			.then(res => {
				const { count, stores, userStores } = res.data.response;
				updateListParams(Math.ceil(count / pageLimit), 'count');
				setStoresList(stores || []);
				setStoresSelectedList(userStores || []);
				setLoading(false);
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				clearListParams(true);
				setHasError(reportError);
				setLoading(false);
			});
	};

	const onViewChange = () => {
		setShowAll(!showAll);
	};

	const onSelectStores = (storeSelected: StoreSettings, callback: (hasError?: RequisitionError) => void) => {
		if (!isLoading) {
			const userId = store.getState().data.user.id;
			const currentIndex = storeSelectedList.indexOf(storeSelected.clientId);
			const newChecked = [...storeSelectedList];
			let isCreating = false;

			if (currentIndex === -1) {
				newChecked.push(storeSelected.clientId);
				isCreating = true;
			} else {
				newChecked.splice(currentIndex, 1);
			}

			if (newChecked.length === 0 && storeSelectedList.length === 1) {
				callback();
				return;
			}

			setStoresSelectedList(newChecked);

			const request = isCreating ? createUserClient({ userId, clientId: storeSelected.clientId, perfil: '' }) : removeUserClient(userId, storeSelected.clientId);

			setLoading(true);
			setUpdating(true);
			request
				.then(() => {
					requestStores();
					onUpdateUser();
					callback();
				})
				.catch(err => {
					const error = err.request.response?.error;
					const reportError = {
						message: error ? error.message : 'Internal Server Error',
						code: error ? error.statusCode : 500,
						error: error ? error.name : 'ServerError',
					};
					requestStores();
					onUpdateUser();
					callback(reportError);
				});
		}
	};

	const onSearchStores = (searchValue: string) => {
		updateListParams(searchValue, 'search');
		requestStores();
	};

	const onPageChange = (page: number) => {
		updateListParams(page, 'page');
		requestStores();
	};

	const onLimitChange = (newLimit: number) => {
		updateListParams(newLimit, 'limit');
		clearListParams();
		requestStores();
	};

	const clearListParams = (clearAll = false) => {
		if (clearAll) {
			listParams.current = defaultListParams();
		} else {
			const newListParams = { ...listParams.current };
			newListParams.currentPage = INITIAL_PAGE;
			newListParams.totalPages = INITIAL_PAGE;
			listParams.current = newListParams;
		}

		dispatch(updateStoresFilters(listParams.current));
	};

	const updateListParams = (value: number | string, field: 'limit' | 'page' | 'search' | 'count') => {
		const newListParams = { ...listParams.current };

		switch (field) {
			case 'limit':
				newListParams.pageLimit = value as number;
				break;
			case 'page':
				newListParams.currentPage = value as number;
				break;
			case 'count':
				newListParams.totalPages = value as number;
				break;
			case 'search':
				newListParams.searchValue = value as string;
				break;
		}

		listParams.current = newListParams;
		dispatch(updateStoresFilters(newListParams));
	};

	const providerValues = () => {
		return {
			storeList,
			showAll,
			hasError,
			...listParams.current,
			isLoading,
			storeSelectedList,
			onSelectStores,
			onSearchStores,
			onPageChange,
			onLimitChange,
			onViewChange,
		};
	};

	return <StoreSettingsContextElement.Provider value={providerValues()}>{props.children}</StoreSettingsContextElement.Provider>;
};

export default StoreSettingsProvider;
