/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import AddIcon from '@material-ui/icons/Add';
import EquipmentTransferModal from 'components/Equipments/EquipmentsTransferModal';
import TransfersContext from 'contexts/TransfersContext/TransfersContext';
import React, { useState } from 'react';
import Modal from '../Modal';
import image from './images/forno.png';
import './styles.scss';

const EmptyUser: React.FC = () => {
	const [showAddEquipModal, setShowAddEquipModal] = useState<boolean>(false);

	const onOpenAddEquipmentClick = () => {
		setShowAddEquipModal(true);
	};

	const onOpenAddEquipmentClose = () => {
		setShowAddEquipModal(false);
	};

	const addEquipmentModalContent = () => {
		const title = 'ADICIONAR EQUIPAMENTO';
		const onClose = onOpenAddEquipmentClose;

		return (
			<TransfersContext>
				<Modal show={showAddEquipModal} title={title} defaultTitle={title} onClose={onClose}>
					<EquipmentTransferModal onClose={onClose} />
				</Modal>
			</TransfersContext>
		);
	};

	return (
		<>
			<div className="empty-user-main-content">
				<div className="empty-user-main">
					<img src={image} />
					<div className="empty-user-message">
						<h4>VOCÊ AINDA NÃO POSSUI EQUIPAMENTOS VINCULADOS!</h4>
						<p>Para ter acesso a todas as funcionalidades do App Cliente, é necessário adicionar um equipamento. Clique no botão abaixo e siga as próximas etapas.</p>
						<div className="empty-user-actions" onClick={onOpenAddEquipmentClick}>
							<div>
								<AddIcon />
							</div>
							<span>Novo Equipamento</span>
						</div>
					</div>
				</div>
			</div>
			{addEquipmentModalContent()}
		</>
	);
};

export default EmptyUser;
