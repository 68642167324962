/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React from 'react';
import { RouteList } from 'utils/constants';
import history from '../../../routes/history';
import './styles.scss';

interface Params {
	action: () => void;
}

const MobileNavigator: React.FC<Params> = ({ action }) => {
	const onGoBackAction = () => {
		if (action) {
			action();
		} else {
			switch (history.length) {
				case 1:
					history.push(RouteList.HOME);
					break;
				case 2:
					history.push(RouteList.EQUIPMENTS);
					break;

				default:
					history.goBack();
					break;
			}
		}
	};

	return (
		<div
			className="mobile-navigator-component"
			onClick={() => {
				onGoBackAction();
			}}
		>
			<ArrowBackIosIcon />
		</div>
	);
};

export default MobileNavigator;
