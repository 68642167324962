/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import { signOutRequest } from 'store/modules/auth/actions';
import { RouteList } from 'utils/constants';
import history from '../../../../routes/history';
import iconHelp from './../../images/help-24px.png';
import logoPratica from './../../images/logo-pratica-branco.png';
import iconLogout from './../../images/logout-24px.png';
import iconTools from './../../images/tools-24px.png';

import { store } from 'store';
import './styles.scss';

interface Props {
	onHelpClick: () => void;
	onSettingsClick: () => void;
}

const MainOptions: React.FC<Props> = ({ onSettingsClick, onHelpClick }) => {
	const dispatch = useDispatch();

	const auth = store.getState().auth;

	const handleLogout = () => {
		dispatch(signOutRequest(auth.id));
		history.push(RouteList.LOGIN);
	};

	return (
		<section className="main-mobile-menu-options transitions">
			<div className="mobile-menu-logo">
				<img src={logoPratica} alt="Prática Klimaquip" />
			</div>
			<div className="menu-option-list">
				<div className="menu-option-item" onClick={onSettingsClick}>
					<img src={iconTools} alt="config-icon" />
					<span>Configurações</span>
				</div>
				<div className="menu-option-item" onClick={onHelpClick}>
					<img src={iconHelp} alt="help-icon" />
					<span>Ajuda</span>
				</div>
				<div className="menu-option-item logout" onClick={handleLogout}>
					<img src={iconLogout} alt="notify-icon" />
					<span>Sair</span>
				</div>
			</div>
		</section>
	);
};

export default MainOptions;
