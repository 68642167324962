/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import OpenServiceOrderResponse from 'components/OpenServiceOrder/OpenServiceOrderResponse';
import Modal from 'components/Shared/Modal';
import { OpenServiceOrderContextElement } from 'contexts/OpenServiceOrderContext/OpenServiceOrderContext';
import { OpenServiceOrderContextType } from 'contexts/OpenServiceOrderContext/OpenServiceOrderContextType';
import { ServiceOrderFeedbackContextElement } from 'contexts/ServiceOrderFeedbackContext/ServiceOrderFeedbackContext';
import { ServiceOrderFeedbackContextType } from 'contexts/ServiceOrderFeedbackContext/ServiceOrderFeedbackContextType';
import FileProperties from 'models/FileProperties';
import ServiceOrder, { ServiceOrderInput } from 'models/ServiceOrder';
import { ServiceOrderFeedbackAnswers } from 'models/ServiceOrderFeedback';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import history from 'routes/history';
import { store } from 'store';
import { RATING_BY_PUSH, SERVICE_ORDER_ORIGIN, YES_OPTION } from 'utils/constants';
import ServiceOrderFeedbackForm from './ServiceOrderFeedbackForm';
import './styles.scss';

interface Params {
	serviceOrder: ServiceOrder;
}

const ServiceOrderFeedback: React.FC<Params> = ({ serviceOrder }) => {
	const { serviceOrderFeedback, onServiceOrderChange, createServiceOrderFeedback } = useContext<ServiceOrderFeedbackContextType>(ServiceOrderFeedbackContextElement);

	const { createServiceOrder } = useContext<OpenServiceOrderContextType>(OpenServiceOrderContextElement);

	const [showModal, setShowModal] = useState<boolean>(false);
	const [hasCompleted, sethasCompleted] = useState<boolean>(true);
	const [serviceOrderCode, setServiceOrderCode] = useState<string>();
	const [showServiceOrderResponse, setShowServiceOrderResponse] = useState<boolean>(false);

	useEffect(() => {
		onServiceOrderChange(serviceOrder.id);
		if (!serviceOrder.appOsFeedback) {
			sethasCompleted(false);
		}
		if (history?.location.search === RATING_BY_PUSH) {
			setShowModal(true);
		}
	}, []);

	const onConfirm = (data: any) => {
		const userId = store.getState().data.user.id;
		const serviceOrderId = serviceOrder.id;
		const callback = (res: ServiceOrderFeedbackAnswers) => (res ? onSuccess(res) : toast.error('Erro ao enviar pesquisa!'));

		createServiceOrderFeedback({ ...data, userId, serviceOrderId }, callback);
	};

	const onSuccess = (feedback: ServiceOrderFeedbackAnswers) => {
		if (feedback.serviceOrderReopen === YES_OPTION) {
			reopenServiceOrder();
		} else {
			toast.success('Pesquisa enviada com sucesso!');
			sethasCompleted(true);
		}
	};

	const reopenServiceOrder = () => {
		setServiceOrderCode('');
		setShowServiceOrderResponse(true);

		const equipment = serviceOrder.equipments;
		const files: FileProperties[] = [];
		const message = 'Atendimento reaberto por solicitação do cliente, problema não resolvido.';
		const newServiceOrder = {
			created_by: serviceOrder.created_by,
			origin: SERVICE_ORDER_ORIGIN,
			client_code: serviceOrder.client_code,
			service_order: '',
			contato: serviceOrder.contato,
			phone: serviceOrder.phone,
			email: serviceOrder.email,
			equipment_id: serviceOrder.equipment_id,
			type: serviceOrder.type,
			obs: message,
			complement: serviceOrder.complement,
			address: serviceOrder.address,
			neighborhood: serviceOrder.neighborhood,
			city: serviceOrder.city,
			state_sigla: serviceOrder.state_sigla,
			zip_code: serviceOrder.zip_code,
			closure_status: null,
		} as ServiceOrderInput;

		createServiceOrder(equipment, newServiceOrder, message, files, onCreateServiceOrder);
	};

	const onCreateServiceOrder = (res: any, err?: any) => {
		if (!err && res) {
			setServiceOrderCode(res);
		} else {
			sethasCompleted(false);
			setShowServiceOrderResponse(false);
			toast.error('Erro ao solicitar atendimento');
		}
	};

	const closeServiceOrderResponse = () => {
		setShowServiceOrderResponse(false);
		sethasCompleted(true);
		onClose();
	};

	const onClose = () => setShowModal(false);

	const openServiceOrderModalContent = () => (
		<Modal show={showModal} title="Pesquisa de Satisfação" defaultTitle="Pesquisa de Satisfação" onClose={onClose}>
			<ServiceOrderFeedbackForm {...{ serviceOrder, serviceOrderFeedback, onClose, onConfirm }} />
		</Modal>
	);

	const mainContent = () => (
		<div className="service-order-feedback-content">
			<span
				className="service-order-feedback-click-item"
				onClick={() => {
					setShowModal(true);
				}}
			>
				Clique aqui para avaliar seu atendimento
			</span>
			{openServiceOrderModalContent()}
			<OpenServiceOrderResponse {...{ serviceOrderCode, onClose: closeServiceOrderResponse, show: showServiceOrderResponse }} />
		</div>
	);

	return hasCompleted ? <></> : mainContent();
};

export default ServiceOrderFeedback;
