/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import ServiceOrder from 'models/ServiceOrder';
import React, { useContext } from 'react';
import { ViewType } from 'utils/constants';
import ServiceOrderListItemMobile from './Mobile';
import './styles.scss';
import ServiceOrderListItemWeb from './Web';

interface Params {
	serviceOrder: ServiceOrder;
	onItemClick: (serviceOrder: ServiceOrder) => void;
	onCancelServiceOrderClick: (serviceOrder: ServiceOrder) => void;
}

const ServiceOrderListItem: React.FC<Params> = ({ serviceOrder, onItemClick, onCancelServiceOrderClick }) => {
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);

	const mainContent = () => {
		return viewType === ViewType.WEB ? (
			<ServiceOrderListItemWeb {...{ serviceOrder, onItemClick, onCancelServiceOrderClick }} />
		) : (
			<ServiceOrderListItemMobile {...{ serviceOrder, onItemClick, onCancelServiceOrderClick }} />
		);
	};

	return mainContent();
};

export default ServiceOrderListItem;
