/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import ResponseModal from 'components/Shared/ResponseModal';
import LoadingProgress from 'components/Shared/LoadingProgress';

import './styles.scss';
interface Params {
	type: 'pass' | 'data';
	show: boolean;
	isLoading: boolean;
	onClose?: () => void;
}

const EditAccountResponse: React.FC<Params> = ({ type, show, isLoading, onClose }) => {
	const onLoadingContent = () => {
		const text = type === 'pass' ? 'Enviando e-mail de alteração...' : 'Salvando informações...';
		return <LoadingProgress show={isLoading} text={text} />;
	};

	const onSuccessContent = () => {
		const message = type === 'pass' ? 'Um link de recuperação de senha foi enviado para o seu endereço de e-mail.' : 'Informações de conta atualizadas!';
		return (
			<div className="edit-account-response-modal">
				<span>{message}</span>
			</div>
		);
	};

	const responseModalChildren = () => {
		return isLoading ? onLoadingContent() : onSuccessContent();
	};

	const responseModalTitle = () => {
		return isLoading ? 'Editar Conta' : 'Alterar minha senha';
	};

	const responseModalSecClass = () => {
		return isLoading ? 'loading-message' : 'success-message';
	};

	const onCloseModals = () => {
		if (!isLoading && onClose) {
			onClose();
		}
	};

	const responseModalContent = () => {
		const title = responseModalTitle();
		const children = responseModalChildren();
		const secondaryClass = responseModalSecClass();

		return <ResponseModal {...{ children, title, secondaryClass, onClose: onCloseModals, hideAction: isLoading, hideTitle: isLoading }} />;
	};

	return show ? responseModalContent() : <></>;
};

export default EditAccountResponse;
