/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import logoPratica from 'assets/image/logo-pratica.webp';
import React from 'react';
import { RouteList } from 'utils/constants';
import history from '../../../routes/history';

interface Params {
	isRootPath: boolean;
}

const WebNavigator: React.FC<Params> = ({ isRootPath }) => {
	const onGoBackAction = () => {
		switch (history.length) {
			case 1:
				history.push(RouteList.HOME);
				break;
			case 2:
				history.push(RouteList.EQUIPMENTS);
				break;

			default:
				history.goBack();
				break;
		}
	};

	const logoContent = () => (
		<div
			className="logo-pratica"
			onClick={() => {
				history.push(RouteList.EQUIPMENTS);
			}}
		>
			<img src={logoPratica} alt="Prática Klimaquip" style={{ width: '150px' }} />
		</div>
	);

	const navContent = () => {
		return (
			<div
				className="web-navigator-component"
				onClick={() => {
					onGoBackAction();
				}}
			>
				<ArrowBackIosIcon />
				<span>Voltar</span>
			</div>
		);
	};

	return isRootPath ? logoContent() : navContent();
};

export default WebNavigator;
