/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import MobileHeader from 'components/Shared/MobileHeader';
import React from 'react';

interface Params {
	show: boolean;
	title: string;
	subTitle?: string;
	defaultTitle?: string;
	onClose: () => void;
}

const ModalMobile: React.FC<Params> = ({ show, title, subTitle, defaultTitle, children, onClose }) => {
	const mainContent = () => {
		return (
			<div className="modal-main-backdrop">
				<div className="modal-mobile-component">
					<div className="modal-mobile-header-content">
						<MobileHeader {...{ title, subTitle, defaultTitle, action: onClose }} />
					</div>
					<div className="modal-mobile-main-content">{children}</div>
				</div>
			</div>
		);
	};

	return show ? mainContent() : <></>;
};

export default ModalMobile;
