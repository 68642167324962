import axios, { AxiosRequestConfig } from 'axios';

const socketAxiosConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_API_SOCKET,
	headers: { 'X-Custom-Header': 'foobar' },
	validateStatus: status => {
		return status >= 200 && status < 400;
	},
	transformResponse: [
		function(data) {
			if (data) {
				return JSON.parse(data);
			}
			return data;
		},
	],
};

export const apiSocket = axios.create(socketAxiosConfig);
