/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EmailIcon from '@material-ui/icons/Email';
import CustomButton from 'components/Shared/CustomButton';
import Dropdown from 'components/Shared/Dropdown';
import LoadingModal from 'components/Shared/LoadingModal';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import AppUserReport from 'models/AppUserReport';
import { DropdownItem } from 'models/DropdownItem';
import { RequisitionError } from 'models/RequisitionError';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { store } from 'store';
import ModalTutorial from '../../../../components/Shared/Tutorial/ModalTutorial';
import './styles.scss';

interface Params {
	onClose: () => void;
}

interface HelpInput {
	reportTypeId: number;
	message: string;
}

const helpReportList = [
	{ value: 1, label: 'Excluir minha conta e meus dados pessoais' },
	{ value: 2, label: 'Falha na abertura de chamado' },
	{ value: 3, label: 'Equipamentos que não me pertencem' },
	{ value: 4, label: 'Faltam equipamentos na minha lista' },
	{ value: 5, label: 'Dados incorretos' },
	{ value: 6, label: 'Outros assuntos' },
] as DropdownItem[];

const WebHelpUser: React.FC<Params> = ({ onClose }) => {
	const { onCreateReport } = useContext<DeviceContextType>(DeviceContextElement);

	const { register, handleSubmit } = useForm<HelpInput>();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [reportItem, setReportItem] = useState<number | string>(0);
	const [reportItemValidator, setReportItemValidator] = useState<boolean>(false);
	const [showTutorial, setShowTutorial] = useState<boolean>(false);
	const [text, setText] = useState<string>('');
	const [msg, setMsg] = useState<number>(500);

	const onSubmit = (data: HelpInput) => {
		if (!reportItem) {
			setReportItemValidator(true);
		} else {
			setIsLoading(true);
			data.reportTypeId = Number(reportItem);
			const user = store.getState().data.user;
			const appReport = { userId: user.id, ...data } as AppUserReport;
			onCreateReport(appReport, onCompleted);
		}
	};

	const onCompleted = (hasError?: RequisitionError) => {
		setIsLoading(false);
		if (hasError) {
			setReportItemValidator(false);
			setReportItem(0);
			toast.error('Erro ao relatar problema!');
		} else {
			toast.success('Problema relatado com sucesso!');
			onClose();
		}
	};

	const reportItemValidatorContent = () => {
		return reportItemValidator ? (
			<div className="form-item-content-validator">
				<span>* Campo obrigatório</span>
			</div>
		) : (
			<></>
		);
	};

	const onSelectedItem = (optionSelected: DropdownItem) => {
		setReportItem(optionSelected.value);
		setReportItemValidator(false);
	};

	const onShowTutorialClick = () => {
		setShowTutorial(true);
	};

	const onCloseTutorialClick = () => {
		setShowTutorial(false);
	};

	const tutorialContent = () => {
		return showTutorial ? (
			<div className="tutorial">
				<ModalTutorial show={true} onClose={onCloseTutorialClick} />
			</div>
		) : (
			<></>
		);
	};

	const countMsg = (e: any) => {
		if (text.length < 500) {
			setText(e.target.value.substring(0, 500));
			if (e.nativeEvent.inputType == 'insertText' || e.nativeEvent.inputType == 'insertFromPaste') {
				setMsg(500 - (text.length + 1));
			} else {
				setMsg(500 - (text.length - 1));
			}
		} else {
			if (e.nativeEvent.inputType == 'insertText' || e.nativeEvent.inputType == 'insertFromPaste') {
				setMsg(0);
			} else {
				setText(e.target.value.substring(0, 500));
				setMsg(500 - (text.length + 1));
			}
		}
	};

	const formContent = () => {
		return (
			<section className="web-help-main-content">
				<div className="help-contact-area">
					<div className="help-contact-area-title">
						<h5>Central de atendimentos Prática</h5>
					</div>
					<div className="help-contact-area-content">
						<div className="email-content">
							<span>ajuda@praticabr.com</span>
							<a href="mailto:ajuda@praticabr.com" className="email-button">
								<EmailIcon />
								<span>E-mail</span>
							</a>
						</div>
						<div className="phone-content">
							<span>(35) 3449.1200</span>
						</div>
						<div className="tutorial-content">
							<span>Tutorial</span>
							<a onClick={onShowTutorialClick} className="tutorial-button">
								<ArrowRightIcon />
								<span>Rever</span>
							</a>
						</div>
					</div>

					<div className="help-contact-area-action">
						<a href="https://ajuda.praticabr.com/hc/pt-br" target="_blank" rel="noreferrer">
							<span>Central de ajuda</span>
						</a>
					</div>
				</div>
				<div className="help-form-area">
					<form onSubmit={handleSubmit(onSubmit)} className="main-form-content">
						<h5>Relate um problema:</h5>
						<div className="form-item-content">
							<Dropdown options={helpReportList} onChange={onSelectedItem} selectedOption={reportItem} placeholder="Selecione" />
							{reportItemValidatorContent()}
						</div>
						<div className="form-item-content">
							<textarea
								{...register('message', { maxLength: 500 })}
								placeholder="Digite aqui..."
								onChange={e => {
									countMsg(e);
								}}
								value={text}
							/>
							<span className="characters-remaining">{msg} caracteres restantes</span>
						</div>
						<div className="help-form-action">
							<CustomButton context="primary" label="ENVIAR" type="submit" />
						</div>
					</form>
				</div>
				<LoadingModal show={isLoading} title="Relatando problema" text="Enviando..." />
			</section>
		);
	};

	return (
		<>
			{formContent()}
			{tutorialContent()}
		</>
	);
};

export default WebHelpUser;
