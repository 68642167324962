/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */
import React from 'react';

const CreatedMessage: React.FC = () => {
	return (
		<div className="default-description">
			<div>
				<span>
					<b>Requisição de atendimento técnico recebida!</b>
				</span>
			</div>
			<span>Em breve um dos nossos atendentes entrará em contato para agendar o atendimento.</span>
		</div>
	);
};

export default CreatedMessage;
