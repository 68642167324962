/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import _ from 'lodash';
import Client from 'models/Client';
import Equipment from 'models/Equipment';
import Files from 'models/OsFiles';
import RequestResponse from 'models/RequestResponse';
import { RequestServiceOrderCancel } from 'models/RequestServiceOrderCancel';
import { RequisitionError } from 'models/RequisitionError';
import ServiceOrder from 'models/ServiceOrder';
import { ServiceOrderDetail, VisitInfo } from 'models/ServiceOrderDetail';
import TechnicalAssists from 'models/TechnicalAssists';
import React, { createContext, useEffect, useState } from 'react';
import { getServicesOrdersByCode, registerCancelRequest } from 'services';
import { StepTypes } from 'utils/constants';
import { parseFileNameByStepType } from 'utils/pipe';
import { ServiceOrderDetailsContextType } from './ServiceOrderDetailsContextType';

export const ServiceOrderDetailsContextElement = createContext<ServiceOrderDetailsContextType>({
	serviceOrderDetail: {} as ServiceOrderDetail,
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	serviceOrderCode: {} as string,
	onCancelServiceOrderRequest: () => {},
	onServiceOrderChange: () => {},
});

const ServiceOrdersDetailsProvider = (props: any) => {
	const [serviceOrderDetail, setServiceOrderDetail] = useState<ServiceOrderDetail>();
	const [serviceOrderCode, setServiceOrderCode] = useState<string>();
	const [serialNumber, setSerialNumber] = useState<string>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<RequisitionError>();

	useEffect(() => {
		requestServiceOrder();
	}, [serviceOrderCode]);

	const requestServiceOrder = () => {
		if (serviceOrderCode) {
			setLoading(true);

			getServicesOrdersByCode(serviceOrderCode)
				.then(res => {
					const { serviceOrder, client } = res.data;
					buildServiceOrderDetailInfo(serviceOrder, client);
					setLoading(false);
				})
				.catch(err => {
					const error = err.request.response?.error;
					const reportError = {
						message: error ? error.message : 'Internal Server Error',
						code: error ? error.statusCode : 500,
						error: error ? error.name : 'ServerError',
					};
					setHasError(reportError);
					setLoading(false);
				});
		}
	};

	const buildServiceOrderDetailInfo = (serviceOrder: ServiceOrder, client: Client) => {
		const equipment = serviceOrder.equipments as Equipment;
		const history = getServiceOrderHistory(serviceOrder.technicalAssistances);
		const visitInfo = getVisitInfo(history);
		const technician = getLastTech(history);
		const files = getFiles(history);

		if (serialNumber && serialNumber !== equipment.serial_number) {
			serviceOrderInvalid();
		} else {
			setSerialNumber(serialNumber);
			setServiceOrderDetail({ serviceOrder, client, history, files, equipment, technician, visitInfo });
		}
	};

	const serviceOrderInvalid = () => {
		const reportError = {
			message: 'Erro ao carregar ordem de serviço',
			error: 'Erro ao carregar ordem de serviço',
		};
		setHasError(reportError);
		setServiceOrderDetail(undefined);
	};

	const getServiceOrderHistory = (history: TechnicalAssists[]) => {
		return history.filter((h: TechnicalAssists) => isValidType(h.step_type));
	};

	const isValidType = (type?: string) => {
		return (
			type &&
			type !== StepTypes.INVENTORY_ORDER &&
			type !== StepTypes.INVENTORY_INVOICE &&
			type !== StepTypes.INTERNAL_OBS &&
			type !== StepTypes.AUTH &&
			type !== StepTypes.INVOICE
		);
	};

	const getVisitInfo = (history: TechnicalAssists[]) => {
		const historyWithVisit = history.filter((h: TechnicalAssists) => h.step_type === StepTypes.NEW_VISIT);
		const lastVisit = _.maxBy(historyWithVisit, 'created_at');
		if (lastVisit?.appointment_date) {
			return { date: new Date(lastVisit.appointment_date) } as VisitInfo;
		}
	};

	const getLastTech = (history: TechnicalAssists[]) => {
		const historyWithTech = history.filter((h: TechnicalAssists) => h.technician);
		const getLastTech = _.maxBy(historyWithTech, 'created_at');
		return getLastTech?.technician;
	};

	const getFiles = (history: TechnicalAssists[]): Files[] => {
		const files = [] as Files[];
		if (history?.length) {
			const historyWithFiles = history.filter((t: TechnicalAssists) => t.tech_assistance_files.length || t.files.length);
			if (historyWithFiles.length) {
				historyWithFiles.forEach((t: TechnicalAssists) => {
					files.push(...getFilesInfo(t));
				});
			}
		}

		return files;
	};

	const getFilesInfo = (history: TechnicalAssists): Files[] => {
		const techAssistanceFiles = history.tech_assistance_files.map((file: Files) => {
			file.created_date = history.created_at;
			file.tech_assist_type = history.step_type;
			file.name = parseFileNameByStepType(file, history.step_type as StepTypes);
			return file;
		});

		const files = history.files.map((file: Files) => {
			file.created_date = history.created_at;
			file.tech_assist_type = history.step_type;
			file.name = parseFileNameByStepType(file, history.step_type as StepTypes);
			return file;
		});

		const listFiles = techAssistanceFiles.concat(files);

		return listFiles;
	};

	const onServiceOrderChange = (code: string) => {
		setServiceOrderCode(code);
	};

	const onCancelServiceOrderRequest = (requestServiceOrderCancel: RequestServiceOrderCancel, callback: (response: RequestResponse) => void) => {
		registerCancelRequest(requestServiceOrderCancel)
			.then(res => {
				const response = res.data.assistanceSaved;
				callback({ response });
				setLoading(false);
			})
			.catch(err => {
				const { error } = err.request?.response;
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request,
				};
				callback({ reportError });
			});
	};

	const providerValues = () => {
		return {
			serviceOrderDetail,
			serviceOrderCode,
			isLoading,
			hasError,
			onServiceOrderChange,
			onCancelServiceOrderRequest,
		};
	};

	return <ServiceOrderDetailsContextElement.Provider value={providerValues()}>{props.children}</ServiceOrderDetailsContextElement.Provider>;
};

export default ServiceOrdersDetailsProvider;
