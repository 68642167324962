/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import PageListOptions from 'components/Shared/PageListOptions';
import { StoreSettingsContextElement } from 'contexts/StoreSettingsContext/StoreSettingsContext';
import { StoreSettingsContextType } from 'contexts/StoreSettingsContext/StoreSettingsContextType';
import { PaginatorOptions, SearchOptions } from 'models/PageListOptions';
import StoreSettings from 'models/StoreSettings';
import React, { useContext } from 'react';
import StoresSettingsListItem from './StoresSettingsListItem';
import './styles.scss';

const SCROLL_TOP = 0;

const MobileStoresSettingsList: React.FC = () => {
	const { storeList, showAll, pageLimit, onLimitChange, onViewChange, onSearchStores } = useContext<StoreSettingsContextType>(StoreSettingsContextElement);

	$('.list-view').scrollTop(SCROLL_TOP);

	const listContent = () => {
		return (
			<>
				{storeList.map((storeSettings: StoreSettings, index: number) => {
					return <StoresSettingsListItem {...{ storeSettings }} key={index} />;
				})}
			</>
		);
	};

	const storesTypeContent = () => {
		return (
			<div className="stores-settings-mobile-list-options">
				<div className="stores-view-type">
					<FormControl component="fieldset">
						<RadioGroup aria-label="address-selector" name="address-selector" value={showAll} onChange={onViewChange}>
							<FormControlLabel value={false} control={<Radio />} label="Minhas Lojas" />
							<FormControlLabel value={true} control={<Radio />} label="Todas" />
						</RadioGroup>
					</FormControl>
				</div>
			</div>
		);
	};

	const listOptionsContent = () => {
		const paginatorOptions: PaginatorOptions = {
			limit: pageLimit,
			onChangeLimit: onLimitChange,
		};

		const searchOptions: SearchOptions = {
			onChangeValues: onSearchStores,
			placeHolder: 'Procurar loja',
		};

		return (
			<div className="stores-settings-mobile-search-content">
				<PageListOptions {...{ paginatorOptions, searchOptions }} />
			</div>
		);
	};

	return (
		<section className="stores-settings-mobile-list-content">
			{listOptionsContent()}
			<div className="stores-settings-mobile-list-items">
				{storesTypeContent()}
				{listContent()}
			</div>
		</section>
	);
};

export default MobileStoresSettingsList;
