/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import Client from 'models/Client';
import Equipment from 'models/Equipment';
import { FilterData } from 'models/FilterData';
import { RequisitionError } from 'models/RequisitionError';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getEquipments } from 'services';
import { store } from 'store';
import { updateEquipsFilters } from 'store/modules/data/actions';
import { EquipmentsContextType } from './EquipmentsContextType';

const INITIAL_PAGE = 1;
const DEFAULT_LIMIT = 10;

export const EquipmentsContextElement = createContext<EquipmentsContextType>({
	equipList: [],
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	pageLimit: {} as number,
	totalPages: {} as number,
	currentPage: {} as number,
	equipsFilter: {} as FilterData,
	onPageChange: () => {},
	onLimitChange: () => {},
	onSearchEquip: () => {},
	onFilterChange: () => {},
});

interface ListParams {
	pageLimit: number;
	currentPage: number;
	totalPages: number;
	searchValue?: string;
	equipsFilter?: FilterData;
}

function defaultListParams(clearAll = true): ListParams {
	if (!clearAll) {
		const listEquipsParams = store.getState().data.listEquipsParams;
		return {
			pageLimit: listEquipsParams?.pageLimit || DEFAULT_LIMIT,
			currentPage: listEquipsParams?.currentPage || INITIAL_PAGE,
			totalPages: listEquipsParams?.totalPages || INITIAL_PAGE,
			equipsFilter: listEquipsParams?.equipsFilter || undefined,
		};
	} else {
		return {
			pageLimit: DEFAULT_LIMIT,
			currentPage: INITIAL_PAGE,
			totalPages: INITIAL_PAGE,
		};
	}
}

const EquipmentsProvider = (props: any) => {
	const [client, setClient] = useState<Client>(store.getState().data?.client);
	const [equipList, setEquipList] = useState<Equipment[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<RequisitionError>();
	const listParams = useRef<ListParams>(defaultListParams(false));

	const dispatch = useDispatch();

	useEffect(() => {
		if (client) {
			updateListParams('', 'search');
			clearListParams();
			requestEquips();
		}
		clientChangeListener();
	}, [client]);

	const clientChangeListener = () => {
		const unsubscribe = store.subscribe(() => {
			const clientSelected = store.getState().data?.client;
			if (clientSelected?.id !== client?.id) {
				setClient(clientSelected);
				unsubscribe();
			}
		});
	};

	const requestEquips = () => {
		setLoading(true);
		const clientId = client.id;
		const lendingId = client.id_client_orig;
		const { currentPage, pageLimit, searchValue, equipsFilter } = { ...listParams.current };

		const offset = (currentPage - 1) * pageLimit;
		getEquipments(clientId, pageLimit, offset, searchValue, lendingId, equipsFilter)
			.then(res => {
				const { count, equips } = res.data.response;
				updateListParams(Math.ceil(count / pageLimit), 'count');
				setEquipList(equips);
				setLoading(false);
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				clearListParams(true);
				setHasError(reportError);
				setLoading(false);
			});
	};

	const onPageChange = (page: number) => {
		updateListParams(page, 'page');
		requestEquips();
	};

	const onLimitChange = (newLimit: number) => {
		updateListParams(newLimit, 'limit');
		clearListParams();
		requestEquips();
	};

	const onSearchEquip = (search: string) => {
		updateListParams(search, 'search');
		clearListParams();
		requestEquips();
	};

	const onFilterChange = (newFilter: FilterData) => {
		updateListParams(newFilter, 'filter');
		clearListParams();
		requestEquips();
	};

	const updateListParams = (value: number | string | FilterData, field: 'filter' | 'limit' | 'page' | 'search' | 'count') => {
		const newListParams = { ...listParams.current };

		switch (field) {
			case 'filter':
				newListParams.equipsFilter = value as FilterData;
				break;
			case 'limit':
				newListParams.pageLimit = value as number;
				break;
			case 'page':
				newListParams.currentPage = value as number;
				break;
			case 'count':
				newListParams.totalPages = value as number;
				break;
			case 'search':
				newListParams.searchValue = value as string;
				break;
		}

		listParams.current = newListParams;
		dispatch(updateEquipsFilters(newListParams));
	};

	const clearListParams = (clearAll = false) => {
		if (clearAll) {
			listParams.current = defaultListParams();
		} else {
			const newListParams = { ...listParams.current };
			newListParams.currentPage = INITIAL_PAGE;
			newListParams.totalPages = INITIAL_PAGE;
			listParams.current = newListParams;
		}

		dispatch(updateEquipsFilters(listParams.current));
	};

	const providerValues = () => {
		return {
			equipList,
			isLoading,
			hasError,
			...listParams.current,
			onPageChange,
			onLimitChange,
			onSearchEquip,
			onFilterChange,
		};
	};

	return <EquipmentsContextElement.Provider value={providerValues()}>{props.children}</EquipmentsContextElement.Provider>;
};

export default EquipmentsProvider;
