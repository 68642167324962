/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React, { useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { DefaultServiceOrderAddress } from 'utils/constants';

interface Params {
	equipAddress: string;
	onAddressSelected: (value: DefaultServiceOrderAddress) => void;
}

const AddressField: React.FC<Params> = ({ equipAddress, onAddressSelected }) => {
	const [defaultAddress, setDefaultAddress] = useState<DefaultServiceOrderAddress>(DefaultServiceOrderAddress.DEFAULT_VALUE);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, value: DefaultServiceOrderAddress) => {
		setDefaultAddress(value);
		onAddressSelected(value);
	};

	return (
		<div className="service-order-address-selector">
			<FormControl component="fieldset">
				<RadioGroup aria-label="address-selector" name="address-selector" value={defaultAddress} onChange={handleChange}>
					<FormControlLabel value={DefaultServiceOrderAddress.DEFAULT_VALUE} control={<Radio />} label={equipAddress} />
					<FormControlLabel value={DefaultServiceOrderAddress.ADD_NEW_VALUE} control={<Radio />} label="Outro endereço" />
				</RadioGroup>
			</FormControl>
		</div>
	);
};

export default AddressField;
