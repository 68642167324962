/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import ModalTutorialVideo from '../../../../Shared/Tutorial/ModalTutorialVideo';

interface Params {
	onClose: () => void;
}

const ModalMobile: React.FC<Params> = ({ onClose }) => {
	return (
		<div className="modal-main-content-success">
			<div className="modal-main-backdrop">
				<div className="modal-mobile-component-tutorial">
					<div onClick={onClose} className="modal-mobile-header-content-tutorial">
						<CloseIcon />
					</div>
					<ModalTutorialVideo />
				</div>
			</div>
		</div>
	);
};

export default ModalMobile;
