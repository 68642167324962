/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import NotificationsCenter from 'components/Notifications/NotificationsModal/Mobile/Center';
import ClientContext from 'contexts/ClientContext/ClientContext';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { NotificationsContextElement } from 'contexts/NotificationsContext/NotificationsContext';
import { NotificationsContextType } from 'contexts/NotificationsContext/NotificationsContextType';
import { RouterContextElement } from 'contexts/RouterContext/RouterContext';
import { RouterContextType } from 'contexts/RouterContext/RouterContextType';
import React, { useContext, useState } from 'react';
import { store } from 'store';
import { EMPTY_USER_STORE, RouteList, ViewType } from 'utils/constants';
import history from '../../../routes/history';
import ClientSelector from '../../Shared/ClientSelector';
import iconHome from './../images/home-24px.png';
import iconMenu from './../images/menu-24px.png';
import iconNotification from './../images/notifications.png';
import MainOptions from './MainOptions';
import MobileHelpUser from './MobileHelpUser';
import MobileSettings from './MobileSettings';
import './styles.scss';

const MobileMenu: React.FC = () => {
	const { isRoot } = useContext<RouterContextType>(RouterContextElement);
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);
	const { hasNewNotifications } = useContext<NotificationsContextType>(NotificationsContextElement);

	const [showHelpUser, setShowHelpUser] = useState<boolean>(false);
	const [showMenuOptions, setShowMenuOptions] = useState<boolean>(false);
	const [showClientSelector, setShowClientSelector] = useState<boolean>(false);
	const [showNotificationsCenter, setNotificationsCenter] = useState<boolean>(false);

	const [showAppSettings, setShowAppSettings] = useState(false);

	const client = store.getState().data?.client;

	const clientName = () => {
		return client ? `${client.nome_fantasia.toLowerCase()}` : EMPTY_USER_STORE;
	};

	const handleHomeClick = () => {
		setShowHelpUser(false);
		setShowMenuOptions(false);
		setShowAppSettings(false);
		setNotificationsCenter(false);
		history.push(RouteList.EQUIPMENTS);
	};

	const handleMenuOptionsShowToogle = () => {
		if (showNotificationsCenter) {
			setNotificationsCenter(!showNotificationsCenter);
		}
		if (showAppSettings) {
			setShowAppSettings(!showAppSettings);
		}
		if (showHelpUser) {
			setShowHelpUser(!showHelpUser);
		}
		if (showClientSelector) {
			setShowClientSelector(!showClientSelector);
		}
		setShowMenuOptions(!showMenuOptions);
	};

	const handleNotificationsCenterShowToogle = () => {
		if (showMenuOptions) {
			setShowMenuOptions(!showMenuOptions);
		}
		if (showAppSettings) {
			setShowAppSettings(!showAppSettings);
		}
		if (showHelpUser) {
			setShowHelpUser(!showHelpUser);
		}
		if (showClientSelector) {
			setShowClientSelector(!showClientSelector);
		}
		setNotificationsCenter(!showNotificationsCenter);
	};

	const notificationsCenterContent = () => {
		return showNotificationsCenter ? <NotificationsCenter onClose={handleNotificationsCenterShowToogle} /> : <></>;
	};

	const handleNotificationsCount = () => {
		const hasShow = hasNewNotifications ? 'show' : 'hide';
		return <div className={`user-notifications-badge ${hasShow}`}></div>;
	};

	const handlerClientSelectorToggle = () => {
		if (isRoot && client) {
			if (showNotificationsCenter) {
				setNotificationsCenter(!showNotificationsCenter);
			}
			if (showAppSettings) {
				setShowAppSettings(!showAppSettings);
			}
			if (showHelpUser) {
				setShowHelpUser(!showHelpUser);
			}
			if (showMenuOptions) {
				setShowMenuOptions(!showMenuOptions);
			}
			setShowClientSelector(!showClientSelector);
		}
	};

	const clientSelectorContent = () =>
		showClientSelector ? (
			<ClientContext>
				<ClientSelector onClose={handlerClientSelectorToggle} />
			</ClientContext>
		) : (
			<></>
		);

	const handleClientDropDownIcon = () => (showClientSelector ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />);

	const clientSelectorAreaContent = () => {
		const lockedClass = !isRoot ? 'locked' : '';
		const activeClass = showClientSelector ? 'active' : '';
		return (
			<div className={`user-client-options-item ${activeClass} ${lockedClass}`} onClick={handlerClientSelectorToggle}>
				<div className="dropdown-area">
					<span className="client-name">{clientName()}</span>
					{handleClientDropDownIcon()}
				</div>
			</div>
		);
	};

	const onSettingsClick = () => {
		if (showMenuOptions) {
			setShowMenuOptions(!showMenuOptions);
		}
		if (showNotificationsCenter) {
			setNotificationsCenter(!showNotificationsCenter);
		}
		if (showHelpUser) {
			setShowHelpUser(!showHelpUser);
		}
		setShowAppSettings(!showAppSettings);
	};

	const appSettingsContent = () => {
		return showAppSettings ? <MobileSettings /> : <></>;
	};

	const onHelpClick = () => {
		if (showMenuOptions) {
			setShowMenuOptions(!showMenuOptions);
		}
		if (showNotificationsCenter) {
			setNotificationsCenter(!showNotificationsCenter);
		}
		if (showAppSettings) {
			setShowAppSettings(!showAppSettings);
		}
		setShowHelpUser(!showHelpUser);
	};

	const helpUserContent = () => {
		return showHelpUser ? <MobileHelpUser /> : <></>;
	};

	const mainManuContent = () => {
		const isRootActive = isRoot && !showMenuOptions && !showNotificationsCenter && !showClientSelector && !showHelpUser && !showAppSettings;
		const lockedClass = !isRoot ? 'locked' : '';
		const menu = showMenuOptions || showHelpUser || showAppSettings;
		const home = !showNotificationsCenter && !showMenuOptions && !showClientSelector && !showHelpUser && !showAppSettings;

		return (
			<section className="main-mobile-menu">
				<div className="user-select-options">
					<div className={`user-select-options-item ${menu ? 'active' : ''}`} onClick={handleMenuOptionsShowToogle}>
						<img src={iconMenu} className="menu-icon" alt="menu-icon" />
					</div>
					<div className={`user-select-options-item home ${(isRootActive || lockedClass) && home! ? 'active' : ''}`} onClick={handleHomeClick}>
						<img src={iconHome} alt="home-icon" />
					</div>
					<div className={`user-select-options-item notify ${showNotificationsCenter ? 'active' : ''}`} onClick={handleNotificationsCenterShowToogle}>
						<img
							style={{
								width: '40px',
								height: '35px',
							}}
							src={iconNotification}
							alt="notify-icon"
						/>
						{handleNotificationsCount()}
					</div>
				</div>
				{clientSelectorAreaContent()}
			</section>
		);
	};

	const menuOptionsContent = () => (showMenuOptions ? <MainOptions {...{ onSettingsClick, onHelpClick }} /> : <></>);

	return viewType === ViewType.MOBILE ? (
		<>
			{mainManuContent()}
			{menuOptionsContent()}
			{clientSelectorContent()}
			{notificationsCenterContent()}
			{helpUserContent()}
			{appSettingsContent()}
		</>
	) : (
		<></>
	);
};

export default MobileMenu;
