/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import Client from 'models/Client';
import Equipment from 'models/Equipment';
import FileProperties from 'models/FileProperties';
import { RequisitionError } from 'models/RequisitionError';
import ServiceOrder, { ServiceOrderInput } from 'models/ServiceOrder';
import { ServiceOrderRequest } from 'models/ServiceOrderRequest';
import React, { createContext, useState } from 'react';
import { createNewServiceOrder } from 'services';
import { store } from 'store';
import { StepTypes } from 'utils/constants';
import { OpenServiceOrderContextType } from './OpenServiceOrderContextType';

export const OpenServiceOrderContextElement = createContext<OpenServiceOrderContextType>({
	isLoading: {} as boolean,
	createServiceOrder: () => {},
});

const OpenServiceOrderProvider = (props: any) => {
	const [isLoading, setLoading] = useState<boolean>(false);

	const createServiceOrder = (
		equipment: Equipment,
		serviceOrder: ServiceOrder,
		message: string,
		files: FileProperties[],
		callback: (res: any, err?: RequisitionError) => void,
	) => {
		const client = store.getState().data?.client;
		if (client) {
			setLoading(true);
			const newServiceOrder = buildNewServiceOrderParams(equipment, serviceOrder, client, message, files);

			createNewServiceOrder(newServiceOrder)
				.then(res => {
					setLoading(false);
					callback(res.data.serviceOrderCode);
				})
				.catch(err => {
					const error = err.request.response?.error;
					const reportError = {
						message: error ? error.message : 'Internal Server Error',
						code: error ? error.statusCode : 500,
						error: error ? error.name : 'ServerError',
					};
					setLoading(false);
					callback(null, reportError);
				});
		} else {
			callback(null, { message: 'Client is invalid', error: client });
		}
	};

	const buildNewServiceOrderParams = (equipment: Equipment, service: ServiceOrderInput, client: Client, message: string, files: FileProperties[]) => {
		const clientData = { ...client };
		if (service.addressDefault) {
			clientData.street = service.address;
			clientData.neighborhood = service.neighborhood;
			clientData.city = service.city;
			clientData.state = service.state_sigla;
			clientData.zip_code = service.zip_code;
		}
		const newServiceOrder = {
			equipment,
			service,
			client: clientData,
			message,
			step_type: StepTypes.CREATE_OS,
			files,
		} as ServiceOrderRequest;
		return newServiceOrder;
	};

	const providerValues = () => {
		return { isLoading, createServiceOrder };
	};

	return <OpenServiceOrderContextElement.Provider value={providerValues()}>{props.children}</OpenServiceOrderContextElement.Provider>;
};

export default OpenServiceOrderProvider;
