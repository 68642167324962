/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import $ from 'jquery';
import NotificationsList from '../List';
import React, { useContext } from 'react';
import { RouteList } from 'utils/constants';
import CloseIcon from '@material-ui/icons/Close';
import EmptyList from 'components/Shared/EmptyList';
import history from '../../../../../routes/history';
import { ClickAwayListener } from '@material-ui/core';
import LoadingProgress from 'components/Shared/LoadingProgress';
import { Notification } from '../../../../../models/Notification';
import { LastNotificationsContextElement } from 'contexts/LastNotificationsContext/LastNotificationsContext';
import { LastNotificationsContextType } from 'contexts/LastNotificationsContext/LastNotificationsContextType';

import './styles.scss';

interface Props {
	onClose: () => void;
}

const NotificationsCenter: React.FC<Props> = ({ onClose }) => {
	const { isLoading, lastNotificationsList, hasNewNotifications, onChangeAllStatus } = useContext<LastNotificationsContextType>(LastNotificationsContextElement);

	const onItemClick = (notification: Notification) => {
		if (window.location.pathname === `/atendimentos/${notification.osNumber}`) {
			window.location.reload();
		}
		history.push(`/atendimentos/${notification.osNumber}`, { notification });
	};

	const handleNotificationCenterClick = () => {
		history.push(RouteList.NOTIFICATIONS);
	};

	const markAllCheckContent = () => {
		const disableOption = hasNewNotifications ? '' : 'disabled';
		return (
			<div
				className={`notify-center-option ${disableOption}`}
				onClick={() => {
					onChangeAllStatus();
				}}
			>
				<span>Marcar todas como lidas</span>
			</div>
		);
	};

	const contentPosition = () => {
		const position =
			($('.user-notifications-area-icon')
				.first()
				.offset()?.left || 170) - 370;
		return position.toString();
	};

	const mainContent = () => {
		const modalPosition = contentPosition();
		return (
			<ClickAwayListener onClickAway={onClose}>
				<div
					className="notify-center-main-content"
					onClick={(e: any) => {
						e.stopPropagation();
					}}
					style={{ left: `${modalPosition}px` }}
				>
					<div className="notify-center-header">
						<span>Notificações</span>
						<div className="notify-center-close">
							<CloseIcon onClick={onClose}></CloseIcon>
						</div>
					</div>
					{markAllCheckContent()}
					<div className="notify-center-content">
						<NotificationsList {...{ onItemClick }} />
						<EmptyList show={!lastNotificationsList.length && !isLoading} text="Sem notificações por aqui" />
						<LoadingProgress show={isLoading} text="Buscando notificações..." />
					</div>
					<div className="notify-center-footer" onClick={handleNotificationCenterClick}>
						<span>Abrir central de Notificações</span>
					</div>
				</div>
			</ClickAwayListener>
		);
	};

	return mainContent();
};

export default NotificationsCenter;
