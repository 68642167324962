/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { FilterData } from 'models/FilterData';
import { Notification } from 'models/Notification';
import React, { useContext, useState } from 'react';
import EmptyList from 'components/Shared/EmptyList';
import NotificationListItem from '../NotificationsListItem';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import LoadingProgress from 'components/Shared/LoadingProgress';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { NotificationsContextElement } from 'contexts/NotificationsContext/NotificationsContext';
import { NotificationsContextType } from 'contexts/NotificationsContext/NotificationsContextType';

import './styles.scss';

interface Params {
	onItemClick: (notify: Notification) => void;
}

const NotificationsList: React.FC<Params> = ({ onItemClick }) => {
	const { notificationsList, isLoading, notifyFilter, onFilterChange } = useContext<NotificationsContextType>(NotificationsContextElement);

	const [statusOrder, setStatusOrder] = useState(notifyFilter?.statusOrder || 'asc');
	const [createdOrder, setCreatedOrder] = useState(notifyFilter?.createdOrder || 'desc');

	const onOrderChange = (type: string, order: 'desc' | 'asc') => {
		const newFilter = notifyFilter ? { ...notifyFilter } : ({} as FilterData);

		if (type === 'created') {
			newFilter.createdOrder = order;
			newFilter.statusOrder = statusOrder;
		} else {
			newFilter.statusOrder = order;
			newFilter.createdOrder = createdOrder;
		}

		setStatusOrder(newFilter.statusOrder);
		setCreatedOrder(newFilter.createdOrder);
		onFilterChange(newFilter);
	};

	const arrowCreateContent = () => {
		return createdOrder === 'desc' ? (
			<ArrowUpwardIcon onClick={() => onOrderChange('created', 'asc')} />
		) : (
			<ArrowDownwardIcon onClick={() => onOrderChange('created', 'desc')} />
		);
	};

	const arrowStatusContent = () => {
		return statusOrder !== 'asc' ? <ArrowUpwardIcon onClick={() => onOrderChange('status', 'asc')} /> : <ArrowDownwardIcon onClick={() => onOrderChange('status', 'desc')} />;
	};

	const listContent = () => {
		return (
			<>
				{notificationsList.map((notify: Notification, index: number) => {
					return <NotificationListItem notify={notify} onItemClick={onItemClick} key={index} />;
				})}
			</>
		);
	};

	return (
		<section className="notifications-list-content">
			<div className="notifications-list-header">
				<div className="list-header title">
					<span>Notificação</span>
				</div>
				<div className="list-header message">
					<span>Mensagem</span>
				</div>
				<div className="list-header protocol">
					<span>Protocolo</span>
				</div>
				<div className="list-header created">
					<span>Data</span>
					{arrowCreateContent()}
				</div>
				<div className="list-header status">
					<span>Status</span>
					{arrowStatusContent()}
				</div>
			</div>
			<div className="notifications-list-items">{listContent()}</div>
			<EmptyList show={!notificationsList.length} text="Sem notificações por aqui" />
			<LoadingProgress show={isLoading} text="Buscando notificações..." />
		</section>
	);
};

export default NotificationsList;
