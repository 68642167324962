/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { store } from 'store';
import { RouteList } from 'utils/constants';
import history from '../../../routes/history';
import './styles.scss';

const MainPageSelector: React.FC = () => {
	const actualLocation = history.location;
	const client = store.getState().data?.client;

	let showPageSelector = false;
	if ((actualLocation.pathname === RouteList.EQUIPMENTS || actualLocation.pathname === RouteList.ORDERS) && client) {
		showPageSelector = true;
	}

	const isEquipPath = (match: any, location: any) => {
		if (!location) return false;
		const { pathname } = location;
		return pathname === RouteList.EQUIPMENTS;
	};

	const isOsPath = (match: any, location: any) => {
		if (!location) return false;
		const { pathname } = location;
		return pathname === RouteList.ORDERS;
	};

	const mainContent = () => {
		return (
			<section className="main-page-selector">
				<NavLink to={RouteList.EQUIPMENTS} className="item-selector" activeClassName={'active'} isActive={isEquipPath}>
					<span>EQUIPAMENTOS</span>
				</NavLink>
				<NavLink to={RouteList.ORDERS} className="item-selector" activeClassName={'active'} isActive={isOsPath}>
					<span>ATENDIMENTOS</span>
				</NavLink>
			</section>
		);
	};

	return showPageSelector ? mainContent() : <></>;
};

export default MainPageSelector;
