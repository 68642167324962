/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import Client from 'models/Client';
import { RequisitionError } from 'models/RequisitionError';
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllClients } from 'services';
import { store } from 'store';
import { saveClientSelected } from '../../store/modules/data/actions';
import { ClientContextType } from './ClientContextType';

const firstIndex = 0;
export const ClientContextElement = createContext<ClientContextType>({
	clientList: [],
	clientSelected: {} as Client,
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	onSelectClient: () => {},
	onSearchClient: () => {},
});

const ClientProvider = (props: any) => {
	const [clientList, setClientList] = useState<Client[]>([]);
	const [clientSelected, setClientSelected] = useState<Client>();
	const [hasError, setHasError] = useState<RequisitionError>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();

	useEffect(() => {
		requestClients();
	}, []);

	const requestClients = (searchValue = '') => {
		setLoading(true);
		const userId = store.getState().data.user.id;
		getAllClients(userId, searchValue)
			.then(res => {
				const { allClients } = res.data;
				setClientList(allClients);
				setClientSelected(allClients[firstIndex]);
				setLoading(false);
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				setHasError(reportError);
				setLoading(false);
			});
	};

	const onSelectClient = (clientSelected: Client, callback: any) => {
		dispatch(saveClientSelected(clientSelected));
		setClientSelected(clientSelected);
		callback(clientSelected);
	};

	const onSearchClient = (searchValue: string, callback: any) => {
		requestClients(searchValue);
		callback();
	};

	const providerValues = () => {
		return { clientList, clientSelected, hasError, isLoading, onSelectClient, onSearchClient };
	};

	return <ClientContextElement.Provider value={providerValues()}>{props.children}</ClientContextElement.Provider>;
};

export default ClientProvider;
