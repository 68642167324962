import produce from 'immer';

const INITIAL_STATE = { user: null, client: null, listEquipsParams: null, listOsParams: null, listStoresParams: null };

export default function user(state = INITIAL_STATE, action) {
	return produce(state, draft => {
		switch (action.type) {
			case '@auth/SIGN_IN_REQUEST': {
				draft.user = action.payload.user;
				draft.client = action.payload.user.client;
				break;
			}
			case '@auth/SIGN_OUT_REQUEST': {
				draft.user = null;
				draft.client = null;
				draft.listEquipsParams = null;
				draft.listOsParams = null;
				draft.listStoresParams = null;
				break;
			}
			case '@data/SAVE_CLIENT_INFO': {
				draft.client = action.payload.client;
				break;
			}
			case '@data/UPDATE_USER_INFO': {
				draft.user = action.payload.user;
				break;
			}
			case '@data/UPDATE_OS_FILTER': {
				draft.listOsParams = action.payload.listOsParams;
				break;
			}
			case '@data/UPDATE_EQUIP_FILTER': {
				draft.listEquipsParams = action.payload.listEquipsParams;
				break;
			}
			case '@data/UPDATE_STORES_FILTER': {
				draft.listStoresParams = action.payload.listStoresParams;
				break;
			}
			default:
				return state;
		}
	});
}
