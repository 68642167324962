/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { SocketContextElement } from 'contexts/SocketContext/SocketContext';
import { SocketContextType } from 'contexts/SocketContext/SocketContextType';
import { RequisitionError } from 'models/RequisitionError';
import UpdateInfo from 'models/UpdateInfo';
import React, { useContext, useEffect, useState } from 'react';
import { getLastVersion } from 'services';
import { store } from 'store';
import appPackage from '../../../package.json';
import { VersionContextElement } from './VersionContextType';

const VersionProvider = (props: any) => {
	const { eventListener, disconnect } = useContext<SocketContextType>(SocketContextElement);
	const [newVersion, setNewVersion] = useState<boolean>(false);

	const updateNotificationEl = document.getElementById('update-notification-bar');

	useEffect(() => {
		verifyNewVersion();
		socketEvents();
		return () => {
			disconnect();
		};
	}, []);

	const socketEvents = () => {
		console.log('Version Provider Socket Listener');
		const socketTopic = `notify_app_report/updates`;
		eventListener(socketTopic, (err: boolean, data: any) => {
			if (!err) {
				console.log('Update Receive on Socket: ', data.appVersion);
				setNewVersion(true);
				showUpdateNotification(true);
				setNotificationListener();
			} else {
				console.error('Houve uma falha no listener de versões');
			}
		});
	};

	const verifyNewVersion = () => {
		// Verificar a versão do usuário local
		const userId = store.getState().data.user?.id;
		getLastVersion(userId)
			.then(res => {
				const appVersion = res.data.appUpdate.appVersion;
				if (appPackage.version !== appVersion) {
					console.log('Update Receive on load: ', appVersion);
					showUpdateNotification(true);
				} else {
					console.log('Version Updated');
				}
			})
			.catch(err => {
				console.error('Fail on request new updates', err);
			});
	};

	const reportUserVersion = () => {
		// Atualizar a versão do usuário no banco
	};

	const onUpdateClick = () => {
		// Atualizar o app
		window.navigator.serviceWorker.ready
			.then(registration => {
				registration.unregister().then(() => {
					setNewVersion(false);
					showUpdateNotification(false);
					window.location.reload();
					localStorage.clear();
				});
			})
			.catch(() => {
				setNewVersion(false);
				showUpdateNotification(false);
				window.location.reload();
				localStorage.clear();
			});
	};

	const showUpdateNotification = (show: boolean) => {
		updateNotificationEl.className = show ? 'show' : '';
	};

	const setNotificationListener = () => {
		updateNotificationEl.addEventListener('click', onUpdateClick);
	};

	const onUpdateVersion = (updateInfo: UpdateInfo, callback: (hasError?: RequisitionError) => void) => {};

	const providerValues = () => {
		return {
			newVersion,
			onUpdateClick,
			onUpdateVersion,
		};
	};

	return <VersionContextElement.Provider value={providerValues()}>{props.children}</VersionContextElement.Provider>;
};

export default VersionProvider;
