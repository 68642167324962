/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';

interface Params {
	register: any;
	formName: string;
	onChange: (value: string, field: string) => void;
}

const RatingField: React.FC<Params> = ({ formName, register, onChange }) => {
	return (
		<div className="form-item-rating-content">
			<div className="form-item-rating-label">
				<span>Muito ruim</span>
			</div>
			<div className="form-item-rating-input">
				<FormControl component="fieldset">
					<RadioGroup aria-label="service-rating" {...register(formName)} onChange={(e, value) => onChange(value, formName)}>
						<FormControlLabel value="1" control={<Radio />} label="1" />
						<FormControlLabel value="2" control={<Radio />} label="2" />
						<FormControlLabel value="3" control={<Radio />} label="3" />
						<FormControlLabel value="4" control={<Radio />} label="4" />
						<FormControlLabel value="5" control={<Radio />} label="5" />
					</RadioGroup>
				</FormControl>
			</div>
			<div className="form-item-rating-label">
				<span>Muito bom</span>
			</div>
		</div>
	);
};

export default RatingField;
