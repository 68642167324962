/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import FormValidator from 'components/Shared/FormFields/FormValidator';
import PasswordField from 'components/Shared/PasswordField';
import { AccountContextElement } from 'contexts/AccountContext/AccountContext';
import { AccountContextType } from 'contexts/AccountContext/AccountContextType';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { LoginInput } from 'models/LoginInfo';
import { RequisitionError } from 'models/RequisitionError';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ViewType } from 'utils/constants';
import LoadingModal from '../LoadingModal';
import ResponseModal from '../ResponseModal';
import './styles.scss';

const PASSWORD_REGEX = '(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}';

interface Params {
	email: string;
	authToken?: string;
}

const RenewPassForm: React.FC<Params> = ({ email }) => {
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);
	const { onUpdatePass } = useContext<AccountContextType>(AccountContextElement);
	const { clearUser } = useContext<DeviceContextType>(DeviceContextElement);
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<LoginInput>();

	const [isLoading, setIsLoading] = useState(false);
	const [successResponse, setSuccessResponse] = useState<boolean>(false);

	const onSubmit = (data: LoginInput) => {
		data.email = email;
		if (data.newPassword === data.password) {
			toast.error('A senha atual e a nova senha não podem ser iguais!');
		} else if (data.newPassword === data.confirmPassword) {
			setIsLoading(true);
			onUpdatePass(data, onComplete);
		} else {
			toast.error('A senha informada deve ser igual a anterior!');
		}
	};

	const onComplete = (hasError?: RequisitionError) => {
		setIsLoading(false);
		if (hasError) {
			switch (hasError.code) {
				case 401:
					toast.error('A senha atual informada está errada!');
					break;
				default:
					toast.error('Erro ao atualizar senha!');
					break;
			}
		} else {
			onSuccess();
		}
	};

	const onSuccess = () => {
		setSuccessResponse(true);
	};

	const onClose = () => {
		setSuccessResponse(false);

		clearUser();
	};

	const onResponseSuccessContent = () => {
		const title = 'Senha alterada';
		const children = (
			<span>
				Senha alterada com sucesso!
				<br /> Por gentileza, faça login novamente.
			</span>
		);
		const hideTitle = viewType === ViewType.MOBILE;

		return successResponse ? <ResponseModal {...{ title, children, onClose, hideTitle }} /> : <></>;
	};

	const loadingContent = () => {
		return <LoadingModal text="Carregando..." show={isLoading} title="Atualizando senha" />;
	};

	const handleLoadingContent = () => {
		return isLoading ? loadingContent() : <></>;
	};

	const formContent = () => {
		return (
			<form onSubmit={handleSubmit(onSubmit)} className="main-form-content renew-content">
				<div>
					<div className="form-item-content">
						<label>Senha Atual</label>
						<PasswordField register={register('password', { required: true, maxLength: 20 })} placeholder="Digite sua senha atual" />
						<FormValidator {...{ formName: 'password', validatorName: 'Senha', errors, isDirty }} />
					</div>
					<div className="form-item-content">
						<label>Nova senha</label>
						<PasswordField
							register={register('newPassword', { required: true, minLength: 8, maxLength: 20, pattern: RegExp(PASSWORD_REGEX) })}
							placeholder="Digite sua nova senha"
						/>
						<FormValidator {...{ formName: 'newPassword', validatorName: 'Senha', errors, isDirty }} />
					</div>
					<div className="form-item-content">
						<label>Confirme a nova senha</label>
						<PasswordField
							register={register('confirmPassword', { required: true, minLength: 8, maxLength: 20, pattern: RegExp(PASSWORD_REGEX) })}
							placeholder="Confirme sua nova senha"
						/>
						<FormValidator {...{ formName: 'confirmPassword', validatorName: 'Senha', errors, isDirty }} />
					</div>
				</div>
				<div className="renew-pass-options">
					<span>Sua senha deve ter entre 8 e 20 caracteres, ter ao menos uma letra maiúscula, uma minúscula, um número e um caractere especial.</span>
				</div>
				<div className="renew-pass-form-actions">
					<button className="renew-pass-submit custom-button-primary" type="submit">
						SALVAR
					</button>
				</div>
			</form>
		);
	};

	return (
		<div className="renew-pass-form">
			{formContent()}
			{handleLoadingContent()}
			{onResponseSuccessContent()}
		</div>
	);
};

export default RenewPassForm;
