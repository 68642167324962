import CustomButton from 'components/Shared/CustomButton';
import FormValidator from 'components/Shared/FormFields/FormValidator';
import LoadingModal from 'components/Shared/LoadingModal';
import Modal from 'components/Shared/Modal';
import { ServiceOrderDetailsContextElement } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContext';
import { ServiceOrderDetailsContextType } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContextType';
import RequestResponse from 'models/RequestResponse';
import ServiceOrder from 'models/ServiceOrder';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { store } from 'store';
import { StepTypes } from 'utils/constants';
import CancelServiceOrderModalContentResponseError from '../CancelServiceOrderContentResponseError';
import './styles.scss';

interface Params {
	show: boolean;
	serviceOrder: ServiceOrder;
	onClose: () => void;
}

const CancelServiceOrderModalContent: React.FC<Params> = ({ show, serviceOrder, onClose }) => {
	const { onCancelServiceOrderRequest } = useContext<ServiceOrderDetailsContextType>(ServiceOrderDetailsContextElement);
	const [loading, setLoading] = useState<boolean>(false);
	const userInfo = store.getState().data.user.name;
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm();

	const onCompleted = (response: RequestResponse) => {
		setLoading(false);
		if (response.reportError) {
			const errorCode = response.reportError.code;
			switch (errorCode) {
				case 405:
					toast.error('Não é possível cancelar atendimento em andamento');
					break;
				default:
					toast.error('Erro ao realizar a solicitação');
					break;
			}
		} else {
			toast.success('Solicitação enviada com sucesso!');
			onClose();
		}
	};

	const onConfirm = (data: any) => {
		const message = data.message;
		const serviceOrderId = serviceOrder.id;
		const requestServiceOrderCancel = { serviceOrderId, message, userInfo };
		if (data.message) {
			setLoading(true);
			onCancelServiceOrderRequest(requestServiceOrderCancel, onCompleted);
		} else {
			toast.error('o preenchimento do motivo é obrigatório');
		}
	};

	const cancelServiceOrderContent = () => {
		return (
			<div className="cancel-service-order-main-content">
				<form className="main-form-content" onSubmit={handleSubmit(onConfirm)}>
					<div className="cancel-service-order-content">
						<p>{`Por que deseja cancelar a solicitação o atendimento ${serviceOrder.service_order}?`}</p>
						<div className="form-item-content">
							<textarea placeholder="Informe o motivo de sua solicitação de cancelamento" {...register('message', { required: true, maxLength: 500 })} />
							<FormValidator {...{ formName: 'message', errors, isDirty }} />
						</div>
						<span>
							<b>Atenção:</b> sua solicitação será avaliada por um atendente e, nesta avaliação, sua ordem de serviço poderá ser excluída ou apenas finalizada em sua
							lista de atendimentos.
						</span>
					</div>
					<div className="cancel-service-actions">
						<CustomButton context="primary" type="submit" label="ENVIAR" />
						<CustomButton context="secondary" label="CANCELAR" onClick={onClose} />
					</div>
				</form>
			</div>
		);
	};

	const mainContent = () => {
		const title = 'SOLICITAR CANCELAMENTO';

		return (
			<>
				<Modal show={show} title={title} defaultTitle={title} onClose={onClose}>
					{cancelServiceOrderContent()}
				</Modal>
				<LoadingModal show={loading} title="Solicitando o cancelamento" text="Enviando..." />
			</>
		);
	};

	const errorContent = () => {
		return <CancelServiceOrderModalContentResponseError show={show} status={serviceOrder.status} onCloseCancelServiceOrderModalClick={onClose} />;
	};

	return serviceOrder.status !== StepTypes.CLOSE_OS && serviceOrder.status !== StepTypes.CANCEL_REQUEST ? mainContent() : errorContent();
};

export default CancelServiceOrderModalContent;
