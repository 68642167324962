/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { RouterContextElement } from 'contexts/RouterContext/RouterContext';
import { RouterContextType } from 'contexts/RouterContext/RouterContextType';
import MainPage from 'pages/MainPage';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteList, UserTypes } from 'utils/constants';
import { store } from '../store';

type TParams = { component: any; exact: boolean; path?: string; isPrivate?: boolean; context?: any };

const RouterWrapper: React.FC<TParams> = ({ component: Component, isPrivate, context, path, ...rest }) => {
	const { onRouteChange } = useContext<RouterContextType>(RouterContextElement);
	const { signed } = store.getState().auth;
	const hasClient = store.getState().data?.client;

	if (!signed && isPrivate) {
		const redirectTo = RouteList.LOGIN;
		onRouteChange(redirectTo);
		return <Redirect to={redirectTo} />;
	}

	if (signed && !hasClient && isPrivate && path !== RouteList.EQUIPMENTS) {
		const redirectTo = RouteList.EQUIPMENTS;
		onRouteChange(redirectTo);
		return <Redirect to={redirectTo} />;
	}

	if (signed && !isPrivate && path !== RouteList.REDEFINE_PASS) {
		const redirectTo = RouteList.EQUIPMENTS;
		onRouteChange(redirectTo);
		return <Redirect to={redirectTo} />;
	}

	if (path === RouteList.DEFAULT || path === RouteList.HOME) {
		const redirectTo = RouteList.LOGIN;
		onRouteChange(redirectTo);
		return <Redirect to={redirectTo} />;
	}

	if (path === RouteList.STORES_SETTINGS && signed) {
		const { appConfigs } = store.getState().data.user;
		if (appConfigs?.userType === UserTypes.DEFAULT) {
			const redirectTo = RouteList.EQUIPMENTS;
			onRouteChange(redirectTo);
			return <Redirect to={redirectTo} />;
		}
	}

	onRouteChange(path as RouteList);

	const MainContext = context ? context : Fragment;

	const mainLayout = (props: any) => {
		return (
			<MainContext>
				<MainPage>
					<Component {...props} />
				</MainPage>
			</MainContext>
		);
	};

	return <Route {...rest} render={props => mainLayout({ ...props })} />;
};

RouterWrapper.propTypes = {
	component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouterWrapper.defaultProps = {
	isPrivate: false,
};

export default RouterWrapper;
