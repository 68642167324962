/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import FilterListIcon from '@material-ui/icons/FilterList';
import ServiceOrderFilter from 'components/ServiceOrder/ServiceOrderFilter';
import ServiceOrderLabel from 'components/ServiceOrder/ServiceOrderLabel';
import ServiceOrderList from 'components/ServiceOrder/ServiceOrderList';
import EmptyUser from 'components/Shared/EmptyUser';
import Modal from 'components/Shared/Modal';
import PageListOptions from 'components/Shared/PageListOptions';
import PageTitle from 'components/Shared/PageTitle';
import Paginator from 'components/Shared/Paginator';
import CancelServiceOrderModalContent from 'components/Shared/ServiceOrderCancel/CancelServiceOrderContent';
import { ServiceOrderContextElement } from 'contexts/ServiceOrderContext/ServiceOrderContext';
import { ServiceOrderContextType } from 'contexts/ServiceOrderContext/ServiceOrderContextType';
import ServiceOrderDetailsContext from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContext';
import Client from 'models/Client';
import { ButtonOptions, PaginatorOptions, SearchOptions } from 'models/PageListOptions';
import ServiceOrder from 'models/ServiceOrder';
import React, { useContext, useEffect, useState } from 'react';
import history from 'routes/history';
import { store } from 'store';
import './styles.scss';

const ServiceOrders: React.FC = () => {
	const { totalPages, currentPage, isLoading, pageLimit, onPageChange, onLimitChange, onSearchOrder } = useContext<ServiceOrderContextType>(ServiceOrderContextElement);

	const [client, setClient] = useState<Client>(store.getState().data?.client);
	const [showFilter, setShowFilter] = useState<boolean>(false);

	const [serviceOrderToCancel, setServiceOrderToCancel] = useState<ServiceOrder>();
	const [showCancelServiceOrderModal, setShowCancelServiceOrderModal] = useState<boolean>(false);

	useEffect(() => {
		scrollListener();
		clientChangeListener();
		$('.input-search-field')
			.find('input')
			.val('');
	}, [client]);

	useEffect(() => {
		if (serviceOrderToCancel) {
			setShowCancelServiceOrderModal(true);
		} else {
			setShowCancelServiceOrderModal(false);
		}
	}, [serviceOrderToCancel]);

	const scrollListener = () => {
		const listComponent = $('.orders-list-items');
		listComponent;
	};

	const clientChangeListener = () => {
		const unsubscribe = store.subscribe(() => {
			const clientSelected = store.getState().data?.client;
			if (clientSelected?.id !== client?.id) {
				setClient(clientSelected);
				unsubscribe();
			}
		});
	};

	const onItemClick = (serviceOrder: ServiceOrder) => {
		history.push(`/atendimentos/${serviceOrder.service_order}`, { serviceOrder });
	};

	const onCancelServiceOrderClick = (serviceOrder: ServiceOrder) => {
		setServiceOrderToCancel(serviceOrder);
	};

	const onCloseCancelServiceOrderModalClick = () => {
		setServiceOrderToCancel(undefined);
	};

	const cancelServiceOrderContent = () => {
		return serviceOrderToCancel ? (
			<ServiceOrderDetailsContext>
				<CancelServiceOrderModalContent serviceOrder={serviceOrderToCancel} show={showCancelServiceOrderModal} onClose={onCloseCancelServiceOrderModalClick} />
			</ServiceOrderDetailsContext>
		) : (
			<></>
		);
	};

	const filterModalContent = () => {
		return (
			<Modal show={showFilter} title="Filtros" defaultTitle="Filtros" onClose={filterModalToggle}>
				<ServiceOrderFilter onClose={filterModalToggle} />
			</Modal>
		);
	};

	const filterModalToggle = () => {
		setShowFilter(!showFilter);
	};

	const listOptionsContent = () => {
		const searchOptions: SearchOptions = {
			onChangeValues: onSearchOrder,
			placeHolder: 'Procurar atendimento',
		};

		const paginatorOptions: PaginatorOptions = {
			limit: pageLimit,
			onChangeLimit: onLimitChange,
		};

		const buttonOptions: ButtonOptions = {
			onClick: filterModalToggle,
			icon: <FilterListIcon />,
		};

		return <PageListOptions {...{ searchOptions, paginatorOptions, buttonOptions }} />;
	};

	const headerContent = () => {
		return (
			<section className="orders-header-content">
				<div className="options-list-area">
					<ServiceOrderLabel />
					{listOptionsContent()}
				</div>
			</section>
		);
	};

	const mainContent = () => {
		const paginatorContent = <Paginator selected={currentPage} total={totalPages} isLoading={isLoading} onPageChange={onPageChange} />;
		return client ? (
			<section className="orders-main-content">
				{headerContent()}
				<ServiceOrderList {...{ onCancelServiceOrderClick, onItemClick, paginatorContent }} />
			</section>
		) : (
			<EmptyUser />
		);
	};

	return (
		<>
			<PageTitle title="Atendimentos" />
			{mainContent()}
			{filterModalContent()}
			{cancelServiceOrderContent()}
		</>
	);
};

export default ServiceOrders;
