/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { Button } from '@material-ui/core';
import EquipmentsList from 'components/Equipments/EquipmentsList';
import EquipmentsListItemDetails from 'components/Equipments/EquipmentsListItemDetails';
import EquipmentTransferModal from 'components/Equipments/EquipmentsTransferModal';
import AddEquipIcon from 'components/Shared/AddEquipIcon';
import EmptyUser from 'components/Shared/EmptyUser';
import Modal from 'components/Shared/Modal';
import PageListOptions from 'components/Shared/PageListOptions';
import PageTitle from 'components/Shared/PageTitle';
import Paginator from 'components/Shared/Paginator';
import { EquipmentsContextElement } from 'contexts/EquipmentsContext/EquipmentsContext';
import { EquipmentsContextType } from 'contexts/EquipmentsContext/EquipmentsContextType';
import EquipmentsDetailsContext from 'contexts/EquipmentsDetailsContext/EquipmentsDetailsContext';
import TransfersContext from 'contexts/TransfersContext/TransfersContext';
import Client from 'models/Client';
import Equipment from 'models/Equipment';
import { ButtonOptions, PaginatorOptions, SearchOptions } from 'models/PageListOptions';
import React, { useContext, useEffect, useState } from 'react';
import history from 'routes/history';
import { store } from 'store';
import './styles.scss';

const Equipments: React.FC = () => {
	const { totalPages, currentPage, isLoading, pageLimit, onPageChange, onLimitChange, onSearchEquip } = useContext<EquipmentsContextType>(EquipmentsContextElement);

	const [showOpenServiceOrderModal, setShowOpenServiceOrderModal] = useState<boolean>(false);
	const [showAddEquipModal, setShowAddEquipModal] = useState<boolean>(false);
	const [client, setClient] = useState<Client>(store.getState().data?.client);
	const [selectedEquip, setSelectedEquip] = useState<Equipment>();

	useEffect(() => {
		clientChangeListener();
		$('.input-search-field')
			.find('input')
			.val('');
	}, [client]);

	const clientChangeListener = () => {
		const unsubscribe = store.subscribe(() => {
			const clientSelected = store.getState().data?.client;
			if (clientSelected?.id !== client?.id) {
				setClient(clientSelected);
				unsubscribe();
			}
		});
	};

	const onItemClick = (equipment: Equipment) => {
		console.log(equipment.serial_number.replace('/', '%'));
		history.push(`/equipamentos/${encodeURIComponent(equipment.serial_number)}/atendimentos`, { equipment });
	};

	const onOpenClick = (equipSelected: Equipment) => {
		setSelectedEquip(equipSelected);
		setShowOpenServiceOrderModal(true);
	};

	const closeServiceOrderToggle = () => {
		setShowOpenServiceOrderModal(false);
		setSelectedEquip(undefined);
	};

	const serviceOrderModalContent = () => {
		return (
			<EquipmentsDetailsContext>
				<EquipmentsListItemDetails {...{ equipment: selectedEquip, onClose: closeServiceOrderToggle }} />
			</EquipmentsDetailsContext>
		);
	};

	const showOpenServiceModalContent = () => {
		return showOpenServiceOrderModal ? serviceOrderModalContent() : <></>;
	};

	const onOpenAddEquipmentClick = () => {
		setShowAddEquipModal(true);
	};

	const onOpenAddEquipmentClose = () => {
		setShowAddEquipModal(false);
	};

	const addEquipmentModalContent = () => {
		const title = 'ADICIONAR EQUIPAMENTO';
		const onClose = onOpenAddEquipmentClose;

		return (
			<TransfersContext>
				<Modal show={showAddEquipModal} title={title} defaultTitle={title} onClose={onClose}>
					<EquipmentTransferModal onClose={onClose} />
				</Modal>
			</TransfersContext>
		);
	};

	const headerOptionsContent = () => {
		return (
			<div className="equips-main-more-options">
				<Button className="addEquip-icon" aria-controls="simple-menu" aria-haspopup="true" onClick={onOpenAddEquipmentClick}>
					<AddEquipIcon />
				</Button>
			</div>
		);
	};

	const headerContent = () => {
		const searchOptions: SearchOptions = {
			onChangeValues: onSearchEquip,
			placeHolder: 'Procurar equipamento',
		};

		const paginatorOptions: PaginatorOptions = {
			limit: pageLimit,
			onChangeLimit: onLimitChange,
		};

		const buttonOptions: ButtonOptions = {
			onClick: onOpenAddEquipmentClick,
			icon: <AddEquipIcon />,
		};

		return (
			<section className="equips-header-content">
				<PageListOptions {...{ searchOptions, paginatorOptions, buttonOptions }} />
			</section>
		);
	};

	const paginatorContent = <Paginator selected={currentPage} total={totalPages} isLoading={isLoading} onPageChange={onPageChange} />;

	const mainContent = () => {
		return client ? (
			<section className="equips-main-content">
				{headerContent()}
				<EquipmentsList {...{ onItemClick, onOpenClick, paginatorContent }} />
			</section>
		) : (
			<EmptyUser />
		);
	};

	return (
		<>
			<PageTitle title="Equipamentos" />
			{mainContent()}
			{addEquipmentModalContent()}
			{showOpenServiceModalContent()}
		</>
	);
};

export default Equipments;
