/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { RequisitionError } from 'models/RequisitionError';
import UpdateInfo from 'models/UpdateInfo';
import { createContext } from 'react';

export interface VersionContextType {
	newVersion: boolean;
	onUpdateClick: () => void;
	onUpdateVersion: (updateInfo: UpdateInfo, callback: (hasError?: RequisitionError) => void) => void;
}

export const VersionContextElement = createContext<VersionContextType>({
	newVersion: {} as boolean,
	onUpdateClick: (): void => {},
	onUpdateVersion: (): void => {},
});
