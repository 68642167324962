/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React, { useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

interface Params {
	register: any;
	placeholder: string;
}

const PasswordField: React.FC<Params> = ({ register, placeholder }) => {
	const [showPass, setShowPass] = useState<boolean>(false);

	const handleFieldType = () => (showPass ? 'text' : 'password');

	const showPassToggle = () => {
		setShowPass(!showPass);
	};

	const showPassToggleContent = () => <div onClick={showPassToggle}>{!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}</div>;

	return (
		<div className="toggle-input">
			<input {...{ ...register, placeholder }} maxLength={20} type={handleFieldType()} />
			{showPassToggleContent()}
		</div>
	);
};

export default PasswordField;
