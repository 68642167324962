/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EmptyList from 'components/Shared/EmptyList';
import HasError from 'components/Shared/HasError';
import LoadingProgress from 'components/Shared/LoadingProgress';
import Paginator from 'components/Shared/Paginator';
import { EquipmentsHistoryContextElement } from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContext';
import { EquipmentsHistoryContextType } from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContextType';
import { FilterData } from 'models/FilterData';
import ServiceOrder from 'models/ServiceOrder';
import React, { useContext, useState } from 'react';
import { onChildrenScroll } from 'utils/Screen';
import EquipmentServiceOrdersListItem from '../ServiceOrdersListItem';
import './styles.scss';

const SCROLL_TOP = 0;

interface Params {
	onItemClick: (serviceOrder: ServiceOrder) => void;
	onCancelServiceOrderClick: (serviceOrder: ServiceOrder) => void;
}

const EquipmentServiceOrdersList: React.FC<Params> = ({ onItemClick, onCancelServiceOrderClick }) => {
	const { serviceOrders, isLoading, hasError, ordersFilter, currentPage, totalPages, onPageChange, onFilterChange } = useContext<EquipmentsHistoryContextType>(
		EquipmentsHistoryContextElement,
	);

	const [statusOrder, setStatusOrder] = useState(ordersFilter?.statusOrder || 'asc');
	const [createdOrder, setCreatedOrder] = useState(ordersFilter?.createdOrder || 'desc');

	$('.mobile-scroll').scrollTop(SCROLL_TOP);
	$('.equip-orders-list-items').scrollTop(SCROLL_TOP);

	const onOrderChange = (type: string, order: 'desc' | 'asc') => {
		const newFilter = ordersFilter ? { ...ordersFilter } : ({} as FilterData);

		if (type === 'created') {
			newFilter.createdOrder = order;
			newFilter.statusOrder = statusOrder;
		} else {
			newFilter.statusOrder = order;
			newFilter.createdOrder = createdOrder;
		}

		setStatusOrder(newFilter.statusOrder);
		setCreatedOrder(newFilter.createdOrder);
		onFilterChange(newFilter);
	};

	const arrowCreateContent = () => {
		return createdOrder === 'desc' ? (
			<ArrowUpwardIcon onClick={() => onOrderChange('created', 'asc')} />
		) : (
			<ArrowDownwardIcon onClick={() => onOrderChange('created', 'desc')} />
		);
	};

	const arrowStatusContent = () => {
		return statusOrder !== 'asc' ? <ArrowUpwardIcon onClick={() => onOrderChange('status', 'asc')} /> : <ArrowDownwardIcon onClick={() => onOrderChange('status', 'desc')} />;
	};

	const headerContent = () => {
		return (
			<div className="equip-orders-list-header">
				<div className="list-header protocol">
					<span>Protocolo</span>
				</div>
				<div className="list-header created">
					<span>Data da solicitação</span>
					{arrowCreateContent()}
				</div>
				<div className="list-header tech">
					<span>Técnico</span>
				</div>
				<div className="list-header status">
					<span>Status</span>
					{arrowStatusContent()}
				</div>
				<div className="list-header options"></div>
			</div>
		);
	};

	const listContent = () => {
		const listItems = serviceOrders.map((serviceOrder: ServiceOrder, index: number) => (
			<EquipmentServiceOrdersListItem {...{ serviceOrder, onItemClick, onCancelServiceOrderClick }} key={index} />
		));

		return (
			<>
				<div
					className="equip-orders-list-items"
					onScroll={e => {
						onChildrenScroll(e, 'equip-orders-list-content');
					}}
				>
					{listItems}
				</div>
				<Paginator selected={currentPage} total={totalPages} isLoading={isLoading} onPageChange={onPageChange} />
			</>
		);
	};

	const handleMainContent = () => {
		if (isLoading) {
			return <LoadingProgress show text="Buscando atendimentos..." />;
		} else if (hasError) {
			return <HasError show />;
		} else if (!hasError && !isLoading && !serviceOrders.length) {
			return <EmptyList show text="Sem atendimentos por aqui" />;
		} else {
			return listContent();
		}
	};

	return (
		<section className="equip-orders-list-content">
			{headerContent()}
			{handleMainContent()}
		</section>
	);
};

export default EquipmentServiceOrdersList;
