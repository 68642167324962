/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import TechnicalAssists from 'models/TechnicalAssists';
import Technician from 'models/Technician';
import moment from 'moment';
import React from 'react';
import { StepTypes } from 'utils/constants';
import TimelineFiles from '../TimelineFiles';
import ClosedMessage from './ClosedMessage';
import CreatedMessage from './CreatedMessage';
import FollowUpMessage from './FollowUpMessage';
import InvoiceMessage from './InvoiceMessage';
import OrderMessage from './OrderMessage';
import QuoteMessage from './QuoteMessage';
import ServiceOrderCancelRequest from './ServiceOrderCancelRequest';
import VisitCompletedFieldControl from './VisitCompletedFieldControl';
import { VisitEvaluation } from './VisitEvaluation';
import VisitInProgressFieldControl from './VisitInProgressFieldControl';
import VisitMessage from './VisitMessage';
import VisitPreventedFieldControl from './VisitPreventedFieldControl';
import './styles.scss';

interface Params {
	techAssist: TechnicalAssists;
	technician?: Technician;
}

const TimelineDescription: React.FC<Params> = ({ techAssist, technician }) => {
	const stepType = techAssist.step_type;
	const order = techAssist.orderQuotation;
	const dateCloseService = techAssist.closing_date;
	const appointmentDate = techAssist.appointment_date;
	const datePartShipping = techAssist.part_shipping_date;
	const message = JSON.parse(techAssist?.message);

	const messageContent = () => {
		if (dateCloseService) {
			return <ClosedMessage {...{ techAssist }} />;
		} else if (technician && appointmentDate) {
			return <VisitMessage {...{ techAssist }} />;
		} else if (datePartShipping) {
			return <InvoiceMessage {...{ techAssist }} />;
		} else if (stepType === StepTypes.FOLLOW_UP) {
			return <FollowUpMessage {...{ techAssist }} />;
		} else if (stepType === StepTypes.IN_PROGRESS_OS_FIELD_CONTROL) {
			return <VisitInProgressFieldControl {...{ techAssist }} />;
		} else if (stepType === StepTypes.COMPLETED_OS_FIELD_CONTROL) {
			return <VisitCompletedFieldControl {...{ techAssist }} />;
		} else if (stepType === StepTypes.REPORTED_OS_FIELD_CONTROL) {
			return <VisitPreventedFieldControl />;
		} else if (stepType === StepTypes.CANCEL_REQUEST) {
			return <ServiceOrderCancelRequest {...{ techAssist }} />;
		} else if (stepType === StepTypes.QUOTE) {
			return <QuoteMessage {...{ techAssist }} />;
		} else if (stepType === StepTypes.ORDER) {
			return <OrderMessage {...{ techAssist }} />;
		} else if (stepType === StepTypes.INVOICE && order?.code_protheus?.trim() !== undefined) {
			return <></>;
		} else {
			return <CreatedMessage />;
		}
	};

	const showTechAssistFiles = () => {
		return (
			<>
				{!!techAssist.tech_assistance_files.length && <TimelineFiles techAssistFiles={techAssist.tech_assistance_files} />}
				{!!techAssist.files.length && <TimelineFiles techAssistFiles={techAssist.files} />}
			</>
		);
	};

	return (
		<div className="service-order-timeline-description-content">
			<div className="date-content">
				<span>{moment(techAssist.created_at).format('DD/MM/YYYY')}</span>
			</div>
			<div className="description-content">{messageContent()}</div>
			{showTechAssistFiles()}
			{!!message?.ratingLink && <VisitEvaluation techAssist={techAssist} />}
		</div>
	);
};

export default TimelineDescription;
