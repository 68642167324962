/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import TechnicalAssists from 'models/TechnicalAssists';
import React from 'react';

interface Params {
	techAssist: TechnicalAssists;
}

export const VisitEvaluation: React.FC<Params> = ({ techAssist }) => {
	const message = JSON.parse(techAssist?.message);

	const messageContent = () => {
		return message.ratingLink ? (
			<div style={{ fontSize: '13px' }}>
				<div>
					<span>{`${message.ratingMessage}`}</span>
				</div>
				<a href={message.ratingLink} target="_blank" rel="noopener noreferrer">
					{message.ratingLink}
				</a>
			</div>
		) : (
			<></>
		);
	};

	return (
		<div className="visit-description">
			<span>{messageContent()}</span>
		</div>
	);
};
