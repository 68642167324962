/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

const AddEquipIcon: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="50" height="40" viewBox="0 0 50 40" className="addEquip-icon">
			<g id="add-equip" transform="translate(-337 -463)">
				<rect id="Retângulo_1458" data-name="Retângulo 1458" width="50" height="40" transform="translate(337 463)" fill="none" />
				<g id="icon" transform="translate(61.449 -3)">
					<path
						id="oven"
						d="M-1147-5974h-6v-.152a7.484,7.484,0,0,0,3.6-1.847h18.4a4,4,0,0,0,4-4v-14h-30v5.426a7.584,7.584,0,0,0-1,.438V-5996a4,4,0,0,1,4-4h24a4,4,0,0,1,4,4v17a4,4,0,0,1-4,4h-1v1h-6v-1h-10v1Zm18-16h-26a1,1,0,0,1-1-1,1,1,0,0,1,1-1h26a1,1,0,0,1,1,1A1,1,0,0,1-1129-5990Z"
						transform="translate(1444.551 6473)"
					/>
					<path
						id="circle"
						d="M-1155.5-5987a6.508,6.508,0,0,1-6.5-6.5,6.507,6.507,0,0,1,6.5-6.5,6.506,6.506,0,0,1,6.5,6.5A6.507,6.507,0,0,1-1155.5-5987Zm0-12.135a5.64,5.64,0,0,0-5.634,5.634,5.641,5.641,0,0,0,5.634,5.634,5.64,5.64,0,0,0,5.632-5.634A5.64,5.64,0,0,0-1155.5-5999.134Z"
						transform="translate(1445.551 6485)"
					/>
					<path
						id="add"
						d="M-1158.889-5992.888v-2.223h-2.223A.888.888,0,0,1-1162-5996a.889.889,0,0,1,.888-.89h2.223v-2.223A.888.888,0,0,1-1158-6000a.889.889,0,0,1,.89.888v2.223h2.221a.891.891,0,0,1,.89.89.889.889,0,0,1-.89.889h-2.221v2.223a.889.889,0,0,1-.89.888A.888.888,0,0,1-1158.889-5992.888Z"
						transform="translate(1448.051 6487.501)"
					/>
				</g>
			</g>
		</svg>
	);
};

export default AddEquipIcon;
