/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { FormControl, FormGroup } from '@material-ui/core';
import { CustomSwitch } from 'components/Shared/CustomSwitch';
import LoadingModal from 'components/Shared/LoadingModal';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { RequisitionError } from 'models/RequisitionError';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { store } from 'store';
import CustomButton from '../../../Shared/CustomButton';
import './styles.scss';

interface Params {
	onClose: () => void;
}

const AppSettings: React.FC<Params> = ({ onClose }) => {
	const { onUpdateSettings } = useContext<DeviceContextType>(DeviceContextElement);

	const userAppConfig = store.getState().data.user.appConfigs;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [darkTheme, setDarkTheme] = useState<boolean>(userAppConfig.theme);
	const [enableNotifications, setEnableNotifications] = useState<boolean>(userAppConfig.receiveNotifications);

	const submit = () => {
		setIsLoading(true);
		const newAppSettings = { ...userAppConfig };
		newAppSettings.theme = darkTheme ? 1 : 0;
		newAppSettings.receiveNotifications = enableNotifications ? 1 : 0;
		onUpdateSettings(newAppSettings, onCompleted);
	};

	const onCompleted = (hasError?: RequisitionError) => {
		setIsLoading(false);
		if (hasError) {
			setDarkTheme(!darkTheme);
			setEnableNotifications(!enableNotifications);
			toast.error('Erro ao alterar configurações!');
		} else {
			toast.success('Configurações alteradas com sucesso!');
			onClose();
		}
	};

	const handleDarkThemeToggle = () => {
		setDarkTheme(!darkTheme);
	};

	const handleEnableNotificationsToggle = () => {
		setEnableNotifications(!enableNotifications);
	};

	return (
		<section className="app-settings-main-content">
			<FormGroup>
				<div className="app-settings-item">
					<label>Modo escuro</label>
					<FormControl>
						<CustomSwitch checked={darkTheme} onChange={handleDarkThemeToggle} name="app-theme-toggle" />
					</FormControl>
				</div>
				<div className="app-settings-item">
					<label>Receber notificações</label>
					<FormControl>
						<CustomSwitch checked={enableNotifications} onChange={handleEnableNotificationsToggle} name="notifications-toggle" />
					</FormControl>
				</div>
			</FormGroup>
			<div className="app-settings-actions">
				<CustomButton context="primary" type="button" label="SALVAR" onClick={() => submit()} />
				<CustomButton context="secondary" label="CANCELAR" onClick={() => onClose()} />
			</div>
			<LoadingModal show={isLoading} title="Aplicando configurações" text="Carregando..." />
		</section>
	);
};

export default AppSettings;
