/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import TechnicalAssists from 'models/TechnicalAssists';
import React from 'react';

interface Params {
	techAssist: TechnicalAssists;
}

const ServiceOrderCancelRequest: React.FC<Params> = ({ techAssist }) => {
	const ServiceOrderCancelRequestMessageContent = () => {
		if (techAssist.message) {
			return (
				<>
					<span>
						<b>Solicitação de cancelamento recebida! </b>
					</span>
					<span>Em breve um dos nossos atendentes avaliará a solicitação e sequenciará o fechamento da ordem de serviço.</span>
				</>
			);
		} else {
			return <></>;
		}
	};

	return <div className="default-description">{ServiceOrderCancelRequestMessageContent()}</div>;
};

export default ServiceOrderCancelRequest;
