/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { ClickAwayListener } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface Params {
	title: string;
	children: any;
	secondaryClass?: string;
	hideClose?: boolean;
	hideTitle?: boolean;
	onClose: () => void;
}

const WebResponseModal: React.FC<Params> = ({ title, children, secondaryClass, hideClose, hideTitle, onClose }) => {
	const headerAction = () => {
		return hideClose ? (
			<></>
		) : (
			<div
				className="modal-header-close-content"
				onClick={() => {
					onClose();
				}}
			>
				<CloseIcon />
			</div>
		);
	};

	const modalTitle = () => {
		return hideTitle ? <></> : <span>{title}</span>;
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<div className={`response-modal-web ${secondaryClass}`}>
				<div className="modal-header-content">
					{modalTitle()}
					{headerAction()}
				</div>
				<div className="modal-main-content">{children}</div>
			</div>
		</ClickAwayListener>
	);
};

export default WebResponseModal;
