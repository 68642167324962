/**
 * Developed by: Inatel Competence Center
 * Copyright 2019, Prática
 * Author: Lucas Marciano
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import 'font-awesome/css/font-awesome.css';
import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';

/**
 * Global component for the all application.
 */
const GlobalStyle = createGlobalStyle`
	body {        
        font-family: Roboto, sans-serif;        
        font-size: 14px;        
        margin: 0;
        min-height: 100%;
    }
`;

export default GlobalStyle;
