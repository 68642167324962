/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import CreateAccountForm from 'components/Account/CreateAccount';
import PageTitle from 'components/Shared/PageTitle';
import PraticaImage from 'components/Shared/PraticaImage';
import SideBar from 'components/Shared/SideBar';
import AuthContext from 'contexts/AuthContext/AuthContext';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import './styles.scss';

type TParams = {
	history: string;
	location: any;
	match: any;
};

const CreateAccount: React.FC<RouteComponentProps<TParams>> = () => {
	return (
		<>
			<PageTitle title="Criação de conta" single />
			<section className="create-account-main-content" id="accounts-forms">
				<SideBar>
					<AuthContext>
						<CreateAccountForm />
					</AuthContext>
				</SideBar>
				<PraticaImage />
			</section>
		</>
	);
};

export default CreateAccount;
