/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ModalTutorial from 'components/Shared/Tutorial/ModalTutorial';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import LastNotificationsContext from 'contexts/LastNotificationsContext/LastNotificationsContext';
import NotificationsContext from 'contexts/NotificationsContext/NotificationsContext';
import { RouterContextElement } from 'contexts/RouterContext/RouterContext';
import { RouterContextType } from 'contexts/RouterContext/RouterContextType';
import React, { useContext } from 'react';
import MainPageSelector from './MainPageSelector';
import MobileMenu from './Mobile';
import WebMenu from './Web';

const MainMenu: React.FC = ({ children }) => {
	const { mainTheme } = useContext<DeviceContextType>(DeviceContextElement);
	const { isRoot } = useContext<RouterContextType>(RouterContextElement);

	return (
		<section className={`main-page-content ${mainTheme}`}>
			<LastNotificationsContext>
				<WebMenu />
			</LastNotificationsContext>
			<MainPageSelector />
			<div className={`main-page-component ${isRoot ? 'root-page' : ''}`}>{children}</div>
			<NotificationsContext>
				<MobileMenu />
			</NotificationsContext>
			<ModalTutorial />
		</section>
	);
};

export default MainMenu;
