/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { ClickAwayListener } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface Params {
	show: boolean;
	title: string;
	showClose?: boolean;
	awayClose?: boolean;
	onClose: () => void;
}

const ModalWeb: React.FC<Params> = ({ show, title, showClose, awayClose, onClose, children }) => {
	const showCloseButton = () =>
		!showClose ? (
			<></>
		) : (
			<div className="modal-header-close-content" onClick={onClose}>
				<CloseIcon />
			</div>
		);

	const mainChildren = () => (
		<div className="modal-component">
			<div className="modal-header-content">
				<span>{title}</span>
				{showCloseButton()}
			</div>
			<div className="modal-main-content">{children}</div>
		</div>
	);

	const handleAwayContent = () => {
		return awayClose ? (
			<ClickAwayListener onClickAway={onClose} mouseEvent="onMouseUp">
				{mainChildren()}
			</ClickAwayListener>
		) : (
			mainChildren()
		);
	};

	const mainContent = () => <div className="modal-main-backdrop">{handleAwayContent()}</div>;

	return show ? mainContent() : <></>;
};

export default ModalWeb;
