/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import './styles.scss';

interface Params {
	phone?: string;
}

const WhatsAppCaller: React.FC<Params> = ({ phone }) => {
	const whatsAppClick = () => {
		const whatsAppPhone = phone?.replace(/\D/g, '');
		if (whatsAppPhone) {
			window.open(`https://api.whatsapp.com/send?phone=${encodeURIComponent(`+55${whatsAppPhone}`)}`);
		}
	};

	const whatsAppContent = () => {
		return (
			<div
				className="whats-app-button"
				onClick={() => {
					whatsAppClick();
				}}
			>
				<WhatsAppIcon />
				<span>Enviar</span>
			</div>
		);
	};

	return phone ? whatsAppContent() : <></>;
};

export default WhatsAppCaller;
