/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import Files from 'models/OsFiles';
import React, { useState } from 'react';
import Modal from 'components/Shared/Modal';
import { firstEl, StepTypes } from 'utils/constants';
import FilesCenter from 'components/Shared/FilesCenter';
import { downloadFile, downloadItemQuote } from 'utils/Download';

import './styles.scss';

interface Params {
	techAssistFiles: Files[];
}

const TimelineFiles: React.FC<Params> = ({ techAssistFiles }) => {
	const [showFilesModal, setShowFilesModal] = useState<boolean>(false);

	const onItemClick = (file: Files) => {
		const type = file.tech_assist_type;
		switch (type) {
			case StepTypes.ORDER:
			case StepTypes.QUOTE:
			case StepTypes.INVOICE:
				downloadItemQuote(file);
				break;
			default:
				downloadFile(file);
				break;
		}
	};

	const onClose = () => {
		setShowFilesModal(false);
	};

	const listFilesModalContent = () => {
		return (
			<Modal title="Anexos do Atendimento" defaultTitle="Anexos do Atendimento" onClose={onClose} show={showFilesModal} showClose={true}>
				<FilesCenter {...{ techAssistFiles, onClose }} />
			</Modal>
		);
	};

	const showListFilesContent = () => {
		return showFilesModal ? listFilesModalContent() : <></>;
	};

	const openModalFileContent = () => {
		return (
			<>
				<div className="service-order-timeline-file-link">
					<div
						onClick={() => {
							setShowFilesModal(true);
						}}
					>
						<span>Anexos</span>
					</div>
				</div>
				{showListFilesContent()}
			</>
		);
	};

	const openSingleFileContent = () => {
		return (
			<div className="service-order-timeline-file-link">
				<div
					onClick={() => {
						onItemClick(firstEl(techAssistFiles));
					}}
				>
					<span>Anexo</span>
				</div>
			</div>
		);
	};

	return techAssistFiles.length > 1 ? openModalFileContent() : openSingleFileContent();
};

export default TimelineFiles;
