/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { store } from 'store';
import { toast } from 'react-toastify';
import { RequisitionError } from 'models/RequisitionError';
import { FormControl, FormGroup } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';

import './styles.scss';
import LoadingModal from 'components/Shared/LoadingModal';
import { CustomSwitch } from 'components/Shared/CustomSwitch';

const MobileAppSettings: React.FC = () => {
	const { onUpdateSettings } = useContext<DeviceContextType>(DeviceContextElement);

	const userAppConfig = store.getState().data.user.appConfigs;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [darkTheme, setDarkTheme] = useState<boolean>(userAppConfig.theme);
	const [enableNotifications, setEnableNotifications] = useState<boolean>(userAppConfig.receiveNotifications);

	useEffect(() => {
		if (darkTheme !== userAppConfig.theme || enableNotifications !== userAppConfig.receiveNotifications) {
			onChanges();
		}
	}, [darkTheme, enableNotifications]);

	const onChanges = () => {
		setIsLoading(true);
		const newAppSettings = { ...userAppConfig };
		newAppSettings.theme = darkTheme;
		newAppSettings.receiveNotifications = enableNotifications;
		onUpdateSettings(newAppSettings, onCompleted);
	};

	const onCompleted = (hasError?: RequisitionError) => {
		setIsLoading(false);
		if (hasError) {
			setDarkTheme(!darkTheme);
			setEnableNotifications(!enableNotifications);
			toast.success('Erro ao alterar configurações!');
		} else {
			toast.success('Configurações alteradas com sucesso!');
		}
	};

	const handleDarkThemeToggle = () => {
		setDarkTheme(!darkTheme);
	};

	const handleEnableNotificationsToggle = () => {
		setEnableNotifications(!enableNotifications);
	};

	return (
		<section className="mobile-app-settings-main-content">
			<FormGroup>
				<div className="mobile-app-settings-item">
					<label>Modo escuro</label>
					<FormControl>
						<CustomSwitch checked={darkTheme} onChange={handleDarkThemeToggle} />
					</FormControl>
				</div>
				<div className="mobile-app-settings-item">
					<label>Notificações</label>
					<FormControl>
						<CustomSwitch checked={enableNotifications} onChange={handleEnableNotificationsToggle} name="notifications-toggle" />
					</FormControl>
				</div>
			</FormGroup>
			<LoadingModal show={isLoading} title="Aplicando configurações" text="Carregando..." />
		</section>
	);
};

export default MobileAppSettings;
