/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

const BriefcaseIcon: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="29" height="24.408" viewBox="0 0 29 24.408" className="briefcase-icon">
			<path
				id="Icon_awesome-briefcase"
				data-name="Icon awesome-briefcase"
				d="M18.125,19.5a.906.906,0,0,1-.906.906H11.781a.906.906,0,0,1-.906-.906V16.783H0V24.94a2.788,2.788,0,0,0,2.719,2.719H26.281A2.788,2.788,0,0,0,29,24.94V16.783H18.125ZM26.281,7.721H21.75V5.969A2.788,2.788,0,0,0,19.031,3.25H9.969A2.788,2.788,0,0,0,7.25,5.969V7.721H2.719A2.788,2.788,0,0,0,0,10.44V15.3H29V10.44A2.788,2.788,0,0,0,26.281,7.721Zm-15.406-.73h0Z"
				transform="translate(0 -3.25)"
			/>
		</svg>
	);
};

export default BriefcaseIcon;
