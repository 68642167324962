/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React, { createContext, useState } from 'react';
import { RequisitionError } from 'models/RequisitionError';
import { ServiceOrderFeedbackAnswers } from 'models/ServiceOrderFeedback';
import { ServiceOrderFeedbackContextType } from './ServiceOrderFeedbackContextType';
import { createNewServiceOrderFeedback, getAppOsFeedbackByServiceOrderId } from 'services';

export const ServiceOrderFeedbackContextElement = createContext<ServiceOrderFeedbackContextType>({
	serviceOrderFeedback: {} as ServiceOrderFeedbackAnswers,
	isLoading: {} as boolean,
	hasError: {} as RequisitionError,
	onServiceOrderChange: () => {},
	createServiceOrderFeedback: () => {},
});

const ServiceOrderFeedbackProvider = (props: any) => {
	const [serviceOrderFeedback, setServiceOrderFeedback] = useState<ServiceOrderFeedbackAnswers>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<RequisitionError>();

	const onServiceOrderChange = (serviceOrderId: number) => {
		setLoading(true);
		getAppOsFeedbackByServiceOrderId(serviceOrderId)
			.then(res => {
				const { feedback } = res.data;
				setServiceOrderFeedback(feedback);
				setLoading(false);
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				setHasError(reportError);
				setLoading(false);
			});
	};

	const createServiceOrderFeedback = (feedback: ServiceOrderFeedbackAnswers, callback: (res: any, err?: RequisitionError) => void) => {
		createNewServiceOrderFeedback(feedback)
			.then(res => {
				const { feedback } = res.data;
				callback(feedback);
			})
			.catch(err => {
				const reportError = {
					message: err.message,
					code: err.statusCode,
					error: err.request,
				};
				callback(null, reportError);
			});
	};

	const providerValues = () => {
		return {
			isLoading,
			hasError,
			serviceOrderFeedback,
			createServiceOrderFeedback,
			onServiceOrderChange,
		};
	};

	return <ServiceOrderFeedbackContextElement.Provider value={providerValues()}>{props.children}</ServiceOrderFeedbackContextElement.Provider>;
};

export default ServiceOrderFeedbackProvider;
