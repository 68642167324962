/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import { ServiceOrderStatusTypes } from 'utils/constants';
import ServiceOrderStatus from '../ServiceOrderStatus';

import './styles.scss';

const ServiceOrderLabel: React.FC = () => {
	return (
		<div className="orders-status-label">
			<div className="status-label">
				<ServiceOrderStatus status={ServiceOrderStatusTypes.PROGRESS} />
				<span>Em andamento</span>
			</div>
			<div className="status-label">
				<ServiceOrderStatus status={ServiceOrderStatusTypes.CLOSED} />
				<span>Concluída</span>
			</div>
		</div>
	);
};

export default ServiceOrderLabel;
