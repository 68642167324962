/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import TechnicalAssists from 'models/TechnicalAssists';
import React from 'react';
import { phoneMask } from 'utils/pipe';

interface Params {
	techAssist: TechnicalAssists;
}

const VisitInProgressFieldControl: React.FC<Params> = ({ techAssist }) => {
	const technician = techAssist?.technician;
	const techPhones = () => {
		const defaultPhone = technician?.phone || technician?.phone1;
		if (technician?.phone && technician?.phone1) {
			return <a>{`contatos: ${phoneMask(technician?.phone)} / ${phoneMask(technician?.phone1)}`}</a>;
		} else if (defaultPhone) {
			return <a> {`contato: ${phoneMask(defaultPhone)}`}</a>;
		}
	};

	return (
		<div className="visit-description">
			<span>
				<b>Atendimento em progresso</b>
			</span>
			<span>
				{`Atendimento inicializado por ${technician?.name} `}
				{techPhones()}
			</span>
		</div>
	);
};

export default VisitInProgressFieldControl;
