/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import './styles.scss';

interface Params {
	placeHolder: string;
	onChangeValues: (value: string) => void;
}

const SearchInput: React.FC<Params> = ({ placeHolder, onChangeValues }) => {
	let timer = 0;
	const onSearch = async (value: string) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			onChangeValues(value.trim());
			clearTimeout(timer);
		}, 600);
	};

	const onFocus = () => {
		const input = $('.input-search-field');
		input.addClass('focused');
	};

	const onBlur = () => {
		const input = $('.input-search-field');
		input.removeClass('focused');
	};

	return (
		<FormControl className="input-search-field">
			<OutlinedInput
				id="input-with-icon-adornment"
				placeholder={placeHolder}
				onChange={e => {
					onSearch(e.target.value);
				}}
				onFocus={onFocus}
				onBlur={onBlur}
				startAdornment={
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				}
			/>
		</FormControl>
	);
};

export default SearchInput;
