import React, { useContext } from 'react';
import { DeviceContextElement } from '../../../../contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from '../../../../contexts/DeviceContext/DeviceContextType';
import { DeviceType } from '../../../../utils/constants';
import './styles.scss';

const ModalTutorialVideo: React.FC = () => {
	const { deviceType } = useContext<DeviceContextType>(DeviceContextElement);

	const mainContent = () => {
		return deviceType === DeviceType.MOBILE ? (
			<div className="modal-mobile-main-content-tutorial">
				<video
					className="modal-video-style"
					src="https://praticasharedfiles.s3.sa-east-1.amazonaws.com/tutorials/app_pratica/Tutorial-AppCliente-Mobile_270122.mp4"
					controls
					autoPlay
					disablePictureInPicture
				/>
			</div>
		) : (
			<div className="modal-main-content-tutorial">
				<video
					className="modal-video-style"
					src="https://praticasharedfiles.s3.sa-east-1.amazonaws.com/tutorials/app_pratica/Tutorial-AppCliente_260122.mp4"
					controls
					autoPlay
					disablePictureInPicture
				/>
			</div>
		);
	};

	return mainContent();
};

export default ModalTutorialVideo;
