/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import PageListOptions from 'components/Shared/PageListOptions';
import { StoreSettingsContextElement } from 'contexts/StoreSettingsContext/StoreSettingsContext';
import { StoreSettingsContextType } from 'contexts/StoreSettingsContext/StoreSettingsContextType';
import { PaginatorOptions, SearchOptions } from 'models/PageListOptions';
import React, { useContext } from 'react';
import './styles.scss';

const WebStoresSettingsHeader: React.FC = () => {
	const { showAll, pageLimit, onSearchStores, onLimitChange, onViewChange } = useContext<StoreSettingsContextType>(StoreSettingsContextElement);

	const listOptionsContent = () => {
		const paginatorOptions: PaginatorOptions = {
			limit: pageLimit,
			onChangeLimit: onLimitChange,
		};

		const searchOptions: SearchOptions = {
			onChangeValues: onSearchStores,
			placeHolder: 'Procurar loja',
		};

		return <PageListOptions {...{ paginatorOptions, searchOptions }} />;
	};

	return (
		<section className="stores-settings-header-content">
			<div className="title-page-area">
				<h4>Configurações de Lojas</h4>
			</div>
			<div className="stores-view-type">
				<FormControl component="fieldset">
					<RadioGroup aria-label="address-selector" name="address-selector" value={showAll} onChange={onViewChange}>
						<FormControlLabel value={false} control={<Radio />} label="Minhas Lojas" />
						<FormControlLabel value={true} control={<Radio />} label="Todas Lojas" />
					</RadioGroup>
				</FormControl>
			</div>
			{listOptionsContent()}
		</section>
	);
};

export default WebStoresSettingsHeader;
