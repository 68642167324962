/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import { NO_OPTION, YES_OPTION } from 'utils/constants';

interface Params {
	register: any;
	formName: string;
	onChange: (value: string, field: string) => void;
}

const OptionsField: React.FC<Params> = ({ register, formName, onChange }) => {
	return (
		<div className="form-item-option-content">
			<FormControl component="fieldset">
				<RadioGroup aria-label="service-rating" {...register(formName)} onChange={(e, value) => onChange(value, formName)}>
					<FormControlLabel value={YES_OPTION} control={<Radio />} label="Sim" />
					<FormControlLabel value={NO_OPTION} control={<Radio />} label="Não" />
				</RadioGroup>
			</FormControl>
		</div>
	);
};

export default OptionsField;
