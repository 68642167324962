/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import FormErrorIcon from '../FormErrorIcon';
import PasswordField from '../PasswordField';
import './styles.scss';

interface Params {
	register: any;
	placeholder: string;
	errors: any;
	isDirty: boolean;
	formName: string;
	validatorName?: string;
	emailConflict?: boolean;
	disabled?: boolean;
	password?: boolean;
	value?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onClearError: (formName: string) => void;
	onValidatorClick?: (formName: string) => void;
}

const CustomInput: React.FC<Params> = ({
	register,
	placeholder,
	errors,
	isDirty,
	formName,
	validatorName,
	emailConflict,
	password,
	disabled,
	value,
	onChange,
	onClearError,
	onValidatorClick,
}) => {
	const handleValidatorContent = () => {
		const formError = errors[formName];
		if (formError && isDirty) {
			switch (formError.type) {
				case 'required':
					return (
						<div className="custom-form-item-content-validator">
							<span>Preenchimento obrigatório</span>
						</div>
					);
				case 'maxLength':
					return (
						<div className="form-item-content-validator">
							<span>* Limite de máximo de caracteres atingido!</span>
						</div>
					);
				case 'minLength':
					return (
						<div className="form-item-content-validator">
							<span>* Limite de minimo de caracteres atingido!</span>
						</div>
					);
				case 'pattern':
					const patternMessage = validatorName ? `* O campo ${validatorName} não atende o formato correto!` : `* O Campo acima está fora do padrão!`;
					return (
						<div className="form-item-content-validator">
							<span>{patternMessage}</span>
						</div>
					);
				default:
					const defaultMessage = validatorName ? `* Verifique o campo ${validatorName}!` : `* Verifique o campo acima!`;
					return (
						<div className="form-item-content-validator">
							<span>{defaultMessage}</span>
						</div>
					);
			}
		} else if (emailConflict) {
			return (
				<div className="form-item-content-validator email-input">
					<span>E-mail já cadastrado</span>
					<span
						className="validator-link"
						onClick={() => {
							onValidatorClick('email');
						}}
					>
						Redefinir Senha
					</span>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const formItemClass = () => {
		const formError = errors[formName];
		const passwordCustomClass = formError && isDirty && formError.type === 'required' && isPassField(formName) ? 'required-pass' : '';
		if ((formError && isDirty) || emailConflict) {
			return `custom-form-item-input input-has-error ${formName} ${passwordCustomClass}`;
		} else {
			return 'custom-form-item-input';
		}
	};

	const isPassField = (formName: string) => formName === 'newPassword' || formName === 'confirmPassword' || formName === 'password';

	const onFormClick = (event: any) => {
		event.stopPropagation();
		const formError = errors[formName];
		if ((formError && isDirty) || emailConflict) {
			onClearError(formName);
		}
	};

	const handleErrorIcon = () => {
		const formError = errors[formName];
		return (formError && isDirty) || emailConflict ? <FormErrorIcon /> : <></>;
	};

	const handleInputContent = () => {
		return password ? <PasswordField {...{ register, placeholder }} /> : <input {...{ ...register, placeholder, disabled, onChange, value }} />;
	};

	return (
		<div className="custom-form-item-content" onClick={onFormClick}>
			<div className={formItemClass()}>
				{handleInputContent()}
				{handleErrorIcon()}
			</div>
			{handleValidatorContent()}
		</div>
	);
};

export default CustomInput;
