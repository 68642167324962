/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import TechnicalAssists from 'models/TechnicalAssists';

interface Params {
	techAssist: TechnicalAssists;
}

const InvoiceMessage: React.FC<Params> = ({ techAssist }) => {
	const order = techAssist.orderQuotation;

	const orderInfoContent = () => {
		if (order) {
			const message = `Solicitação de peças de número ${order.code_protheus} foi realizada! ` + `Em breve você receberá mais atualizações sobre o atendimento.`;
			return <span>{message}</span>;
		} else {
			return <></>;
		}
	};

	return (
		<div className="default-description">
			<span>
				<b>Solicitação de peças</b>
			</span>
			{orderInfoContent()}
		</div>
	);
};

export default InvoiceMessage;
