/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React, { useState } from 'react';
import { firstEl } from 'utils/constants';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneNumbersList from './PhoneNumbersList';

import './styles.scss';

interface Params {
	phones: string[];
}

const PhoneCaller: React.FC<Params> = ({ phones }) => {
	const [showList, setShowList] = useState<boolean>(false);

	const phoneCallClick = () => {
		if (phones.length === 1) {
			const phoneNumber = firstEl(phones);
			phoneCallAction(phoneNumber);
		} else if (phones.length > 1) {
			setShowList(true);
		}
	};

	const phoneCallAction = (phoneNumber: string) => {
		window.open(`tel:${phoneNumber.replace(/\D/g, '')}`);
	};

	const onClose = () => {
		setShowList(false);
	};

	const techNumbersModalContent = () => {
		return phones.length > 1 ? <PhoneNumbersList {...{ phones, onClose, phoneCallAction, show: showList }} /> : <></>;
	};

	const phoneCallContent = () => {
		return (
			<>
				<div
					className="call-app-button"
					onClick={() => {
						phoneCallClick();
					}}
				>
					<PhoneIcon />
					<span>Ligar</span>
				</div>
				{techNumbersModalContent()}
			</>
		);
	};

	return phones.length ? phoneCallContent() : <></>;
};

export default PhoneCaller;
