/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import NewPassForm from 'components/Account/NewPass';
import PageTitle from 'components/Shared/PageTitle';
import PraticaImage from 'components/Shared/PraticaImage';
import SideBar from 'components/Shared/SideBar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import history from 'routes/history';
import { signOutRequest } from 'store/modules/auth/actions';
import { RouteList } from 'utils/constants';

import { store } from 'store';
import './styles.scss';

type TParams = {
	history: string;
	location: any;
	match: any;
};

const NewPass: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
	const [authToken, setAuthToken] = useState<string>('');

	const dispatch = useDispatch();

	const auth = store.getState().auth;

	useEffect(() => {
		getRouterParams();
		dispatch(signOutRequest(auth.id));
	}, []);

	const getRouterParams = () => {
		const params = match?.params as any;
		if (params) {
			const auth = params.auth;
			if (auth) {
				setAuthToken(auth);
			} else {
				history.replace(RouteList.LOGIN);
			}
		} else {
			history.replace(RouteList.LOGIN);
		}
	};

	return (
		<>
			<PageTitle title="Nova senha" single />
			<section className="new-pass-main-content">
				<SideBar>
					<NewPassForm {...{ authToken }} />
				</SideBar>
				<PraticaImage />
			</section>
		</>
	);
};

export default NewPass;
