/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import MobileNavigator from 'components/Shared/MobileNavigator';
import Paginator from 'components/Shared/Paginator';
import { NotificationsContextElement } from 'contexts/NotificationsContext/NotificationsContext';
import { NotificationsContextType } from 'contexts/NotificationsContext/NotificationsContextType';
import React, { useContext } from 'react';
import { Notification } from '../../../../../models/Notification';
import history from '../../../../../routes/history';
import NotificationsList from '../List';
import './styles.scss';

interface Params {
	onClose: () => void;
}

const NotificationsCenter: React.FC<Params> = ({ onClose }) => {
	const { isLoading, currentPage, totalPages, onPageChange } = useContext<NotificationsContextType>(NotificationsContextElement);

	const onItemClick = (notification: Notification) => {
		if (window.location.pathname === `/atendimentos/${notification.osNumber}`) {
			window.location.reload();
		}
		history.push(`/atendimentos/${notification.osNumber}`, { notification });
		onClose();
	};

	const paginatorContent = <Paginator selected={currentPage} total={totalPages} isLoading={isLoading} onPageChange={onPageChange} />;

	return (
		<section className="notify-center-mobile-content">
			<div className="notify-center-header">
				<div className="go-back">
					<MobileNavigator action={onClose} />
				</div>
				<div className="notify-center-title">
					<span>Notificações</span>
				</div>
			</div>
			<NotificationsList {...{ onItemClick, paginatorContent }} />
		</section>
	);
};

export default NotificationsCenter;
