/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import React, { useContext } from 'react';
import { ViewType } from 'utils/constants';
import MobileStoresSettingsList from './Mobile';
import WebStoresSettingsList from './Web';

const StoresSettingsList: React.FC = () => {
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);

	return viewType === ViewType.MOBILE ? <MobileStoresSettingsList /> : <WebStoresSettingsList />;
};

export default StoresSettingsList;
