/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { getBase64 } from 'services';
import footerImage from '../../assets/image/rodapeazul.jpg';
import clientImage from '../../assets/image/headercliazul.png';
import equipImage from '../../assets/image/headerequipazul.png';
import headerImage from '../../assets/image/headerorcatazul.jpg';
import paymentImage from '../../assets/image/headerpgtoazul.png';
import itemsImage from '../../assets/image/headeritensorcazul.png';
import { toast } from 'react-toastify';

const downloads = require('downloadjs');

/**
 * @description Download all kind of documents
 * @param fileData File to be Downloaded.
 */
export async function downloadFile(fileData: any) {
	const fileName = fileData.name;
	const base64Data = fileData.img_base64;
	if (fileData.link) {
		window.open(fileData.link, '_blank');
	} else {
		downloads(base64Data, fileName);
	}
}

/**
 * @description Downloads a Quote Document.
 * @param fileData Quote Document.
 */
export function downloadItemQuote(fileData: any) {
	if (fileData.link) {
		window.open(fileData.link, '_blank');
	} else {
		getBase64(fileData.id)
			.then(response => {
				if (response.status === 200) {
					const { data } = response;

					let doc = data[0].img_base64;
					doc = doc.replace('http://www.praticafornos.com.br/imgti/headerorcatazul.png', headerImage);
					doc = doc.replace('http://www.praticafornos.com.br/imgti/headercliazul.png', clientImage);
					doc = doc.replace('http://www.praticafornos.com.br/imgti/headerequipazul.png', equipImage);
					doc = doc.replace('http://www.praticafornos.com.br/imgti/headeritensorcazul.png', itemsImage);
					doc = doc.replace('http://www.praticafornos.com.br/imgti/headerpgtoazul.png', paymentImage);
					doc = doc.replace('http://www.praticafornos.com.br/imgti/rodapeazul.png', footerImage);

					const byteArray = getByteArray(doc);
					const blob = new Blob([byteArray], { type: 'text/html' });
					downloads(blob, fileData.name, 'text/html');
				}
			})
			.catch(err => {
				console.error('Error on request TechAssit Files > ', err);
				toast.error('Erro ao baixar o anexo!');
			});
	}
}

/**
 * @description Receive a Document and convert to a ByteArray
 * @param document Document to be converted.
 * @returns Returns a Byte Array from document.
 */
function getByteArray(document?: any) {
	let byteCharacters = '';
	if (document) {
		byteCharacters = atob(btoa(document));
	}

	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}

	return new Uint8Array(byteNumbers);
}
