/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import Files from 'models/OsFiles';
import React from 'react';
import { StepTypes } from 'utils/constants';
import { downloadFile, downloadItemQuote } from 'utils/Download';
import FilesCenterItem from './FilesCenterItem';
import './styles.scss';

interface Params {
	techAssistFiles: Files[];
}

const FilesCenter: React.FC<Params> = ({ techAssistFiles }) => {
	const listContent = () => {
		return techAssistFiles.map((file: Files, index: number) => <FilesCenterItem {...{ file, onItemClick }} key={index} />);
	};

	const onItemClick = (file: Files) => {
		const type = file.tech_assist_type;
		switch (type) {
			case StepTypes.ORDER:
			case StepTypes.QUOTE:
			case StepTypes.INVOICE:
				downloadItemQuote(file);
				break;
			default:
				downloadFile(file);
				break;
		}
	};

	const mainContent = () => {
		return (
			<div className="service-orders-files-center-content">
				<div className="files-center-description-content">
					<span>Toque nos arquivos que deseja baixar:</span>
				</div>
				<div className="files-center-header-content">
					<div className="list-header name">
						<span>Nome do Arquivo</span>
					</div>
					<div className="list-header type">
						<span>Formato</span>
					</div>
					<div className="list-header created">
						<span>Data</span>
					</div>
				</div>
				<div className="files-center-list-content">{listContent()}</div>
			</div>
		);
	};

	return mainContent();
};

export default FilesCenter;
