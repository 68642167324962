/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import CustomBorderDotted from 'components/Shared/CustomBorder';
import EmptyList from 'components/Shared/EmptyList';
import HasError from 'components/Shared/HasError';
import LoadingProgress from 'components/Shared/LoadingProgress';
import { EquipmentsHistoryContextElement } from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContext';
import { EquipmentsHistoryContextType } from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContextType';
import ServiceOrder from 'models/ServiceOrder';
import React, { useContext } from 'react';
import { onChildrenScroll } from 'utils/Screen';
import EquipmentServiceOrdersListItem from '../ServiceOrdersListItem';
import './styles.scss';

interface Params {
	onItemClick: (serviceOrder: ServiceOrder) => void;
	onCancelServiceOrderClick: (serviceOrder: ServiceOrder) => void;
}

const EquipmentServiceOrdersList: React.FC<Params> = ({ onItemClick, onCancelServiceOrderClick }) => {
	const { serviceOrders, isLoading, hasError } = useContext<EquipmentsHistoryContextType>(EquipmentsHistoryContextElement);

	const headerContent = () => {
		return (
			<>
				<div className="equip-orders-list-header">
					<div className="list-header created">
						<span>Solicitação</span>
					</div>
					<div className="list-header protocol">
						<span>Protocolo</span>
					</div>
					<div className="list-header tech">
						<span>Técnico</span>
					</div>
					<div className="list-header status">
						<span>Status</span>
					</div>
				</div>
				<CustomBorderDotted />
			</>
		);
	};

	const listContent = () => {
		const listItems = serviceOrders.map((serviceOrder: ServiceOrder, index: number) => (
			<EquipmentServiceOrdersListItem {...{ serviceOrder, onItemClick, onCancelServiceOrderClick }} key={index} />
		));

		return (
			<div
				className="equip-orders-list-mobile-items"
				onScroll={e => {
					onChildrenScroll(e, 'equip-orders-list-mobile-content');
				}}
			>
				{listItems}
			</div>
		);
	};

	const handleMainContent = () => {
		if (isLoading) {
			return <LoadingProgress show text="Buscando atendimentos..." />;
		} else if (hasError) {
			return <HasError show />;
		} else if (!hasError && !isLoading && !serviceOrders.length) {
			return <EmptyList show text="Sem atendimentos por aqui" />;
		} else {
			return listContent();
		}
	};

	return (
		<section className="equip-orders-list-mobile-content">
			<div className="equip-orders-title">
				<span>Atendimentos</span>
			</div>
			{headerContent()}
			{handleMainContent()}
		</section>
	);
};

export default EquipmentServiceOrdersList;
