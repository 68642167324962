/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteList } from 'utils/constants';
import history from '../../../../routes/history';
import { signOutRequest } from '../../../../store/modules/auth/actions';

import { store } from 'store';
import './styles.scss';

interface Params {
	onAccountUserClick: () => void;
	onAppSettingsClick: () => void;
	onHelpUserClick: () => void;
	onClose: () => void;
}

const UserSelector: React.FC<Params> = ({ onHelpUserClick, onAppSettingsClick, onAccountUserClick, onClose }) => {
	const dispatch = useDispatch();

	const auth = store.getState().auth;

	const handleLogout = () => {
		dispatch(signOutRequest(auth.id));
		history.push(RouteList.LOGIN);
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<section className="user-area-selector">
				<div className="user-area-selector-item" onClick={onAccountUserClick}>
					<span>Editar Conta</span>
				</div>
				<div className="user-area-selector-item" onClick={onAppSettingsClick}>
					<span>Configurações</span>
				</div>
				<div className="user-area-selector-item" onClick={onHelpUserClick}>
					<span>Ajuda</span>
				</div>
				<div className="user-area-selector-item" onClick={handleLogout}>
					<span>Sair</span>
				</div>
			</section>
		</ClickAwayListener>
	);
};

export default UserSelector;
