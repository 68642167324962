/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import moment from 'moment';
import React, { useContext } from 'react';
import { Notification } from '../../../../../models/Notification';
import { LastNotificationsContextElement } from 'contexts/LastNotificationsContext/LastNotificationsContext';
import { LastNotificationsContextType } from 'contexts/LastNotificationsContext/LastNotificationsContextType';

import './styles.scss';

interface Params {
	onItemClick: (notification: Notification) => void;
}

const NotificationsList: React.FC<Params> = ({ onItemClick }) => {
	const { lastNotificationsList, onChangeStatus } = useContext<LastNotificationsContextType>(LastNotificationsContextElement);

	const parseDate = (value: Date): string => {
		// let mills = value.getTime();
		// let zoneTime = value.getTimezoneOffset();
		// if (zoneTime) {
		//     mills = mills - (zoneTime * 60000);
		//     let date = new Date(mills);
		//     return moment(date).format('HH:mm | DD/MM/YYYY');
		// }

		return moment(value).format('HH:mm | DD/MM/YYYY');
	};

	const changeAction = (notifyId: number, readAt?: Date) => {
		const actionName = !readAt ? 'Marcar como lida' : 'Marcar como não lida';

		const className = !readAt ? 'mark-as' : 'unmark-as';

		return (
			<span
				className={className}
				onClick={() => {
					onChangeStatus(notifyId);
				}}
			>
				{actionName}
			</span>
		);
	};

	return (
		<>
			{lastNotificationsList.map((notify, index) => (
				<div className="notify-list-item" key={index}>
					<div
						className="list-item title"
						onClick={() => {
							onChangeStatus(notify.id, true);
							onItemClick(notify);
						}}
					>
						<span className={`${!notify.readAt ? 'not-read' : ''}`}>{notify.body}</span>
					</div>
					<div className="list-item options">
						{changeAction(notify.id, notify.readAt)}
						<span className="notify-time">{parseDate(new Date(notify.createdAt))}</span>
					</div>
				</div>
			))}
		</>
	);
};

export default NotificationsList;
