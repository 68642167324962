import { all, takeLatest } from 'redux-saga/effects';
import history from 'routes/history';
import { RouteList } from 'utils/constants';
import { apiPrp, updateUserToken } from '../../../services';

export function signIn({ payload }) {
	history.push(RouteList.EQUIPMENTS);
}

export function setToken({ payload }) {
	if (!payload) return;
	if (payload.auth.token) {
		apiPrp.headers.Authorization = `${payload.auth.token}`;
	}
}

export function signOut({ payload }) {
	updateUserToken(null, payload.userId);
	localStorage.clear();
}

export default all([takeLatest('@auth/SIGN_IN_REQUEST', signIn), takeLatest('@auth/SIGN_OUT_REQUEST', signOut), takeLatest('persist/REHYDRATE', setToken)]);
