/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { Address } from 'models/Address';
import { getLocationByZipCode } from 'services';
import React, { createContext, useState } from 'react';
import { RequisitionError } from 'models/RequisitionError';
import { LocationsContextType } from './LocationsContextType';

export const LocationsContextElement = createContext<LocationsContextType>({
	location: {} as Address,
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	onZipCodeChanges: () => {},
});

const LocationsProvider = (props: any) => {
	const [hasError, setHasError] = useState<RequisitionError>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [location, setLocation] = useState<Address>();

	const onZipCodeChanges = (zipCode: string, callback: (location?: Address) => void) => {
		setLoading(true);
		getLocationByZipCode(zipCode)
			.then(res => {
				setLoading(false);
				callback(buildExtLocationData(res.data));
			})
			.catch(err => {
				setLoading(false);
				callback();
			});
	};

	const buildExtLocationData = (extLocationData: any) => {
		return {
			city: extLocationData.localidade,
			stateSigla: extLocationData.uf,
			address: extLocationData.logradouro,
			neighborhood: extLocationData.bairro,
		} as Address;
	};

	const providerValues = () => {
		return { location, isLoading, hasError, onZipCodeChanges };
	};

	return <LocationsContextElement.Provider value={providerValues()}>{props.children}</LocationsContextElement.Provider>;
};

export default LocationsProvider;
