import produce from 'immer';

const INITIAL_STATE = {
	id: null,
	token: null,
	signed: false,
};

export default function auth(state = INITIAL_STATE, action) {
	return produce(state, draft => {
		switch (action.type) {
			case '@auth/SIGN_IN_REQUEST': {
				draft.id = action.payload.user.id;
				draft.token = action.payload.user.token;
				draft.signed = true;
				break;
			}
			case '@auth/SIGN_OUT_REQUEST': {
				draft.signed = false;
				draft.token = null;
				draft.id = null;
				break;
			}
			case '@auth/SIGN_IN_UPDATE': {
				draft.signed = false;
				draft.token = null;
				draft.id = null;
				break;
			}
			default:
		}
	});
}
