/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { ClickAwayListener } from '@material-ui/core';
import SearchInput from 'components/Shared/SearchInput';
import { ClientContextElement } from 'contexts/ClientContext/ClientContext';
import { ClientContextType } from 'contexts/ClientContext/ClientContextType';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import Client from 'models/Client';
import React, { useContext, useState } from 'react';
import history from 'routes/history';
import { store } from 'store';
import { RouteList, UserTypes, ViewType } from 'utils/constants';
import ClientList from './ClientList';
import './styles.scss';

interface Params {
	onClose: () => void;
}

const ClientSelector: React.FC<Params> = ({ onClose }) => {
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);
	const { clientList, clientSelected, isLoading, onSelectClient, onSearchClient } = useContext<ClientContextType>(ClientContextElement);
	const [client, setClient] = useState<Client>(clientSelected || store.getState().data?.client);

	const user = store.getState().data.user;
	const isTech = user.appConfigs.userType === UserTypes.TECH;

	const onChangeSearchValues = (value: string) => {
		const callback = (value: string) => {};
		onSearchClient(value, callback);
	};

	const onChangeClient = (client: Client) => {
		onSelectClient(client, () => {
			setClient(client);
		});
		onClose();
	};

	const storesConfigContent = () => {
		return (
			<div
				className="client-configs"
				onClick={() => {
					goToStoreSettings();
				}}
			>
				<span>configurar lojas</span>
			</div>
		);
	};

	const goToStoreSettings = () => {
		history.push(RouteList.STORES_SETTINGS);
	};

	const showStoresConfig = () => {
		return isTech ? storesConfigContent() : <></>;
	};

	const mainContent = () => {
		return (
			<section className={`client-area-selector ${viewType === ViewType.MOBILE ? 'mobile' : ''}`}>
				<div className="client-area-selector-header">
					<span>Selecione uma loja:</span>
				</div>
				<div className="client-area-search">
					<SearchInput onChangeValues={onChangeSearchValues} placeHolder="Procurar loja" />
				</div>
				<div className={`client-area-list ${isTech ? 'show-stores' : ''}`}>
					<ClientList clientList={clientList} clientSelected={client} isLoading={isLoading} onChange={onChangeClient} />
				</div>
				{showStoresConfig()}
			</section>
		);
	};

	return viewType === ViewType.MOBILE ? mainContent() : <ClickAwayListener onClickAway={onClose}>{mainContent()}</ClickAwayListener>;
};

export default ClientSelector;
