/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { ClickAwayListener } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import modalBg from '../../../../assets/image/response_modal_mobile_bg.png';

interface Params {
	title: string;
	children: any;
	secondaryClass?: string;
	hideTitle?: boolean;
	hideAction?: boolean;
	hideClose?: boolean;
	onClose: () => void;
}

const MobileResponseModal: React.FC<Params> = ({ title, children, secondaryClass, hideTitle, hideAction, hideClose, onClose }) => {
	const headerAction = () => {
		return hideClose ? (
			<></>
		) : (
			<div
				className="modal-header-close-content"
				onClick={() => {
					onClose();
				}}
			>
				<CloseIcon />
			</div>
		);
	};

	const modalAction = () => {
		return hideAction ? (
			<></>
		) : (
			<div className="modal-action-content">
				<button onClick={onClose}>OK</button>
			</div>
		);
	};

	const modalTitle = () => {
		return hideTitle ? <></> : <span>{title}</span>;
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<div className={`response-modal-mobile ${secondaryClass ? secondaryClass : ''}`}>
				<div className="modal-header-image">
					<img src={modalBg} alt="response_modal_mobile_bg" />
					{headerAction()}
				</div>
				<div className="modal-main-content">
					{modalTitle()}
					{children}
				</div>
				{modalAction()}
			</div>
		</ClickAwayListener>
	);
};

export default MobileResponseModal;
