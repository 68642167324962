/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { store } from 'store';
import Client from 'models/Client';
import { getClientDefects } from 'services';
import { ReportedDefect } from 'models/ReportedDefect';
import { RequisitionError } from 'models/RequisitionError';
import React, { createContext, useEffect, useState } from 'react';
import { ReportedDefectContextType } from './ReportedDefectContextType';

export const ReportedDefectContextElement = createContext<ReportedDefectContextType>({
	reportedDefects: [],
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	onSearchDefect: () => {},
});

const ServiceOrderProvider = (props: any) => {
	const [client, setClient] = useState<Client>(store.getState().data?.client);
	const [reportedDefects, setReportedDefects] = useState<ReportedDefect[]>([]);
	const [hasError, setHasError] = useState<RequisitionError>();
	const [searchValue, setSearchValue] = useState<string>('');
	const [isLoading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		requestReportedDefecs();
	}, [client]);

	const requestReportedDefecs = (search = searchValue) => {
		setLoading(true);
		const clientId = client.id_client_orig || client.id;

		getClientDefects(clientId, search)
			.then(res => {
				const { reportedDefects } = res.data;
				setReportedDefects(reportedDefects);
				setLoading(false);
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				setHasError(reportError);
				setLoading(false);
			});
	};

	const onSearchDefect = (search: string) => {
		setSearchValue(search);
	};

	const providerValues = () => {
		return { reportedDefects, isLoading, hasError, onSearchDefect };
	};

	return <ReportedDefectContextElement.Provider value={providerValues()}>{props.children}</ReportedDefectContextElement.Provider>;
};

export default ServiceOrderProvider;
