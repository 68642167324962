/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React, { useContext } from 'react';
import { NotificationsContextElement } from 'contexts/NotificationsContext/NotificationsContext';
import { NotificationsContextType } from 'contexts/NotificationsContext/NotificationsContextType';

const NotificationsWarning: React.FC = () => {
	const { notificationsList } = useContext<NotificationsContextType>(NotificationsContextElement);

	const mainContent = () => {
		return <span className="notification-message-alert">As notificações serão automaticamente excluídas 60 dias após seu recebimento.</span>;
	};

	return notificationsList.length ? mainContent() : <></>;
};

export default NotificationsWarning;
