export function saveClientSelected(client) {
	return {
		type: '@data/SAVE_CLIENT_INFO',
		payload: { client },
	};
}

export function updateUserInfo(user) {
	return {
		type: '@data/UPDATE_USER_INFO',
		payload: { user },
	};
}

export function updateEquipsFilters(listEquipsParams) {
	return {
		type: '@data/UPDATE_EQUIP_FILTER',
		payload: { listEquipsParams },
	};
}

export function updateOsFilters(listOsParams) {
	return {
		type: '@data/UPDATE_OS_FILTER',
		payload: { listOsParams },
	};
}

export function updateStoresFilters(listStoresParams) {
	return {
		type: '@data/UPDATE_STORES_FILTER',
		payload: { listStoresParams },
	};
}
