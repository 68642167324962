/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { SocketContextElement } from 'contexts/SocketContext/SocketContext';
import { SocketContextType } from 'contexts/SocketContext/SocketContextType';
import { FilterData } from 'models/FilterData';
import { RequisitionError } from 'models/RequisitionError';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllNotifications, removeAllNotifications, removeNotification, updateAllNotificationStatus, updateNotificationStatus } from 'services';
import { store } from 'store';
import { Notification } from '../../models/Notification';
import { NotificationsContextType } from './NotificationsContextType';

const INITIAL_PAGE = 1;
const DEFAULT_LIMIT = 10;

export const NotificationsContextElement = createContext<NotificationsContextType>({
	notificationsList: [],
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	hasNewNotifications: {} as boolean,
	pageLimit: {} as number,
	totalPages: {} as number,
	currentPage: {} as number,
	notifyFilter: {} as FilterData,
	onPageChange: () => {},
	onLimitChange: () => {},
	onFilterChange: () => {},
	onSearchNotification: () => {},
	onChangeStatus: () => {},
	onChangeAllStatus: () => {},
	onRemove: () => {},
	onRemoveAll: () => {},
});

interface ListParams {
	pageLimit: number;
	currentPage: number;
	totalPages: number;
	searchValue?: string;
	notifyFilter?: FilterData;
}

function defaultListParams() {
	return {
		pageLimit: DEFAULT_LIMIT,
		currentPage: INITIAL_PAGE,
		totalPages: INITIAL_PAGE,
	};
}

const NotificationProvider = (props: any) => {
	const { eventListener, disconnect } = useContext<SocketContextType>(SocketContextElement);
	const [notificationsList, setNotificationList] = useState<Notification[]>([]);
	const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<RequisitionError>();
	const listParams = useRef<ListParams>(defaultListParams());
	const userEmail = store.getState().data.user?.email;
	const userId = store.getState().data.user.id;

	useEffect(() => {
		requestNotifications();
		socketEvents();
		return () => {
			disconnect();
		};
	}, [userEmail]);

	const socketEvents = () => {
		if (userEmail) {
			const socketTopic = `notify_app_report/${userEmail}`;
			eventListener(socketTopic, (err: boolean, data: any) => {
				if (!err) {
					requestNotifications();
				} else {
					toast.error('Erro ao buscar as últimas notificações!');
				}
			});
		}
	};

	const requestNotifications = () => {
		setLoading(true);
		const { currentPage, pageLimit, searchValue, notifyFilter } = { ...listParams.current };

		const offset = (currentPage - 1) * pageLimit;
		getAllNotifications(userId, pageLimit, offset, searchValue, notifyFilter)
			.then(res => {
				const { count, notifications } = res.data.response;
				updateListParams(Math.ceil(count / pageLimit), 'count');
				setNotificationList(notifications);
				checkNewNotifications(notifications);
				setLoading(false);
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};

				setHasError(reportError);
				clearListParams(true);
				setLoading(false);
			});
	};

	const checkNewNotifications = (notifications: Notification[]) => {
		const hasNotReadNotification = notifications.length && notifications.find(n => n.readAt === null) ? true : false;
		setHasNewNotifications(hasNotReadNotification);
	};

	const onPageChange = (page: number) => {
		updateListParams(page, 'page');
		requestNotifications();
	};

	const onLimitChange = (newLimit: number) => {
		updateListParams(newLimit, 'limit');
		clearListParams();
		requestNotifications();
	};

	const onSearchNotification = (search: string) => {
		updateListParams(search, 'search');
		clearListParams();
		requestNotifications();
	};

	const onFilterChange = (newFilter: FilterData) => {
		updateListParams(newFilter, 'filter');
		clearListParams();
		requestNotifications();
	};

	const clearListParams = (clearAll = false) => {
		if (clearAll) {
			listParams.current = defaultListParams();
		} else {
			const newListParams = { ...listParams.current };
			newListParams.currentPage = INITIAL_PAGE;
			newListParams.totalPages = INITIAL_PAGE;
			listParams.current = newListParams;
		}
	};

	const updateListParams = (value: number | string | FilterData, field: 'filter' | 'limit' | 'page' | 'search' | 'count') => {
		const newListParams = { ...listParams.current };

		switch (field) {
			case 'filter':
				newListParams.notifyFilter = value as FilterData;
				break;
			case 'limit':
				newListParams.pageLimit = value as number;
				break;
			case 'page':
				newListParams.currentPage = value as number;
				break;
			case 'count':
				newListParams.totalPages = value as number;
				break;
			case 'search':
				newListParams.searchValue = value as string;
				break;
		}

		listParams.current = newListParams;
	};

	const updateNotifications = (notificationId?: number, reverse?: boolean, isReading?: boolean) => {
		setLoading(true);

		const request = notificationId ? updateNotificationStatus(notificationId, isReading) : updateAllNotificationStatus(userId, reverse);

		request
			.then(() => {
				requestNotifications();
			})
			.catch(err => {
				setLoading(false);
				toast.error('Erro ao atualizar notificações!');
			});
	};

	const removeNotifications = (notificationId?: number) => {
		setLoading(true);
		const request = notificationId ? removeNotification(notificationId) : removeAllNotifications(userId);

		request
			.then(() => {
				const message = notificationId ? 'Notificação removida com sucesso!' : 'Notificações removidas com sucesso!';
				toast.success(message);
				requestNotifications();
			})
			.catch(err => {
				setLoading(false);
				toast.error('Erro ao remover notificação!');
			});
	};

	const onChangeStatus = (notificationId: number, isReading?: boolean) => {
		updateNotifications(notificationId, false, isReading);
	};

	const onRemove = (notificationId: number) => {
		removeNotifications(notificationId);
	};

	const onRemoveAll = () => {
		removeNotifications();
	};

	const onChangeAllStatus = (reverse?: boolean) => {
		updateNotifications(undefined, reverse);
	};

	const providerValues = () => {
		return {
			notificationsList,
			isLoading,
			hasError,
			...listParams.current,
			hasNewNotifications,
			onPageChange,
			onLimitChange,
			onSearchNotification,
			onFilterChange,
			onChangeStatus,
			onChangeAllStatus,
			onRemove,
			onRemoveAll,
		};
	};

	return <NotificationsContextElement.Provider value={providerValues()}>{props.children}</NotificationsContextElement.Provider>;
};

export default NotificationProvider;
