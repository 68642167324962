/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import history from 'routes/history';
import { RouteList } from 'utils/constants';

import './styles.scss';

interface Props {
	show: boolean;
	sendAgain: () => void;
}

const ResetPassSuccess: React.FC<Props> = ({ show, sendAgain }) => {
	const mainContent = () => {
		return (
			<div className="reset-pass-completed">
				<h4>UM E-MAIL DE RECUPERAÇÃO DE SENHA FOI ENVIADO PARA O ENDEREÇO INFORMADO.</h4>
				<span
					onClick={() => {
						sendAgain();
					}}
				>
					Reenviar
				</span>
				<span
					onClick={() => {
						history.replace(RouteList.LOGIN);
					}}
				>
					Voltar
				</span>
			</div>
		);
	};

	return show ? mainContent() : <></>;
};

export default ResetPassSuccess;
