/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import LoadingProgress from 'components/Shared/LoadingProgress';
import Client from 'models/Client';
import React from 'react';
import './styles.scss';

const firstIndex = 0;
interface Params {
	clientList: Client[];
	clientSelected?: Client;
	isLoading: boolean;
	onChange: (selectedClient: Client) => void;
}

const ClientSelector: React.FC<Params> = ({ clientList, clientSelected, isLoading, onChange }) => {
	const defaultSelected = clientSelected ? clientSelected.id : clientList[firstIndex]?.id;

	const handleChange = (e: any) => {
		const clientId = Number(e.target.value);
		const clientSelected = clientList.find(c => c.id === clientId);
		if (clientSelected) {
			onChange(clientSelected);
		} else {
			console.error(`Error on select client on list! ClientId ${clientId}`);
		}
	};

	const listItemContent = (client: Client) => {
		return <FormControlLabel value={client.id} control={<Radio />} label={client.nome_fantasia} key={client.id} />;
	};

	const listContent = () => {
		return (
			<>
				{clientList.map(c => {
					return listItemContent(c);
				})}
			</>
		);
	};

	return (
		<>
			<div className="client-selector-list" id="client-list-items">
				<FormControl component="fieldset">
					<RadioGroup aria-label="client-selector" name="client-selector" value={defaultSelected} onChange={handleChange}>
						{listContent()}
					</RadioGroup>
				</FormControl>
			</div>
			<LoadingProgress show={isLoading} text="Buscando lojas..." />
		</>
	);
};

export default ClientSelector;
