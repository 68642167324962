/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { TransfersContextElement } from 'contexts/TransfersContext/TransfersContext';
import { TransfersContextType } from 'contexts/TransfersContext/TransfersContextType';
import RequestResponse from 'models/RequestResponse';
import { TransferInput } from 'models/Transfer';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { store } from 'store';
import { EquipmentTransferViewType } from 'utils/constants';
import { inputCNPJMask } from '../../../utils/pipe';
import CustomButton from '../../Shared/CustomButton';
import FormValidator from '../../Shared/FormFields/FormValidator';
import LoadingModal from '../../Shared/LoadingModal';
import EquipmentTransferConflict from './EquipmentTransferConflict';
import EquipmentTransferSuccess from './EquipmentTransferSuccess';
import './styles.scss';

interface Props {
	onClose: () => void;
}

const EquipmentTransferModal: React.FC<Props> = ({ onClose }) => {
	const { onRequestTransfer, onVerifyEquip } = useContext<TransfersContextType>(TransfersContextElement);
	const { onUpdateUser } = useContext<DeviceContextType>(DeviceContextElement);

	const [viewType, setViewType] = useState<EquipmentTransferViewType>();

	const [loadingTitle, setLoadingTitle] = useState<string>('');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showEquipmentoConflictError, setShowEquipmentoConflictError] = useState<boolean>(false);
	const [showEquipmentTransferSuccess, setShowEquipmentTransferSuccess] = useState<boolean>(false);
	const [CNPJMasked, setCNPJMasked] = useState();

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCNPJMasked(inputCNPJMask(e.target.value));
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<TransferInput>();

	const onSubmit = (data: TransferInput) => {
		setShowEquipmentTransferSuccess(false);
		data.userId = store.getState().data.user.id;
		if (viewType === EquipmentTransferViewType.TRANSFER) {
			setLoadingTitle('Solicitando Transferência');
			setIsLoading(true);
			onRequestTransfer(data, onComplete);
		} else {
			setLoadingTitle('Verificando equipamento');
			setIsLoading(true);
			onVerifyEquip(data, onCompleteVerification);
		}
	};

	const onCompleteVerification = (request: RequestResponse) => {
		setIsLoading(false);
		if (request.reportError) {
			switch (request.reportError.code) {
				case 405:
					setShowEquipmentoConflictError(true);
					setViewType(EquipmentTransferViewType.TRANSFER);
					break;
				case 404:
					toast.error('Equipamento não encontrado');
					break;
				default:
					toast.error('Houve um erro ao verificar equipamento');
					break;
			}
		} else {
			toast.success('Equipamento/Loja inserido com sucesso!');
			onUpdateUser(request.response.clientId);
			onClose();
		}
	};

	const onComplete = (request: RequestResponse) => {
		setIsLoading(false);
		if (request.response) {
			setShowEquipmentTransferSuccess(true);
		} else {
			toast.error('Houve um erro ao enviar solicitação');
		}
	};

	const onEditClick = () => {
		setShowEquipmentoConflictError(false);
		setViewType(EquipmentTransferViewType.CORRECT_VALUES);
	};

	const firstButtonGroup = () => {
		return (
			<div>
				<CustomButton context="primary" type="submit" label="ENVIAR" />
				<CustomButton context="secondary" type="button" label="CANCELAR" onClick={onClose} />
			</div>
		);
	};

	const secondButtonGroup = () => {
		return (
			<div className="second-button-group">
				<CustomButton context="primary" type="submit" label="TRANSFERIR" customClass="outline-button" />
				<CustomButton context="primary" type="button" label="EDITAR" customClass="outline-button" onClick={onEditClick} />
				<CustomButton context="secondary" type="button" label="CANCELAR" onClick={onClose} />
			</div>
		);
	};

	const handleButtonViews = () => {
		return !showEquipmentoConflictError ? firstButtonGroup() : secondButtonGroup();
	};

	const handleConflictErrorContent = () => {
		return showEquipmentoConflictError ? <EquipmentTransferConflict /> : <></>;
	};

	const onCloseSuccessModal = () => {
		setShowEquipmentTransferSuccess(false);
		setShowEquipmentoConflictError(false);
		onClose();
	};

	const equipTransferSuccessContent = () => {
		return <EquipmentTransferSuccess {...{ show: showEquipmentTransferSuccess, onClose: onCloseSuccessModal }} />;
	};

	const loadingContent = () => {
		return <LoadingModal show={isLoading} title={loadingTitle} text="Carregando..." />;
	};

	return (
		<>
			<div className="equip-add-main-content">
				<h4>Informe o número de série do equipamento e CNPJ/CPF ao qual ele está vinculado:</h4>
				<form className="main-form-content" onSubmit={handleSubmit(onSubmit)}>
					<div>
						<div className="form-row-content">
							<div className="form-item-content">
								<label>Número de série</label>
								<input
									{...register('serialNumber', { required: true, maxLength: 250 })}
									placeholder="Informe o N/S de um equipamento"
									disabled={viewType === EquipmentTransferViewType.TRANSFER}
								/>
								<FormValidator {...{ formName: 'serialNumber', validatorName: 'Número de série', errors, isDirty }} />
							</div>
							<div className="form-item-content">
								<label>CPF/CNPJ</label>
								<input
									value={CNPJMasked}
									{...register('cpfCnpj', { required: true, maxLength: 20 })}
									placeholder="CNPJ/CPF ao qual o equipamento pertence"
									onChange={onChange}
									disabled={viewType === EquipmentTransferViewType.TRANSFER}
								/>
								<FormValidator {...{ formName: 'cpfCnpj', validatorName: 'CPF/CNPJ', errors, isDirty }} />
							</div>
						</div>
						{handleConflictErrorContent()}
					</div>
					<div className="equip-add-actions">{handleButtonViews()}</div>
				</form>
			</div>
			{loadingContent()}
			{equipTransferSuccessContent()}
		</>
	);
};

export default EquipmentTransferModal;
