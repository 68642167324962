/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ResponseModal from 'components/Shared/ResponseModal';
import React from 'react';
import { phoneMask } from 'utils/pipe';
import './styles.scss';

interface Params {
	show: boolean;
	phones: string[];
	onClose: () => void;
	phoneCallAction: (phone: string) => void;
}

const PhoneNumbersList: React.FC<Params> = ({ show, phones, onClose, phoneCallAction }) => {
	const phoneItemContent = () => {
		return phones.map((phone: string, index: number) => (
			<div
				key={index}
				className="tech-number-option"
				onClick={() => {
					phoneCallAction(phone);
				}}
			>
				<FiberManualRecordIcon />
				<span>{phoneMask(phone)}</span>
			</div>
		));
	};

	const modalContent = () => {
		return (
			<div className="service-order-tech-numbers-modal ">
				<div className="tech-number-list">{phoneItemContent()}</div>
			</div>
		);
	};

	const mainContent = () => {
		const title = 'Selecione um número abaixo para ligar:';
		const children = modalContent();
		const hideAction = true;
		const hideClose = false;
		return <ResponseModal {...{ title, children, onClose, hideAction, hideClose }} />;
	};

	return show ? mainContent() : <></>;
};

export default PhoneNumbersList;
