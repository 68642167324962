import { browserTracingIntegration, init, replayIntegration } from '@sentry/react';
import packageJson from '../../package.json';

const env = process.env.NODE_ENV;

export const useSentry = (): void => {
	if (process.env.SENTRY_DSN) {
		init({
			dsn: process.env.SENTRY_DSN,
			release: `app-frontend@${packageJson.version}`,
			environment: env,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
			tracesSampleRate: 1.0,
			autoSessionTracking: true,
			enabled: true,
			tracePropagationTargets: ['https://app.praticabr.com/'],
			integrations: [
				browserTracingIntegration(),
				replayIntegration({
					maskAllText: false,
					blockAllMedia: false,
					maskAllInputs: false,
				}),
			],
		});
	}
};
