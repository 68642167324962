/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

import './styles.scss';

const CustomBorderDotted: React.FC = () => {
	return (
		<div className="custom-border-dotted">
			<div></div>
		</div>
	);
};

export default CustomBorderDotted;
