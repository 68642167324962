/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EmptyList from 'components/Shared/EmptyList';
import HasError from 'components/Shared/HasError';
import LoadingProgress from 'components/Shared/LoadingProgress';
import { EquipmentsContextElement } from 'contexts/EquipmentsContext/EquipmentsContext';
import { EquipmentsContextType } from 'contexts/EquipmentsContext/EquipmentsContextType';
import $ from 'jquery';
import Equipment from 'models/Equipment';
import { FilterData } from 'models/FilterData';
import React, { useContext, useState } from 'react';
import { onChildrenScroll } from 'utils/Screen';
import EquipmentsListItem from '../EquipmentsListItem';
import './styles.scss';

const SCROLL_TOP = 0;

interface Params {
	paginatorContent: JSX.Element;
	onItemClick: (equipment: Equipment) => void;
	onOpenClick: (equipment: Equipment) => void;
}

const EquipmentsList: React.FC<Params> = ({ paginatorContent, onItemClick, onOpenClick }) => {
	const { equipList, isLoading, equipsFilter, hasError, onFilterChange } = useContext<EquipmentsContextType>(EquipmentsContextElement);

	const [warrantOrder, setWarrantOrder] = useState(equipsFilter?.warrantOrder || 'desc');

	$('.mobile-scroll').scrollTop(SCROLL_TOP);
	$('.equip-list-items').scrollTop(SCROLL_TOP);

	const onOrderChange = (order: 'desc' | 'asc') => {
		const newFilter = equipsFilter ? { ...equipsFilter } : ({} as FilterData);

		newFilter.warrantOrder = order;

		onFilterChange(newFilter);
		setWarrantOrder(order);
	};

	const arrowOrderContent = () => {
		return warrantOrder === 'desc' ? <ArrowUpwardIcon onClick={() => onOrderChange('asc')} /> : <ArrowDownwardIcon onClick={() => onOrderChange('desc')} />;
	};

	const headerContent = () => {
		return (
			<div className="equip-list-header">
				<div className="list-header equip">
					<span>Equipamento</span>
				</div>
				<div className="list-header serial">
					<span>Número de série</span>
				</div>
				<div className="list-header warrant">
					<span>Fim da garantia</span>
					{arrowOrderContent()}
				</div>
			</div>
		);
	};

	const listContent = () => {
		const listItems = equipList.map((equipment: Equipment, index: number) => <EquipmentsListItem {...{ equipment, onItemClick, onOpenClick }} key={index} />);

		return (
			<div
				className="equip-list-items"
				onScroll={e => {
					onChildrenScroll(e, 'equips-main-content');
				}}
			>
				{listItems}
				{paginatorContent}
			</div>
		);
	};

	const handleMainContent = () => {
		if (isLoading) {
			return <LoadingProgress show text="Buscando equipamentos..." />;
		} else if (hasError) {
			return <HasError show />;
		} else if (!hasError && !isLoading && !equipList.length) {
			return <EmptyList show text="Sem equipamentos por aqui" />;
		} else {
			return listContent();
		}
	};

	return (
		<section className="equip-list-content">
			{headerContent()}
			{handleMainContent()}
		</section>
	);
};

export default EquipmentsList;
