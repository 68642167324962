/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

import './styles.scss';

interface Params {
	order: string;
}

const FormQuestionLine: React.FC<Params> = ({ order }) => {
	return (
		<div className="form-item-content-line">
			<span>{order}</span>
			<div className="line"></div>
		</div>
	);
};

export default FormQuestionLine;
