/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import Paginator from 'components/Shared/Paginator';
import { EquipmentsHistoryContextElement } from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContext';
import { EquipmentsHistoryContextType } from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContextType';
import $ from 'jquery';
import ServiceOrder from 'models/ServiceOrder';
import React, { useContext, useEffect, useState } from 'react';
import EquipmentServiceOrdersList from './ServiceOrdersList';
import './styles.scss';

const SCROLL_TOP = 0;

interface Params {
	equipId?: number;
	onItemClick: (serviceOrder: ServiceOrder) => void;
	onCancelServiceOrderClick: (serviceOrder: ServiceOrder) => void;
}

const EquipmentServiceOrdersMobile: React.FC<Params> = ({ equipId, onItemClick, onCancelServiceOrderClick }) => {
	const { currentPage, totalPages, isLoading, onEquipChange, onPageChange } = useContext<EquipmentsHistoryContextType>(EquipmentsHistoryContextElement);
	const [notFoundError, setNotFoundError] = useState<boolean>(false);

	$('.equipment-details-content').scrollTop(SCROLL_TOP);

	useEffect(() => {
		requestEquipServiceOrders();
	}, []);

	const requestEquipServiceOrders = () => {
		if (equipId) {
			onEquipChange(equipId);
		} else {
			setNotFoundError(true);
		}
	};

	return (
		<section className="equip-service-orders-mobile-content">
			<EquipmentServiceOrdersList onItemClick={onItemClick} onCancelServiceOrderClick={onCancelServiceOrderClick} />
			<Paginator selected={currentPage} total={totalPages} isLoading={isLoading} onPageChange={onPageChange} />
		</section>
	);
};

export default EquipmentServiceOrdersMobile;
