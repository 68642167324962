/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { StoreSettingsContextElement } from 'contexts/StoreSettingsContext/StoreSettingsContext';
import { StoreSettingsContextType } from 'contexts/StoreSettingsContext/StoreSettingsContextType';
import StoreSettings from 'models/StoreSettings';
import React, { useContext } from 'react';
import StoresSettingsListItem from './StoresSettingsListItem';
import './styles.scss';

const SCROLL_TOP = 0;

const WebStoresSettingsList: React.FC = () => {
	const { storeList } = useContext<StoreSettingsContextType>(StoreSettingsContextElement);

	$('.stores-settings-mobile-list-items').scrollTop(SCROLL_TOP);

	const listContent = () => {
		return (
			<>
				{storeList.map((storeSettings: StoreSettings, index: number) => {
					return <StoresSettingsListItem {...{ storeSettings }} key={index} />;
				})}
			</>
		);
	};

	return (
		<section className="stores-settings-list-content">
			<div className="stores-settings-list-header">
				<div className="list-header name">
					<span>Nome da Loja</span>
				</div>
				<div className="list-header full-name">
					<span>Nome da Rede</span>
				</div>
				<div className="list-header equips">
					<span>Quant. equip</span>
				</div>
				<div className="list-header users">
					<span>Usuários</span>
				</div>
			</div>
			<div className="stores-settings-mobile-list-items">{listContent()}</div>
		</section>
	);
};

export default WebStoresSettingsList;
