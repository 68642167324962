/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { store } from 'store';
import { toast } from 'react-toastify';
import { Notification } from '../../models/Notification';
import { RequisitionError } from 'models/RequisitionError';
import { SocketContextElement } from 'contexts/SocketContext/SocketContext';
import { SocketContextType } from 'contexts/SocketContext/SocketContextType';
import { LastNotificationsContextType } from './LastNotificationsContextType';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAllNotifications, updateAllNotificationStatus, updateNotificationStatus } from 'services';

const DEFAULT_LIMIT = 20;
const DEFAULT_OFFSET = 0;

export const LastNotificationsContextElement = createContext<LastNotificationsContextType>({
	lastNotificationsList: [],
	hasNewNotifications: {} as boolean,
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	onChangeStatus: () => {},
	onChangeAllStatus: () => {},
});

const LastNotificationProvider = (props: any) => {
	const { eventListener, disconnect } = useContext<SocketContextType>(SocketContextElement);
	const [lastNotificationsList, setLastNotificationList] = useState<Notification[]>([]);
	const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<RequisitionError>();

	const userId = store.getState().data.user.id;

	useEffect(() => {
		requestLastNotifications();
		socketEvents();
		return () => {
			disconnect();
		};
	}, []);

	const socketEvents = () => {
		const userEmail = store.getState().data.user?.email;
		if (userEmail) {
			const socketTopic = `notify_app_report/${userEmail}`;
			eventListener(socketTopic, (err: boolean, data: any) => {
				if (!err) {
					requestLastNotifications();
				} else {
					toast.error('Erro ao buscar as últimas notificações!');
				}
			});
		}
	};

	const requestLastNotifications = () => {
		setLoading(true);

		getAllNotifications(userId, DEFAULT_LIMIT, DEFAULT_OFFSET)
			.then(res => {
				const { notifications } = res.data.response;
				setLastNotificationList(notifications);
				checkNewNotifications(notifications);
				setLoading(false);
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				setHasError(reportError);
				setLoading(false);
			});
	};

	const checkNewNotifications = (notifications: Notification[]) => {
		const hasNotReadNotification = notifications.length && notifications.find(n => n.readAt === null) ? true : false;
		setHasNewNotifications(hasNotReadNotification);
	};

	const updateNotifications = (notificationId?: number, isReading?: boolean) => {
		const request = notificationId ? updateNotificationStatus(notificationId, isReading) : updateAllNotificationStatus(userId);

		request.catch(err => {
			const reportError = {
				message: err.message,
				code: err.statusCode,
				error: err.request,
			};
			setHasError(reportError);
		});
	};

	const onChangeStatus = (notificationId: number, isReading?: boolean) => {
		updateNotifications(notificationId, isReading);
	};

	const onChangeAllStatus = () => {
		updateNotifications();
	};

	const providerValues = () => {
		return {
			lastNotificationsList,
			isLoading,
			hasError,
			hasNewNotifications,
			onChangeStatus,
			onChangeAllStatus,
		};
	};

	return <LastNotificationsContextElement.Provider value={providerValues()}>{props.children}</LastNotificationsContextElement.Provider>;
};

export default LastNotificationProvider;
