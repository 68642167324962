/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React, { createContext, useRef } from 'react';
import * as io from 'socket.io-client';
import { SocketContextType } from './SocketContextType';

export const SocketContextElement = createContext<SocketContextType>({
	socket: {},
	connect: () => {},
	disconnect: () => {},
	eventListener: () => {},
});

const SocketProvider = (props: any) => {
	const SOCKET_URL = process.env.REACT_APP_SOCKET_HOST || '';
	const socketRef = useRef<SocketIOClient.Socket>();

	const connect = async (): Promise<SocketIOClient.Socket> => {
		return new Promise((res, rej) => {
			const socketConnection = io.connect(SOCKET_URL);
			socketRef.current = socketConnection;
			socketConnection.on('connect', () => {
				res(socketConnection);
			});
		});
	};

	const disconnect = () => {
		const socket = socketRef.current;
		if (socket && socket.connected) {
			socket.on('disconnect', () => {});
			socket.disconnect();
		}
	};

	const eventListener = async (topic: string, callback: any) => {
		const socketConnection = await connect();
		if (!socketConnection) return callback(false, null);
		console.log('Listening to topic: ', topic);
		socketConnection.on(topic, (data: any) => {
			callback(null, data);
		});
	};

	const providerValues = () => {
		return {
			socket: socketRef.current,
			connect,
			disconnect,
			eventListener,
		};
	};

	return <SocketContextElement.Provider value={providerValues()}>{props.children}</SocketContextElement.Provider>;
};

export default SocketProvider;
