/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import EditAccount from 'components/Account/EditAccount';
import AppSettings from 'components/MainMenu/Web/AppSettings';
import NotificationsCenter from 'components/Notifications/NotificationsModal/Web/Center';
import Modal from 'components/Shared/Modal';
import WebNavigator from 'components/Shared/WebNavigator';
import AccountContext from 'contexts/AccountContext/AccountContext';
import ClientContext from 'contexts/ClientContext/ClientContext';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { LastNotificationsContextElement } from 'contexts/LastNotificationsContext/LastNotificationsContext';
import { LastNotificationsContextType } from 'contexts/LastNotificationsContext/LastNotificationsContextType';
import { RouterContextElement } from 'contexts/RouterContext/RouterContext';
import { RouterContextType } from 'contexts/RouterContext/RouterContextType';
import React, { useContext, useState } from 'react';
import { store } from 'store';
import { EMPTY_USER_STORE, ViewType } from 'utils/constants';
import ClientSelector from '../../Shared/ClientSelector';
import iconNotification from './../images/notifications.png';
import './styles.scss';
import UserSelector from './User';
import WebHelpUser from './WebHelpUser';

const WebMenu: React.FC = () => {
	const { isRoot } = useContext<RouterContextType>(RouterContextElement);
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);
	const { hasNewNotifications } = useContext<LastNotificationsContextType>(LastNotificationsContextElement);

	const [showHelpUser, setShowHelpUser] = useState<boolean>(false);
	const [showAppSettings, setShowAppSettings] = useState<boolean>(false);
	const [showAccountUser, setShowAccountUser] = useState<boolean>(false);
	const [showUserSelector, setShowUserSelector] = useState<boolean>(false);
	const [showClientSelector, setShowClientSelector] = useState<boolean>(false);
	const [showNotificationsCenter, setShowNotificationsCenter] = useState<boolean>(false);

	const user = store.getState().data.user;
	const client = store.getState().data?.client;

	const userName = () => {
		return `${user.name.toLowerCase()}`;
	};

	const clientName = () => {
		return client ? `${client.nome_fantasia.toLowerCase()}` : EMPTY_USER_STORE;
	};

	const onAppSettingsClick = () => {
		setShowAppSettings(!showAppSettings);
	};

	const appSettingsContent = () => {
		const title = 'Configurações';
		return (
			<Modal show={showAppSettings} title={title} onClose={onAppSettingsClick}>
				<AppSettings onClose={onAppSettingsClick} />
			</Modal>
		);
	};

	const onAccountUserClick = () => {
		setShowAccountUser(!showAccountUser);
	};

	const accountUserContent = () => {
		const title = 'Editar Conta';
		return (
			<Modal show={showAccountUser} title={title} onClose={onAccountUserClick} showClose>
				<AccountContext>
					<EditAccount onClose={onAccountUserClick} />
				</AccountContext>
			</Modal>
		);
	};

	const onHelpUserClick = () => {
		setShowHelpUser(!showHelpUser);
	};

	const helpUserContent = () => {
		const title = 'Ajuda';
		return (
			<Modal show={showHelpUser} title={title} onClose={onHelpUserClick} showClose>
				<WebHelpUser onClose={onHelpUserClick} />
			</Modal>
		);
	};

	const handlerUserSelectorToggle = () => {
		setShowUserSelector(!showUserSelector);
	};

	const userSelectorContent = () => {
		return showUserSelector ? <UserSelector {...{ onHelpUserClick, onAppSettingsClick, onAccountUserClick, onClose: handlerUserSelectorToggle }} /> : <></>;
	};

	const handlerClientSelectorToggle = () => {
		if (client) {
			setShowClientSelector(!showClientSelector);
		}
	};

	const clientSelectorContent = () => {
		return showClientSelector ? (
			<ClientContext>
				<ClientSelector onClose={handlerClientSelectorToggle} />
			</ClientContext>
		) : (
			<></>
		);
	};

	const handlerNotificationCenterToggle = () => {
		setShowNotificationsCenter(!showNotificationsCenter);
	};

	const notificationCenterContent = () => {
		return showNotificationsCenter ? (
			<div className="notifications-center-backdrop">
				<NotificationsCenter onClose={handlerNotificationCenterToggle} />
			</div>
		) : (
			<></>
		);
	};

	const handleNotificationsCount = () => {
		const hasShow = hasNewNotifications ? 'show' : 'hide';
		return <div className={`user-notifications-badge ${hasShow}`}></div>;
	};

	const handleClientDropDownIcon = () => {
		return showClientSelector ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
	};

	const handleUserDropDownIcon = () => {
		return showUserSelector ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
	};

	const clientSelectorAreaContent = () => {
		return (
			<div className="user-client-options-item">
				<div className="dropdown-area" onClick={handlerClientSelectorToggle}>
					<span className="client-name">{clientName()}</span>
					{handleClientDropDownIcon()}
				</div>
			</div>
		);
	};

	const showClientSelectorContent = () => {
		return isRoot ? clientSelectorAreaContent() : <></>;
	};

	const mainContent = () => {
		return (
			<section className="main-web-menu">
				<WebNavigator isRootPath={isRoot} />
				{showClientSelectorContent()}
				<div className="user-area">
					<div className="user-notifications-area" onClick={handlerNotificationCenterToggle}>
						<img src={iconNotification} className="user-notifications-area-icon" alt="notify-icon" />
						{handleNotificationsCount()}
					</div>
					<div className="dropdown-area" onClick={handlerUserSelectorToggle}>
						<span className="user-name">{userName()}</span>
						{handleUserDropDownIcon()}
					</div>
				</div>
			</section>
		);
	};

	return viewType === ViewType.WEB ? (
		<>
			{mainContent()}
			{userSelectorContent()}
			{clientSelectorContent()}
			{notificationCenterContent()}
			{appSettingsContent()}
			{accountUserContent()}
			{helpUserContent()}
		</>
	) : (
		<></>
	);
};

export default WebMenu;
