/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { LoginInput } from 'models/LoginInfo';
import { RequisitionError } from 'models/RequisitionError';
import User from 'models/User';
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiPrp, singIn, updateUserToken } from 'services';
import { apiSocket } from 'services/webSocket';
import { store } from 'store';
import { signInRequest } from 'store/modules/auth/actions';
import { AuthContextType } from './AuthContextType';

export const AuthContextElement = createContext<AuthContextType>({
	user: {} as User,
	isLoading: {} as boolean,
	onSignIn: () => {},
});

const AuthProvider = (props: any) => {
	// const { onUpdateUser } = useContext<DeviceContextType>(DeviceContextElement);

	const [user, setUser] = useState<User>();
	const [deviceToken, setDeviceToken] = useState<PushSubscription>(null);
	const [isLoading, setLoading] = useState<boolean>(false);

	const auth = store.getState().auth;

	const dispatch = useDispatch();

	useEffect(() => {
		registerServiceWorker();
	}, []);

	const registerServiceWorker = () => {
		const auth = store.getState().auth;
		if (auth.token) {
			navigator.serviceWorker
				.register('/service-worker.js')
				.then(async serviceWorker => {
					let subscription = await serviceWorker.pushManager.getSubscription();

					if (!subscription) {
						const publicKeyResponse = await apiSocket.get('/push/public-key');

						subscription = await serviceWorker.pushManager.subscribe({
							userVisibleOnly: true,
							applicationServerKey: publicKeyResponse.data.publicKey,
						});
					}
					setDeviceToken(subscription);
					updateUserToken(subscription, auth.id).catch(err => {
						console.log(err);
					});

					return Notification.requestPermission();
				})
				.then(async permission => {
					if (permission === 'granted') {
						console.log('Notification permission granted.');
					} else {
						console.log('Unable to get permission to notify.');
					}
				})
				.catch(err => {
					console.log('Error during service worker registration:', err);
				});
		}
	};

	const onSignIn = async (loginInfo: LoginInput, callback: (hasError?: RequisitionError) => void) => {
		setLoading(true);
		singIn(loginInfo.email, loginInfo.password, null)
			.then(res => {
				const { userData } = res.data;
				apiPrp.headers.Authorization = `${userData.token}`;
				dispatch(signInRequest(userData));
				setUser(userData);
				setLoading(false);
				registerServiceWorker();
				callback();
			})
			.catch(err => {
				const error = err.request.response?.error;
				const reportError = {
					message: error ? error.message : 'Internal Server Error',
					code: error ? error.statusCode : 500,
					error: error ? error.name : 'ServerError',
				};
				setUser(undefined);
				callback(reportError);
				setLoading(false);
			});
	};

	const providerValues = () => {
		return { user, isLoading, onSignIn };
	};

	return <AuthContextElement.Provider value={providerValues()}>{props.children}</AuthContextElement.Provider>;
};

export default AuthProvider;
