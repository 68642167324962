/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import MainMenu from 'components/MainMenu';
import React from 'react';
import { store } from 'store';

type TParams = {};

const MainPage: React.FC<TParams> = ({ children }) => {
	const { signed } = store.getState().auth;

	const authLayout = () => {
		return <MainMenu>{children}</MainMenu>;
	};

	const cleanLayout = () => {
		return <>{children}</>;
	};

	return signed ? authLayout() : cleanLayout();
};

export default MainPage;
