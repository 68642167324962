/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import moment from 'moment';
import Equipment from 'models/Equipment';
import BriefcaseIcon from '../../../Shared/BriefcaseIcon';

interface Params {
	equipment: Equipment;
	onItemClick: (equipment: Equipment) => void;
	onOpenClick: (equipment: Equipment) => void;
}

const EquipmentsListItemWeb: React.FC<Params> = ({ equipment, onItemClick, onOpenClick }) => {
	const onBriefcaseClick = (e: any) => {
		e.stopPropagation();
		onOpenClick(equipment);
	};

	return (
		<div className="list-item web" onClick={() => onItemClick(equipment)}>
			<div className="equip-info">
				<div className="equip">
					<span>{equipment.name}</span>
				</div>
				<div className="serial">
					<span>{equipment.serial_number}</span>
				</div>
				<div className="warrant">
					<span>{moment(equipment.date_end_warranty).format('DD/MM/YYYY')}</span>
				</div>
			</div>
			<div className="options" onClick={onBriefcaseClick}>
				<BriefcaseIcon />
			</div>
		</div>
	);
};

export default EquipmentsListItemWeb;
