import React from 'react';
import './styles.scss';

interface Params {
	checked?: boolean;
	name?: string;
	onChange: () => void;
}

export const CustomSwitch: React.FC<Params> = ({ checked, name, onChange }) => {
	return (
		<label className="switch">
			<input checked={checked} onChange={onChange} name={name} type="checkbox" />
			<span className="slider round"></span>
		</label>
	);
};
