/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

const FormErrorIcon: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
			<defs>
				<linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					<stop offset="0" stopColor="#E54529" />
					<stop offset="1" stopColor="#C21618" />
				</linearGradient>
			</defs>
			<path
				id="Icon_awesome-exclamation-circle"
				data-name="Icon awesome-exclamation-circle"
				d="M20.563,10.563a10,10,0,1,1-10-10A10,10,0,0,1,20.563,10.563Zm-10,2.016a1.855,1.855,0,1,0,1.855,1.855A1.855,1.855,0,0,0,10.563,12.579ZM8.8,5.911,9.1,11.4a.484.484,0,0,0,.483.458h1.958a.484.484,0,0,0,.483-.458l.3-5.484a.484.484,0,0,0-.483-.51H9.285A.484.484,0,0,0,8.8,5.911Z"
				transform="translate(-0.563 -0.563)"
				fill="url(#linear-gradient)"
			/>
		</svg>
	);
};

export default FormErrorIcon;
