/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

interface Params {
	errors: any;
	isDirty: boolean;
	formName: string;
	validatorName?: string;
}

const FormValidator: React.FC<Params> = ({ errors, isDirty, formName, validatorName }) => {
	const validatorContent = () => {
		const formError = errors[formName];
		if ((formError && isDirty) || formError) {
			switch (formError.type) {
				case 'required':
					const requiredMessage = validatorName ? `* O campo ${validatorName} é obrigatório!` : `* Campo obrigatório!`;
					return (
						<div className="form-item-content-validator">
							<span>{requiredMessage}</span>
						</div>
					);
				case 'maxLength':
					return (
						<div className="form-item-content-validator">
							<span>* Limite de máximo de caracteres atingido!</span>
						</div>
					);
				case 'minLength':
					if (validatorName === 'Senha') {
						return (
							<div className="form-item-content-validator">
								<span>* Sua senha deve conter no mínimo 8 caracteres!</span>
							</div>
						);
					}
					return (
						<div className="form-item-content-validator">
							<span>* Limite de minimo de caracteres atingido!</span>
						</div>
					);
				case 'pattern':
					const patternMessage = validatorName ? `* O campo ${validatorName} não atende o formato correto!` : `* O Campo acima está fora do padrão!`;
					return (
						<div className="form-item-content-validator">
							<span>{patternMessage}</span>
						</div>
					);
				default:
					const defaultMessage = validatorName ? `* Verifique o campo ${validatorName}!` : `* Verifique o campo acima!`;
					return (
						<div className="form-item-content-validator">
							<span>{defaultMessage}</span>
						</div>
					);
			}
		} else {
			return <></>;
		}
	};

	return validatorContent();
};

export default FormValidator;
