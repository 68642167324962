/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import FormValidator from 'components/Shared/FormFields/FormValidator';
import LoadingProgress from 'components/Shared/LoadingProgress';
import PasswordField from 'components/Shared/PasswordField';
import { AccountContextElement } from 'contexts/AccountContext/AccountContext';
import { AccountContextType } from 'contexts/AccountContext/AccountContextType';
import { LoginInput } from 'models/LoginInfo';
import { RequisitionError } from 'models/RequisitionError';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import History from 'routes/history';
import { RouteList } from 'utils/constants';
import './styles.scss';

const PASSWORD_REGEX = '(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}';

interface Params {
	authToken: string;
}

const NewPassForm: React.FC<Params> = ({ authToken }) => {
	const { isLoading, onUpdatePass } = useContext<AccountContextType>(AccountContextElement);
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<LoginInput>();

	const onSubmit = (data: LoginInput) => {
		if (data.newPassword === data.confirmPassword) {
			data.authToken = authToken;
			onUpdatePass(data, onComplete);
		} else {
			toast.error('As senhas não conferem!');
		}
	};

	const onComplete = (hasError?: RequisitionError) => {
		if (hasError) {
			switch (hasError.code) {
				case 401:
					toast.error('Link de restauração de senha expirado!');
					History.replace(RouteList.LOGIN);
					break;
				case 404:
					toast.error('Usuário não encontrado!');
					break;
				default:
					toast.error('Erro ao atualizar senha!');
					break;
			}
		} else {
			toast.success('Senha atualizada com sucesso!');
			History.replace(RouteList.LOGIN);
		}
	};

	const formContent = () => {
		return (
			<form onSubmit={handleSubmit(onSubmit)} className="main-form-content">
				<div>
					<div className="form-item-content">
						<label>Digite uma nova senha</label>
						<PasswordField
							register={register('newPassword', { required: true, minLength: 8, maxLength: 20, pattern: RegExp(PASSWORD_REGEX) })}
							placeholder="Crie uma senha"
						/>
						<FormValidator {...{ formName: 'newPassword', validatorName: 'Senha', errors, isDirty }} />
					</div>
					<div className="form-item-content">
						<label>Confirme a nova senha</label>
						<PasswordField
							register={register('confirmPassword', { required: true, minLength: 8, maxLength: 20, pattern: RegExp(PASSWORD_REGEX) })}
							placeholder="Confirme sua senha"
						/>
						<FormValidator {...{ formName: 'confirmPassword', validatorName: 'Senha', errors, isDirty }} />
					</div>
				</div>
				<div className="new-pass-options">
					<span>Sua senha deve ter entre 8 e 20 caracteres, ter ao menos uma letra maiúscula, uma minúscula, um número e um caractere especial.</span>
				</div>
				<div className="new-pass-form-actions">
					<button className="new-pass-submit" type="submit">
						ENTRAR
					</button>
				</div>
			</form>
		);
	};

	return (
		<div className="new-pass-form">
			{formContent()}
			<LoadingProgress show={isLoading} text="Salvando nova senha..." />
		</div>
	);
};

export default NewPassForm;
