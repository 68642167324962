/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import ResponseModal from 'components/Shared/ResponseModal';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import React, { useContext } from 'react';
import { ViewType } from 'utils/constants';

interface Props {
	show: boolean;
	onClose: () => void;
}

const EquipmentTransferSuccess: React.FC<Props> = ({ show, onClose }) => {
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);

	const mainContent = () => {
		return (
			<div className="equip-add-success-message">
				<b>Solicitação de transferência de titularidade enviada!</b>
				<br></br>
				<span>
					Caso aprovada, o acesso ao equipamento informado e aos demais equipamentos vinculados ao CNPJ/CPF em questão serão disponibilizados ao seu usuário. Você pode
					consultar o status da liberação através do <b>chat</b> ou pelo e-mail <b>ajuda@praticabr.com</b>
				</span>
			</div>
		);
	};

	const responseModalContent = () => {
		const title = 'SOLICITAÇÃO ENVIADA';
		const children = mainContent();
		const hideTitle = viewType === ViewType.MOBILE;

		return <ResponseModal {...{ children, title, onClose, hideTitle }} hideAction />;
	};

	return show ? responseModalContent() : <></>;
};

export default EquipmentTransferSuccess;
