/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useContext } from 'react';
import { parseEquipName } from 'utils/pipe';
import BriefcaseIcon from 'components/Shared/BriefcaseIcon';
import { EquipmentsDetailsContextElement } from 'contexts/EquipmentsDetailsContext/EquipmentsDetailsContext';
import { EquipmentsDetailsContextType } from 'contexts/EquipmentsDetailsContext/EquipmentsDetailsContextType';

import './styles.scss';

interface Params {
	openNewServiceClick: () => void;
}

const EquipmentInfoWeb: React.FC<Params> = ({ openNewServiceClick }) => {
	const { equip } = useContext<EquipmentsDetailsContextType>(EquipmentsDetailsContextElement);

	const equipLocationContent = () => {
		const location = equip?.location;
		if (location) {
			const address = `${location.street}, ${location.neighborhood}.`;
			const region = `${location.city.toLocaleLowerCase()}, ${location.state_sigla} - ${location.zip_code}`;

			return (
				<>
					<b>Endereço: </b>
					<span>{address.toLocaleLowerCase()}</span>
					<span>{region}</span>
				</>
			);
		} else {
			return <></>;
		}
	};

	const equipWarrantContent = () => {
		const warrant = equip?.date_end_warranty;
		if (warrant) {
			return (
				<>
					<b>Fim da garantia: </b>
					<span>
						{moment(warrant)
							.locale('pt-br')
							.format('LL')}
					</span>
				</>
			);
		} else {
			return <></>;
		}
	};

	const openServiceContent = () => {
		return (
			<>
				<BriefcaseIcon />
				<span>Solicitar Atendimento</span>
			</>
		);
	};

	const mainContent = () => {
		return (
			<section className="equip-web-info-content">
				<div className="equip-info-name">
					<h1>{parseEquipName(equip?.name)}</h1>
					<span>
						<b>Número de série:</b> {equip?.serial_number}
					</span>
				</div>
				<div className="equip-info-extra">
					<div className="equip-info-location">{equipLocationContent()}</div>
					<div className="equip-info-warrant">{equipWarrantContent()}</div>
					<div className="equip-open-service" onClick={() => openNewServiceClick()}>
						{openServiceContent()}
					</div>
				</div>
			</section>
		);
	};

	return equip ? mainContent() : <></>;
};

export default EquipmentInfoWeb;
