/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { EquipmentsHistoryContextElement } from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContext';
import { EquipmentsHistoryContextType } from 'contexts/EquipmentsHistoryContext/EquipmentsHistoryContextType';
import ServiceOrder from 'models/ServiceOrder';
import React, { useContext, useEffect } from 'react';
import { ViewType } from 'utils/constants';
import EquipmentServiceOrdersMobile from './Mobile';
import EquipmentServiceOrdersWeb from './Web';

interface Params {
	equipId: number;
	hasUpdate: boolean;
	onItemClick: (serviceOrder: ServiceOrder) => void;
	onCancelServiceOrderClick: (serviceOrder: ServiceOrder) => void;
}

const EquipmentServiceOrders: React.FC<Params> = ({ equipId, hasUpdate, onItemClick, onCancelServiceOrderClick }) => {
	const { viewType } = useContext<DeviceContextType>(DeviceContextElement);
	const { onEquipChange, refreshList } = useContext<EquipmentsHistoryContextType>(EquipmentsHistoryContextElement);

	useEffect(() => {
		onEquipChange(equipId);
	}, []);

	useEffect(() => {
		if (hasUpdate) {
			refreshList();
		}
	}, [hasUpdate]);

	const mainContent = () => {
		return viewType === ViewType.WEB ? (
			<EquipmentServiceOrdersWeb {...{ equipId, onItemClick, onCancelServiceOrderClick }} />
		) : (
			<EquipmentServiceOrdersMobile {...{ equipId, onItemClick, onCancelServiceOrderClick }} />
		);
	};

	return <>{mainContent()}</>;
};

export default EquipmentServiceOrders;
