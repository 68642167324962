/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { ServiceOrderFeedbackAnswersInput } from 'models/ServiceOrderFeedback';

export const SERVICE_ORDER_TYPE = 'ASSISTENCIA';
export const SERVICE_ORDER_ORIGIN = 'CLIENT_PLAT';

export const firstIndex = 0;
export const lastIndex = (list: any[]) => list.length - 1;

export const firstEl = (list: any[]) => list[firstIndex];
export const lastEl = (list: any[]) => list[lastIndex(list)];

export enum ViewType {
	WEB = 'web',
	TABLET = 'tablet',
	MOBILE = 'mobile',
}

export enum DeviceType {
	WEB = 'device-web',
	TABLET_LAND = 'device-tablet-land',
	TABLET_PORT = 'device-tablet-port',
	MOBILE = 'device-mobile',
}

export enum OrientationType {
	PORTRAIT = 'port',
	LANDSCAPE = 'land',
}

export enum RouteList {
	DEFAULT = '/index.html',
	HOME = '/',
	EQUIPMENTS = '/equipamentos',
	EQUIPMENTS_OLD = '/equipaments',
	EQUIPMENTS_VIEW = '/equipamentos/:serial',
	EQUIPMENTS_ORDERS = '/equipamentos/:serial/atendimentos',
	EQUIPMENTS_ORDERS_VIEW = '/equipamentos/:serial/atendimentos/:orderCode',
	ORDERS = '/atendimentos',
	ORDERS_TIMELINE = '/atendimentos/:orderCode',
	LOGIN = '/login',
	REDEFINE_PASS = '/nova-senha/:auth',
	RESET_PASS = '/restaurar-senha',
	NEW_ACCOUNT = '/nova-conta',
	CONFIG_USER = '/configuracoes',
	NOTIFICATIONS = '/notificacoes',
	STORES_SETTINGS = '/configurar-lojas',
	NEW_USER = '/novo-usuario',
}

export enum StepTypes {
	CREATE_OS = 'create_os',
	NEW_VISIT = 'new_visit',
	FOLLOW_UP = 'follow-up',
	QUOTE = 'quote',
	ORDER = 'order',
	INVOICE = 'invoice',
	CLOSE_OS = 'close_os',
	INTERNAL_OBS = 'internal_obs',
	INVENTORY_ORDER = 'inventory_order',
	INVENTORY_INVOICE = 'inventory_invoice',
	AUTH = 'internal_auth',
	CANCEL_REQUEST = 'cancel_request',
	COMPLETED_OS_FIELD_CONTROL = 'internal_obs_field_control',
	IN_PROGRESS_OS_FIELD_CONTROL = 'in_progress_os_field_control',
	REPORTED_OS_FIELD_CONTROL = 'reported_os_field_control',
}

export enum ServiceOrderStatusTypes {
	CLOSED = 'closed',
	PROGRESS = 'progress',
}

export enum ServiceOrderStatusLabels {
	CLOSED = 'Concluída',
	PROGRESS = 'Em andamento',
}

export enum DefaultServiceOrderAddress {
	DEFAULT_VALUE = 'DEFAULT_VALUE',
	ADD_NEW_VALUE = 'ADD_NEW_VALUE',
}

export enum ServiceOrderQuestionType {
	MESSAGE = 'MESSAGE',
	TEXT_FIELD = 'TEXT_FIELD',
	SELECTOR = 'SELECTOR',
	OPTION = 'OPTION',
	RATING = 'RATING',
}

export const SERVICE_RATING_LIMIT = 5;
export const YES_OPTION = 'SIM';
export const NO_OPTION = 'NÃO';

export const ServiceOrderFeedbackQuestions = {
	serviceOrderRating: { id: 1, order: '01', question: 'Qual a sua avaliação quanto ao atendimento realizado por nosso prestador?', formName: 'serviceOrderRating' },
	serviceOrderComplete: { id: 2, order: '02', question: 'O serviço proposto em sua solicitação foi resolvido?', formName: 'serviceOrderComplete' },
	serviceOrderReopen: { id: 3, order: '2.1', question: 'Deseja agendar uma nova visita?', formName: 'serviceOrderReopen' },
	visitTimeArrive: { id: 4, order: '03', question: 'O prestador realizou a visita no horário programado?', formName: 'visitTimeArrive' },
	visitTimeDelay: { id: 5, order: '3.1', question: 'A necessidade de atraso foi informada previamente?', formName: 'visitTimeDelay' },
	serviceOrderObs: { id: 6, order: '04', question: 'Deixe aqui suas observações, dicas ou elogios.', formName: 'serviceOrderObs' },
};

export const ServiceOrderFeedbackQuestionsIndex = [
	{ id: 1, formName: 'serviceOrderRating' },
	{ id: 2, formName: 'serviceOrderComplete' },
	{ id: 3, formName: 'serviceOrderReopen' },
	{ id: 4, formName: 'visitTimeArrive' },
	{ id: 5, formName: 'visitTimeDelay' },
	{ id: 6, formName: 'serviceOrderObs' },
];

export const CLEAR_OS_FEEDBACK_ANSWERS = {
	serviceOrderRating: 0,
	serviceOrderComplete: '',
	visitTimeArrive: '',
	serviceOrderObs: '',
} as ServiceOrderFeedbackAnswersInput;

export const DEFAULT_RATE_VALUE = '0';

export const RATING_BY_PUSH = '?rating=true';

export const STORE_PATH_PARAM = '?storeId';

export const EMPTY_USER_STORE = 'INDISPONIVEL';

/**
 * Constants and base methods to use on file upload center;
 */
export const FILE_MAX_SIZE = 32000000;
export const FILE_FORMAT_LIST = ['PNG', 'JPG', 'JPEG', 'PDF'];
export const validFileFormat = (fileType: string) => FILE_FORMAT_LIST.includes(fileType);
export const validFileSize = (fileSize: number) => fileSize <= FILE_MAX_SIZE;
export const hasInvalidFileByError = (list: any[], error: InvalidFileErrorType) => list.find(i => i.error === error);

export enum InvalidFileErrorType {
	FILE_ERROR = 'FILE_ERROR',
	SIZE_ERROR = 'SIZE_ERROR',
	FORMAT_ERROR = 'FORMAT_ERROR',
}

export enum StorePatterns {
	AUTH_SIGN_IN = '@auth/SIGN_IN',
	AUTH_SIGN_OUT = '@auth/SIGN_OUT',
	USER_SAVE_USER = '@user/SAVE_USER',
	PERSIST_TOKEN = 'persist/REHYDRATE',
}

export enum UserType {
	DEFAULT = 'DEFAULT',
	TECH = 'TECH',
	BOSS = 'BOSS',
}

export enum AppThemes {
	LIGHT = 'light-theme',
	DARK = 'dark-theme',
	TRANSITION = 'changing-theme',
}

export enum UserTypes {
	DEFAULT = 'DEFAULT',
	TECH = 'TECH',
	BOSS = 'BOSS',
}

export enum EquipmentTransferViewType {
	TRANSFER = 'TRANSFER',
	CORRECT_VALUES = 'CORRECT_VALUES',
}
