/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { Button, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MoreOptionsItem } from 'models/MoreOptionsItem';
import React from 'react';
import './styles.scss';

interface Params {
	id: string;
	options: MoreOptionsItem[];
}

const MoreOptions: React.FC<Params> = ({ id, options }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onClick = (option: MoreOptionsItem) => {
		option.onClick(option.item);
		handleClose();
	};

	const menuListContent = () => {
		return options.map((option: MoreOptionsItem, index: number) => {
			return (
				<MenuItem
					onClick={e => {
						e.stopPropagation();
						onClick(option);
					}}
					key={index}
				>
					{option.label}
				</MenuItem>
			);
		});
	};

	const menuContent = () => {
		return (
			<Menu
				id={id}
				keepMounted
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={(e: any) => {
					e.stopPropagation();
					handleClose();
				}}
			>
				{menuListContent()}
			</Menu>
		);
	};

	const mainContent = () => {
		return (
			<>
				<Button className="more-option-item-click" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</Button>
				{menuContent()}
			</>
		);
	};

	return mainContent();
};

export default MoreOptions;
