/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React, { ChangeEvent } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import FileProperties from 'models/FileProperties';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { parseFileUploadedType } from 'utils/pipe';

import './styles.scss';
import { hasInvalidFileByError, InvalidFileErrorType, validFileFormat, validFileSize } from 'utils/constants';
import { toast } from 'react-toastify';
import { uniqueId } from 'lodash';
import { uploadFiles } from 'services';

interface Params {
	fileList: FileProperties[];
	limit: number;
	onAddFile: (newFiles: FileProperties[]) => void;
	onRemoveFile: (file: FileProperties) => void;
}

interface InvalidFile {
	fileName: string;
	error: string;
	size: number;
}

const UploadFiles: React.FC<Params> = ({ fileList, limit, onAddFile, onRemoveFile }) => {
	const disableButton = () => {
		return fileList.length >= limit;
	};

	const onAddItem = () => {
		$('.upload-file-action-input').trigger('click');
	};

	const addFileToList = async (files: FileList) => {
		const filesToAdd = [] as FileProperties[];
		const invalidFiles = [] as InvalidFile[];
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			const invalidFile = isInvalidFile(file);
			if (!invalidFile) {
				const formattedFiles: FileProperties = {
					file,
					id: uniqueId(),
					name: file.name,
					size: file.size,
					type: file.type,
					uploaded: false,
					error: false,
					url: null,
				};
				try {
					const data = new FormData();

					if (!formattedFiles.file) return;

					data.append('file', formattedFiles.file, formattedFiles.name);

					const response = await uploadFiles(data);

					formattedFiles.uploaded = true;
					formattedFiles.id = response.data.key;
					formattedFiles.url = response.data.url;

					filesToAdd.push(formattedFiles);
				} catch (error) {
					const errorFile = {
						fileName: file.name,
						error: InvalidFileErrorType.FILE_ERROR,
						size: file.size,
					} as InvalidFile;
					invalidFiles.push(errorFile);
				}
			} else {
				invalidFiles.push(invalidFile);
			}
		}
		onAddFile(filesToAdd);
		showInvalidFileAlert(invalidFiles);
	};

	const isInvalidFile = (file: any) => {
		const fileFormat = parseFileUploadedType(file.type);
		const fileSize = file.size;

		if (!validFileFormat(fileFormat)) {
			return {
				fileName: file.name,
				error: InvalidFileErrorType.FORMAT_ERROR,
				size: file.size,
			} as InvalidFile;
		}

		if (!validFileSize(fileSize)) {
			return {
				fileName: file.name,
				error: InvalidFileErrorType.SIZE_ERROR,
				size: file.size,
			} as InvalidFile;
		}

		return;
	};

	const showInvalidFileAlert = (invalidFiles: InvalidFile[]) => {
		if (invalidFiles.length) {
			if (hasInvalidFileByError(invalidFiles, InvalidFileErrorType.SIZE_ERROR)) {
				toast.info('Arquivo arquivos de até 30Mb.');
			}
			if (hasInvalidFileByError(invalidFiles, InvalidFileErrorType.FORMAT_ERROR)) {
				toast.info(`Apenas arquivos JPG, JPEG, PNG e PDF são aceitos.`);
			}
			if (hasInvalidFileByError(invalidFiles, InvalidFileErrorType.FILE_ERROR)) {
				toast.error('Erro ao carregar arquivo!');
			}
		}
	};

	const onUploadClick = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.currentTarget.files;
		if (files?.length) {
			const totalSize = files.length + fileList.length;
			if (totalSize <= limit) {
				addFileToList(files);
			} else {
				toast.error('Selecione até 3 arquivos.');
			}
		}
	};

	const listContent = () => {
		return fileList.map((file: FileProperties, index: number) => (
			<div className="upload-file-list-item" key={index}>
				<span>Anexo {index + 1}</span>
				<div
					className="upload-file-list-item-remove"
					onClick={() => {
						onRemoveFile(file);
					}}
				>
					<CloseIcon />
				</div>
			</div>
		));
	};

	return (
		<div>
			<div className="upload-file-main-content">
				<button type="button" className="upload-file-action" disabled={disableButton()} onClick={onAddItem}>
					<AttachFileIcon />
					<span>Anexar arquivo</span>
				</button>
				<input type="file" multiple data-max-size="52000" className="upload-file-action-input" onChange={onUploadClick} />
				<div className="upload-file-list">{listContent()}</div>
			</div>
			<div className="upload-file-warning">
				<span>* Selecione no máximo três arquivos.</span>
			</div>
		</div>
	);
};

export default UploadFiles;
