/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { ReportedDefectContextElement } from 'contexts/ReportedDefectContext/ReportedDefectContext';
import { ReportedDefectContextType } from 'contexts/ReportedDefectContext/ReportedDefectContextType';
import { ReportedDefect } from 'models/ReportedDefect';
import React, { useContext } from 'react';

interface Params {
	defectCode?: string;
	onDefectSelected: (code: string) => void;
}

const DefectField: React.FC<Params> = ({ defectCode, onDefectSelected }) => {
	const { reportedDefects, isLoading } = useContext<ReportedDefectContextType>(ReportedDefectContextElement);

	const defectSelected = () => {
		return reportedDefects?.find(d => d.code === defectCode) || '';
	};

	const inputProps = (params: any) => {
		const props = { ...params.InputProps };
		props.startAdornment = (
			<InputAdornment position="start">
				<SearchIcon />
			</InputAdornment>
		);

		return props;
	};

	const mainContent = () => {
		return (
			<Autocomplete
				freeSolo
				id="defect-field-selector"
				disableClearable
				options={reportedDefects}
				getOptionLabel={(defect: ReportedDefect) => defect?.name?.toLocaleLowerCase() || ''}
				onChange={(e: React.ChangeEvent, value: ReportedDefect) => onDefectSelected(value.code)}
				className="input-search-field"
				loading={isLoading}
				loadingText="Buscando defeitos..."
				value={defectSelected()}
				renderInput={params => (
					<TextField {...params} placeholder="Procurar por tipo de defeito" margin="normal" variant="outlined" InputProps={{ ...inputProps(params), type: 'search' }} />
				)}
			/>
		);
	};

	return mainContent();
};

export default DefectField;
