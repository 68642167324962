/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { DropdownItem } from 'models/DropdownItem';
import React from 'react';
import Dropdown from '../Dropdown';
import './styles.scss';

interface Params {
	limit: number;
	onChangeLimit: (value: number) => void;
}

const LIMITS = [10, 20, 30, 50, 100];

const PaginatorLimit: React.FC<Params> = ({ limit, onChangeLimit }) => {
	const onChange = (optionSelected: DropdownItem) => {
		onChangeLimit(Number(optionSelected.value));
	};

	const dropdownItems = LIMITS.map(l => {
		return { value: l, label: `${l} por página` } as DropdownItem;
	});

	return <Dropdown options={dropdownItems} onChange={onChange} selectedOption={limit} secondaryClass="paginator-limit-component" />;
};

export default PaginatorLimit;
