/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import { CircularProgress } from '@material-ui/core';

import './styles.scss';

interface Params {
	show: boolean;
	text?: string;
}

const LoadingProgress: React.FC<Params> = ({ show, text }) => {
	const mainContent = () => {
		return (
			<div className="loading-main-content">
				<div className="loading-main">
					<CircularProgress />
					<span>{text ? text : 'Carregando informações...'}</span>
				</div>
			</div>
		);
	};

	return show ? mainContent() : <></>;
};

export default LoadingProgress;
