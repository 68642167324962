/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { FormControl, MenuItem, Select } from '@material-ui/core';
import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import { DropdownItem } from 'models/DropdownItem';
import React, { useContext } from 'react';
import './styles.scss';

interface Params {
	options: DropdownItem[];
	selectedOption?: string | number;
	onChange: (optionSelected: DropdownItem) => void;
	placeholder?: string;
	secondaryClass?: string;
}

const Dropdown: React.FC<Params> = ({ options, selectedOption, onChange, placeholder, secondaryClass }) => {
	const { mainTheme } = useContext<DeviceContextType>(DeviceContextElement);

	const onSelectedItem = (e: any) => {
		const value = e.target.value;
		const selectedOption = options.find(o => o.value === value || o.value === Number(value));
		if (selectedOption) {
			onChange(selectedOption);
		}
	};

	const optionsList = (): JSX.Element[] => {
		const items = options.map((o, index) => (
			<MenuItem value={o.value} key={index} className={`dropdown-list-item ${mainTheme}`}>
				{o.label}
			</MenuItem>
		));

		if (placeholder) {
			items.unshift(
				<MenuItem value="0" key={placeholder} disabled className={`dropdown-list-item ${mainTheme} disabled`}>
					{placeholder}
				</MenuItem>,
			);
		}

		return items;
	};

	const mainContent = () => {
		return (
			<FormControl className={`dropdown-content ${secondaryClass}`}>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={selectedOption}
					onChange={onSelectedItem}
					MenuProps={{
						className: `${mainTheme}`,
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						getContentAnchorEl: null,
					}}
				>
					{optionsList()}
				</Select>
			</FormControl>
		);
	};

	return mainContent();
};

export default Dropdown;
