/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { VersionContextElement, VersionContextType } from 'contexts/VersionContext/VersionContextType';
import React, { useContext } from 'react';
import pack from '../../../../package.json';
import './styles.scss';

const VersionArea: React.FC = () => {
	const { onUpdateClick } = useContext<VersionContextType>(VersionContextElement);

	return (
		<div className="version-area-content">
			<div className="update-area-content" onClick={onUpdateClick}>
				<span id="version">Versão {pack.version}</span>
			</div>
		</div>
	);
};

export default VersionArea;
