/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import history from 'routes/history';
import React, { useContext } from 'react';
import { Notification } from 'models/Notification';
import PageTitle from 'components/Shared/PageTitle';
import Paginator from 'components/Shared/Paginator';
import SearchInput from 'components/Shared/SearchInput';
import MoreOptions from 'components/Shared/MoreOptions';
import { MoreOptionsItem } from 'models/MoreOptionsItem';
import PaginatorLimit from 'components/Shared/PaginatorLimit';
import NotificationsList from 'components/Notifications/NotificationsList';
import { NotificationsContextElement } from 'contexts/NotificationsContext/NotificationsContext';
import { NotificationsContextType } from 'contexts/NotificationsContext/NotificationsContextType';

import './styles.scss';
import NotificationsWarning from 'components/Notifications/NotificationsWarning';

const Notifications: React.FC = () => {
	const { totalPages, currentPage, isLoading, pageLimit, onRemoveAll, onPageChange, onLimitChange, onSearchNotification, onChangeAllStatus } = useContext<
		NotificationsContextType
	>(NotificationsContextElement);

	const reverseAllStatus = () => {
		onChangeAllStatus(true);
	};

	const removeAll: MoreOptionsItem = { label: 'Excluir todas as notificações', onClick: onRemoveAll };
	const readAll: MoreOptionsItem = { label: 'Marcar todas como lidas', onClick: onChangeAllStatus };
	const notReadAll: MoreOptionsItem = { label: 'Marcar todas como não lidas', onClick: reverseAllStatus };

	const moreOptions = [removeAll, notReadAll, readAll];

	const onItemClick = (notify: Notification) => {
		history.push(`/atendimentos/${notify.osNumber}`);
	};

	const moreOptionsContent = () => (
		<div className="notification-more-options">
			<MoreOptions id="notifications-list-options" options={moreOptions} />
		</div>
	);

	const headerContent = () => (
		<section className="notifications-header-content">
			<div className="title-page-area">
				<h4>Central de Notificações</h4>
			</div>
			<div className="options-list-area">
				<SearchInput onChangeValues={onSearchNotification} placeHolder="Procurar notificação" />
				<PaginatorLimit limit={pageLimit} onChangeLimit={onLimitChange} />
				{moreOptionsContent()}
			</div>
		</section>
	);

	const mainContent = () => (
		<section className="notifications-main-content">
			{headerContent()}
			<div className="list-view">
				<NotificationsList onItemClick={onItemClick} />
				<NotificationsWarning />
				<Paginator selected={currentPage} total={totalPages} isLoading={isLoading} onPageChange={onPageChange} />
			</div>
		</section>
	);

	return (
		<>
			<PageTitle title="Central de Notificações" single={true} />
			{mainContent()}
		</>
	);
};

export default Notifications;
