/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React, { useContext } from 'react';
import Technician from 'models/Technician';
import { StepTypes } from 'utils/constants';
import TechnicalAssists from 'models/TechnicalAssists';
import TimelineDescription from './TimelineDescription';
import ProgressLine from 'components/Shared/ProgressLine';
import ServiceOrderFeedback from 'components/ServiceOrderFeedback';
import OpenServiceOrderContext from 'contexts/OpenServiceOrderContext/OpenServiceOrderContext';
import ServiceOrderFeedbackContext from 'contexts/ServiceOrderFeedbackContext/ServiceOrderFeedbackContext';
import { ServiceOrderDetailsContextElement } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContext';
import { ServiceOrderDetailsContextType } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContextType';

interface Params {
	techAssist: TechnicalAssists;
	technician?: Technician;
	isFirstItem: boolean;
	isLastItem: boolean;
}

const TimelineItem: React.FC<Params> = ({ techAssist, technician, isFirstItem, isLastItem }) => {
	const { serviceOrderDetail } = useContext<ServiceOrderDetailsContextType>(ServiceOrderDetailsContextElement);

	const serviceOrder = serviceOrderDetail?.serviceOrder;

	const isFinished = techAssist.step_type === StepTypes.CLOSE_OS && isLastItem;
	const finishClass = isFinished ? 'finished' : '';
	const lastItemClass = isLastItem ? 'timeline-last-item' : '';
	const fileClass = techAssist.tech_assistance_files.length && !finishClass ? 'has-files' : '';

	const showServiceOrderFeedback = () => {
		return serviceOrder && isFinished ? (
			<OpenServiceOrderContext>
				<ServiceOrderFeedbackContext>
					<ServiceOrderFeedback {...{ serviceOrder }} />
				</ServiceOrderFeedbackContext>
			</OpenServiceOrderContext>
		) : (
			<></>
		);
	};

	return (
		<div className={`service-order-timeline-item ${finishClass} ${fileClass} ${lastItemClass}`}>
			<ProgressLine {...{ isFinished, isLastItem, isFirstItem }} />
			<div className="service-order-timeline-description">
				<TimelineDescription {...{ techAssist, technician }} />
				{showServiceOrderFeedback()}
			</div>
		</div>
	);
};

export default TimelineItem;
