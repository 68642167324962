/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import $ from 'jquery';
import HasError from 'components/Shared/HasError';
import PageTitle from 'components/Shared/PageTitle';
import { RouteComponentProps } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import Timeline from 'components/ServiceOrderDetails/Timeline';
import LoadingProgress from 'components/Shared/LoadingProgress';
import ServiceOrderInfo from 'components/ServiceOrderDetails/ServiceOrderInfo';
import { ServiceOrderDetailsContextElement } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContext';
import { ServiceOrderDetailsContextType } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContextType';

import './styles.scss';

const PADDING_SCROLL_TOP = 50;
const SCROLL_INTERVAL = 100;

type TParams = {
	history: string;
	location: any;
	match: any;
};

const ServiceOrderDetails: React.FC<RouteComponentProps<TParams>> = ({ history, location, match }) => {
	const { isLoading, hasError, onServiceOrderChange } = useContext<ServiceOrderDetailsContextType>(ServiceOrderDetailsContextElement);
	const [notFoundError, setNotFoundError] = useState<boolean>(false);
	const [serviceOrderCode, setServiceOrderCode] = useState<string>();
	const [serialNumber, setSerialNumber] = useState<string>();
	const [pageTitle, setPageTitle] = useState<string>('');

	useEffect(() => {
		getRouterParams();
		history.listen(() => {
			const newLocation = history.location as any;
			const osNumber = newLocation.state?.notification?.osNumber;
			if (osNumber) {
				setSerialNumber(undefined);
				setServiceOrderCode(osNumber);
				setPageTitle(`Detalhes do Atendimento ${osNumber}`);
			}
		});
	}, []);

	useEffect(() => {
		if (serviceOrderCode) {
			onServiceOrderChange(serviceOrderCode, serialNumber);
		}
	}, [serviceOrderCode]);

	const scrollTimer = setInterval(() => {
		if (!isLoading) {
			const lastItemPosition = $('.timeline-last-item').position();
			const lastItemHeight = $('.timeline-last-item').height();
			if (lastItemPosition && lastItemHeight) {
				$('.service-order-timeline-list-content').scrollTop(lastItemPosition.top - (lastItemHeight + PADDING_SCROLL_TOP));
				$('.service-order-timeline-content').scrollTop(lastItemPosition.top - (lastItemHeight + PADDING_SCROLL_TOP));
			}
			clearInterval(scrollTimer);
		}
	}, SCROLL_INTERVAL);

	const getRouterParams = () => {
		const params = match?.params as any;
		if (params) {
			const orderCode = params.orderCode;
			const serialNumber = params.serial;
			if (orderCode) {
				setSerialNumber(serialNumber);
				setServiceOrderCode(orderCode);
				setPageTitle(`Detalhes do Atendimento ${orderCode}`);
			} else {
				setNotFoundError(true);
			}
		} else {
			setNotFoundError(true);
		}
	};

	const showError = () => (notFoundError || hasError) as boolean;

	return (
		<>
			<PageTitle title={pageTitle} />
			<section className="service-order-details-content">
				<ServiceOrderInfo />
				<Timeline />
				<HasError show={showError()} />
				<LoadingProgress show={isLoading || !serviceOrderCode} text="Buscando atendimento..." />
			</section>
		</>
	);
};

export default ServiceOrderDetails;
