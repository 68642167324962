/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import './styles.scss';

interface Params {
	type?: 'button' | 'submit' | 'reset';
	label: string;
	context: 'primary' | 'secondary';
	customClass?: string;
	disabled?: boolean;
	onClick?: () => void;
}

const CustomButton: React.FC<Params> = ({ type, label, context, customClass, disabled, onClick }) => {
	const mainClass = context === 'primary' ? 'custom-button-primary' : 'custom-button-secondary';

	return (
		<button className={`${mainClass} ${customClass}`} onClick={onClick} type={type} disabled={disabled}>
			{label}
		</button>
	);
};

export default CustomButton;
