/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import EmptyList from 'components/Shared/EmptyList';
import HasError from 'components/Shared/HasError';
import LoadingProgress from 'components/Shared/LoadingProgress';
import PageTitle from 'components/Shared/PageTitle';
import Paginator from 'components/Shared/Paginator';
import StoresSettingsHeader from 'components/StoresSettings/StoresSettingsHeader';
import StoresSettingsList from 'components/StoresSettings/StoresSettingsList';
import { StoreSettingsContextElement } from 'contexts/StoreSettingsContext/StoreSettingsContext';
import { StoreSettingsContextType } from 'contexts/StoreSettingsContext/StoreSettingsContextType';
import React, { useContext } from 'react';
import { onChildrenScroll } from 'utils/Screen';
import './styles.scss';

const StoresSettings: React.FC = () => {
	const { storeList, hasError, totalPages, currentPage, isLoading, onPageChange } = useContext<StoreSettingsContextType>(StoreSettingsContextElement);

	const mainContent = () => (
		<section className="stores-settings-main-content">
			<StoresSettingsHeader />
			<div
				className="list-view"
				onScroll={e => {
					onChildrenScroll(e, 'stores-settings-main-content');
				}}
			>
				<StoresSettingsList />
				<Paginator selected={currentPage} total={totalPages} isLoading={isLoading} onPageChange={onPageChange} />
			</div>
			<EmptyList show={!storeList.length} text="Sem lojas por aqui" />
			<HasError show={!!hasError && !storeList.length} />
			<LoadingProgress show={isLoading} text="Buscando lojas..." />
		</section>
	);

	return (
		<>
			<PageTitle title="Configurações de Lojas" single={true} />
			{mainContent()}
		</>
	);
};

export default StoresSettings;
