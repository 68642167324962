/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import LoadingModal from 'components/Shared/LoadingModal';
import ResponseModal from 'components/Shared/ResponseModal';
import React, { useContext, useEffect, useState } from 'react';
import { store } from 'store';
import { DeviceContextElement } from '../../../../contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from '../../../../contexts/DeviceContext/DeviceContextType';
import { DeviceType } from '../../../../utils/constants';
import MessageSuccessTutorial from '../MessageSuccessTutorial';
import ModalMobile from './Mobile';
import ModalWeb from './Web';

interface Params {
	show?: boolean;
	onClose?: () => void;
}

const ModalTutorial: React.FC<Params> = ({ show, onClose }) => {
	const { deviceType, onUpdateSettings } = useContext<DeviceContextType>(DeviceContextElement);

	const [showModalTutorial, setShowModalTutorial] = useState<boolean>(false);
	const [showMessageSuccessTutorial, setShowMessageSuccessTutorial] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const { viewTutorial } = store.getState()?.data?.user?.appConfigs;
		if (show || !viewTutorial) {
			setShowModalTutorial(true);
		}
	}, []);

	const onCompleted = () => {
		setIsLoading(false);
		setShowMessageSuccessTutorial(true);
	};

	const onTutorialFinished = () => {
		if (onClose) {
			onClose();
		} else {
			setShowModalTutorial(false);
			setIsLoading(true);
			const newAppConfig = { ...store.getState().data.user.appConfigs };
			newAppConfig.viewTutorial = true;
			onUpdateSettings(newAppConfig, onCompleted);
		}
	};

	const onSuccessMessageClose = () => {
		setShowMessageSuccessTutorial(false);
		if (onClose) {
			onCompleted();
		}
	};

	const messageSuccessTutorial = () => {
		const children = <MessageSuccessTutorial />;
		return showMessageSuccessTutorial ? <ResponseModal {...{ children, title: 'Tutorial Concluído!', onClose: onSuccessMessageClose }} /> : <></>;
	};

	const modalContent = () => {
		return deviceType === DeviceType.MOBILE ? (
			<ModalMobile {...{ show: showModalTutorial, onClose: onTutorialFinished }} />
		) : (
			<ModalWeb {...{ show: showModalTutorial, onClose: onTutorialFinished }} />
		);
	};

	const loadingContent = () => {
		return <LoadingModal show={isLoading} title="Finalizando Tutorial" text="Carregando..." />;
	};

	const mainContent = () => {
		return showModalTutorial ? modalContent() : <></>;
	};

	return (
		<>
			{mainContent()}
			{loadingContent()}
			{messageSuccessTutorial()}
		</>
	);
};

export default ModalTutorial;
