/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { DeviceContextElement } from 'contexts/DeviceContext/DeviceContext';
import { DeviceContextType } from 'contexts/DeviceContext/DeviceContextType';
import React, { useContext } from 'react';
import { DeviceType } from 'utils/constants';
import MobileResponseModal from './Mobile';
import './styles.scss';
import WebResponseModal from './Web';

interface Params {
	title: string;
	children: any;
	secondaryClass?: string;
	hideAction?: boolean;
	hideClose?: boolean;
	hideTitle?: boolean;
	onClose: () => void;
}

const ResponseModal: React.FC<Params> = ({ title, children, secondaryClass, hideTitle, hideClose, hideAction, onClose }) => {
	const { deviceType } = useContext<DeviceContextType>(DeviceContextElement);

	const mainContent = () => {
		return deviceType === DeviceType.MOBILE ? (
			<MobileResponseModal {...{ title, children, secondaryClass, hideAction, hideClose, hideTitle, onClose }} />
		) : (
			<WebResponseModal {...{ title, children, secondaryClass, hideClose, hideTitle, onClose }} />
		);
	};

	return <div className="modal-main-backdrop">{mainContent()}</div>;
};

export default ResponseModal;
