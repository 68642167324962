/**
 * Developed by: Inatel Competence Center
 * Copyright 2019, Prática
 * Author: Lucas Marciano
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import AccountContext from 'contexts/AccountContext/AccountContext';
import AuthContext from 'contexts/AuthContext/AuthContext';
import EquipmentsContext from 'contexts/EquipmentsContext/EquipmentsContext';
import EquipmentsDetailsContext from 'contexts/EquipmentsDetailsContext/EquipmentsDetailsContext';
import NotificationsContext from 'contexts/NotificationsContext/NotificationsContext';
import ServiceOrderContext from 'contexts/ServiceOrderContext/ServiceOrderContext';
import ServiceOrderDetailsContext from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContext';
import StoreSettingsContext from 'contexts/StoreSettingsContext/StoreSettingsContext';
import CreateAccount from 'pages/CreateAccount';
import EquipmentDetails from 'pages/EquipmentDetails';
import Equipments from 'pages/Equipments';
import Login from 'pages/Login';
import NewPass from 'pages/NewPass';
import Notifications from 'pages/Notifications';
import ResetPass from 'pages/ResetPass';
import ServiceOrderDetails from 'pages/ServiceOrderDetails';
import ServiceOrders from 'pages/ServiceOrders';
import StoresSettings from 'pages/StoresSettings';
import React from 'react';
import { Switch } from 'react-router';
import { RouteList } from 'utils/constants';
import RouterWrapper from './RouterWrapper';

export default function Routes() {
	return (
		<Switch>
			<RouterWrapper exact path={RouteList.HOME} component={Login} context={AuthContext} />
			<RouterWrapper exact path={RouteList.LOGIN} component={Login} context={AuthContext} />
			<RouterWrapper exact path={RouteList.DEFAULT} component={Login} context={AuthContext} />
			<RouterWrapper exact={false} path={RouteList.RESET_PASS} component={ResetPass} context={AccountContext} />
			<RouterWrapper exact={false} path={RouteList.NEW_ACCOUNT} component={CreateAccount} context={AccountContext} />
			<RouterWrapper exact={true} path={RouteList.REDEFINE_PASS} component={NewPass} context={AccountContext} />
			<RouterWrapper exact path={RouteList.EQUIPMENTS} component={Equipments} context={EquipmentsContext} isPrivate />
			<RouterWrapper exact path={RouteList.EQUIPMENTS_ORDERS} component={EquipmentDetails} context={EquipmentsDetailsContext} isPrivate />
			<RouterWrapper exact path={RouteList.EQUIPMENTS_VIEW} component={EquipmentDetails} context={EquipmentsDetailsContext} isPrivate />
			<RouterWrapper exact path={RouteList.EQUIPMENTS_ORDERS_VIEW} component={ServiceOrderDetails} context={ServiceOrderDetailsContext} isPrivate />
			<RouterWrapper exact path={RouteList.ORDERS} component={ServiceOrders} context={ServiceOrderContext} isPrivate />
			<RouterWrapper exact path={RouteList.ORDERS_TIMELINE} component={ServiceOrderDetails} context={ServiceOrderDetailsContext} isPrivate />
			<RouterWrapper exact path={RouteList.NOTIFICATIONS} component={Notifications} context={NotificationsContext} isPrivate />
			<RouterWrapper exact path={RouteList.STORES_SETTINGS} component={StoresSettings} context={StoreSettingsContext} isPrivate />
		</Switch>
	);
}
