/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { ServiceOrderDetailsContextElement } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContext';
import { ServiceOrderDetailsContextType } from 'contexts/ServiceOrderDetailsContext/ServiceOrderDetailsContextType';
import TechnicalAssists from 'models/TechnicalAssists';
import React, { useContext } from 'react';
import { firstIndex } from 'utils/constants';
import { onChildrenScroll } from 'utils/Screen';
import './styles.scss';
import TimelineItem from './TimelineItem';

const Timeline: React.FC = () => {
	const { serviceOrderDetail } = useContext<ServiceOrderDetailsContextType>(ServiceOrderDetailsContextElement);
	const history = serviceOrderDetail?.history;

	const timelineItemContent = () => {
		if (history) {
			return history.map((techAssist: TechnicalAssists, index: number) => {
				const technician = techAssist?.technician;
				const isLastItem = index + 1 === history.length;
				const isFirstItem = index === firstIndex;
				return <TimelineItem {...{ techAssist, technician, isFirstItem, isLastItem }} key={index} />;
			});
		} else {
			return <></>;
		}
	};

	return (
		<section
			className="service-order-timeline-content"
			onScroll={e => {
				onChildrenScroll(e, 'service-order-mobile-info-content');
			}}
		>
			<div className="service-order-timeline-list-content" id="timeline-list">
				{timelineItemContent()}
			</div>
		</section>
	);
};

export default Timeline;
