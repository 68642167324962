/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';

const ProgressIcon: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
			<g id="Grupo_455" data-name="Grupo 455" transform="translate(-115 -258)">
				<g id="Icon_feather-loader" data-name="Icon feather-loader" transform="translate(114.185 257.185)">
					<path
						id="Caminho_411"
						data-name="Caminho 411"
						d="M18,3V4.526"
						transform="translate(-11.185)"
						fill="none"
						stroke="#f8f9fa"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<path
						id="Caminho_412"
						data-name="Caminho 412"
						d="M18,27v1.526"
						transform="translate(-11.185 -17.896)"
						fill="none"
						stroke="#f8f9fa"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<path
						id="Caminho_413"
						data-name="Caminho 413"
						d="M7.395,7.395l1.08,1.08"
						transform="translate(-3.277 -3.277)"
						fill="none"
						stroke="#f8f9fa"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<path
						id="Caminho_414"
						data-name="Caminho 414"
						d="M24.36,24.36l1.08,1.08"
						transform="translate(-15.927 -15.927)"
						fill="none"
						stroke="#f8f9fa"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<path
						id="Caminho_415"
						data-name="Caminho 415"
						d="M3,18H4.526"
						transform="translate(0 -11.185)"
						fill="none"
						stroke="#f8f9fa"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<path
						id="Caminho_416"
						data-name="Caminho 416"
						d="M27,18h1.526"
						transform="translate(-17.896 -11.185)"
						fill="none"
						stroke="#f8f9fa"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<path
						id="Caminho_417"
						data-name="Caminho 417"
						d="M7.395,25.44l1.08-1.08"
						transform="translate(-3.277 -15.927)"
						fill="none"
						stroke="#f8f9fa"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<path
						id="Caminho_418"
						data-name="Caminho 418"
						d="M24.36,8.475l1.08-1.08"
						transform="translate(-15.927 -3.277)"
						fill="none"
						stroke="#f8f9fa"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
				</g>
			</g>
		</svg>
	);
};

export default ProgressIcon;
