/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import moment from 'moment';
import Files from 'models/OsFiles';
import { parseFileFormat } from 'utils/pipe';

interface Params {
	file: Files;
	onItemClick: (file: Files) => void;
}

const FilesCenterItem: React.FC<Params> = ({ file, onItemClick }) => {
	return (
		<div
			className="files-center-list-item-content"
			onClick={() => {
				onItemClick(file);
			}}
		>
			<div className="list-item name">
				<span>{file.name}</span>
			</div>
			<div className="list-item type">
				<span>{parseFileFormat(file.name, file.file_type)}</span>
			</div>
			<div className="list-item created">
				<span>{moment(file.created_date).format('DD/MM/YYYY')}</span>
			</div>
		</div>
	);
};

export default FilesCenterItem;
