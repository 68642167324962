/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import CustomInput from 'components/Shared/CustomInput';
import LoadingProgress from 'components/Shared/LoadingProgress';
import { AccountContextElement } from 'contexts/AccountContext/AccountContext';
import { AccountContextType } from 'contexts/AccountContext/AccountContextType';
import { AuthContextElement } from 'contexts/AuthContext/AuthContext';
import { AuthContextType } from 'contexts/AuthContext/AuthContextType';
import { AccountInput } from 'models/Account';
import { LoginInput } from 'models/LoginInfo';
import { RequisitionError } from 'models/RequisitionError';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import history from 'routes/history';
import { RouteList } from 'utils/constants';
import { getValuesFromPath, inputPhoneMask } from 'utils/pipe';
import ResetPassSuccess from '../ResetPassSuccess';
import './styles.scss';

const PASSWORD_REGEX = '(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}';

const CreateAccountForm: React.FC = () => {
	let isFirstAccess = false;
	let authToken = '';

	const defaultFormValue = () => {
		const params = getValuesFromPath();
		const email = params?.email as string;
		authToken = params?.token;
		isFirstAccess = !!(email && authToken);
		return isFirstAccess ? { email } : undefined;
	};

	const { onCreateAccount, onFirstAccess, onResetPass } = useContext<AccountContextType>(AccountContextElement);
	const { onSignIn } = useContext<AuthContextType>(AuthContextElement);

	const [email, setEmail] = useState<string>('');
	const [isLoading, setLoading] = useState<boolean>(false);
	const [receiveNotifications, setReceiveNotifications] = useState<boolean>(true);
	const [userHasAccount, setUserHasAccount] = useState<boolean>(false);
	const [resetPassCompleted, setResetPassCompleted] = useState<boolean>(false);
	const [phoneMasked, setPhoneMasked] = useState();

	const {
		register,
		handleSubmit,
		clearErrors,
		setFocus,
		getValues,
		formState: { errors, isDirty },
	} = useForm<AccountInput>({ defaultValues: defaultFormValue() });

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPhoneMasked(inputPhoneMask(e.target.value));
	};

	const onSubmit = (data: AccountInput) => {
		if (data.newPassword === data.confirmPassword) {
			setLoading(true);
			data.receiveNotifications = receiveNotifications;
			data.authToken = authToken;
			if (isFirstAccess) {
				onFirstAccess(data, onComplete);
			} else {
				onCreateAccount(data, onComplete);
			}
		} else {
			toast.error('As senhas não conferem!');
		}
	};

	const onComplete = (hasError: RequisitionError) => {
		if (hasError) {
			switch (hasError.code) {
				case 409:
					setUserHasAccount(true);
					break;
				case 404:
					toast.error('Cliente não encontrado!');
					break;
				default:
					toast.error('Erro ao criar conta!');
					break;
			}
			setLoading(false);
		} else {
			const loginInfo = getAuthCredential();
			onSignIn(loginInfo, onCompleteSingIn);
		}
	};

	const getAuthCredential = () => {
		const emailUsed = getValues('email');
		const newPassword = getValues('newPassword');
		setEmail(emailUsed);
		return { email: emailUsed, password: newPassword } as LoginInput;
	};

	const onCompleteSingIn = (hasError: RequisitionError) => {
		if (hasError) {
			setLoading(false);
			history.push(RouteList.LOGIN);
			toast.error('Erro ao realizar login!');
		} else {
			setLoading(false);
			toast.success('Conta criada com sucesso!');
		}
	};

	const onResetPassClick = () => {
		setResetPassCompleted(false);
		const loginInfo = getAuthCredential();
		onResetPass(loginInfo.email, onResetPassComplete);
	};

	const onResetPassComplete = (hasError: RequisitionError) => {
		if (hasError) {
			switch (hasError.code) {
				case 404:
					toast.error('Usuário não encontrado!');
					break;
				default:
					toast.error('Erro ao solicitar restauração de senha!');
					break;
			}
		} else {
			setResetPassCompleted(true);
		}
	};

	const sendAgain = () => {
		if (email) {
			onResetPass(email, onComplete);
		} else {
			setResetPassCompleted(false);
		}
	};

	const enableNotificationsFormContent = () => {
		return (
			<FormGroup>
				<FormControlLabel
					label="Concordo em utilizar este e-mail para receber as informações de acompanhamento de minhas solicitações."
					control={
						<Checkbox
							checked={receiveNotifications}
							onChange={() => {
								setReceiveNotifications(!receiveNotifications);
							}}
							name="receiveNotifications"
						/>
					}
				/>
			</FormGroup>
		);
	};

	const formContent = () => {
		return (
			<form onSubmit={handleSubmit(onSubmit)} className="main-form-content">
				<div className="form-row-content">
					<div className="form-item-content">
						<label>Nome</label>
						<CustomInput
							formName="name"
							register={register('name', { required: true, maxLength: 50 })}
							placeholder="Informe seu nome"
							{...{
								errors,
								isDirty,
								onClearError: () => {
									clearErrors('name');
									setFocus('name');
								},
							}}
						/>
					</div>

					<div className="form-item-content">
						<label>Telefone</label>
						<CustomInput
							formName="phone"
							register={register('phone', { required: true, maxLength: 15 })}
							placeholder="(DDD) XXXXX-XXXX"
							{...{
								errors,
								isDirty,
								onChange,
								onClearError: () => {
									clearErrors('phone');
									setFocus('phone');
								},
							}}
							max-length="16"
							value={phoneMasked}
						/>
					</div>
				</div>
				<div>
					<div className="form-item-content">
						<label>E-mail</label>
						<CustomInput
							formName="email"
							register={register('email', { required: true, maxLength: 250 })}
							placeholder="Informe seu e-mail"
							emailConflict={userHasAccount}
							disabled={!!isFirstAccess}
							onValidatorClick={() => {
								onResetPassClick();
							}}
							{...{
								errors,
								isDirty,
								onClearError: () => {
									setUserHasAccount(false);
									clearErrors('email');
									setFocus('email');
								},
							}}
						/>
					</div>
				</div>
				<div className="form-row-content">
					<div className="form-item-content">
						<label>Crie sua senha</label>
						<CustomInput
							password
							formName="newPassword"
							register={register('newPassword', {
								required: true,
								minLength: 8,
								maxLength: 20,
								pattern: RegExp(PASSWORD_REGEX),
							})}
							placeholder="Informe sua senha"
							{...{
								errors,
								isDirty,
								onClearError: () => {
									clearErrors('newPassword');
									setFocus('newPassword');
								},
							}}
						/>
					</div>
					<div className="form-item-content">
						<label>Confirme sua senha</label>
						<CustomInput
							password
							formName="confirmPassword"
							register={register('confirmPassword', {
								required: true,
								minLength: 8,
								maxLength: 20,
								pattern: RegExp(PASSWORD_REGEX),
							})}
							placeholder="Confirme sua senha"
							{...{
								errors,
								isDirty,
								onClearError: () => {
									clearErrors('confirmPassword');
									setFocus('confirmPassword');
								},
							}}
						/>
					</div>
				</div>

				<div className="create-account-options info">
					<span>Sua senha deve ter entre 8 e 20 caracteres, ter ao menos uma letra maiúscula, uma minúscula, um número e um caractere especial.</span>
				</div>

				<div className="create-account-options">{enableNotificationsFormContent()}</div>
				<div className="create-account-form-actions">
					<button className="create-account-submit" type="submit" disabled={userHasAccount}>
						CONFIRMAR
					</button>
				</div>
			</form>
		);
	};

	return (
		<div className="create-account-form">
			{formContent()}
			<LoadingProgress show={isLoading} text="Processando informações..." />
			<ResetPassSuccess show={!isLoading && resetPassCompleted} sendAgain={sendAgain} />
		</div>
	);
};

export default CreateAccountForm;
